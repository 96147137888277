import { call, put, takeLatest } from "@redux-saga/core/effects";
import ChangePasswordService from "../../Services/RestApiManager/ProfileManager/ProfileService";
import { PROFILE_ACTION, errorChangePasswordAction, responseChangePasswordAction } from "./ProfileActionCreator";

export function* changePasswordRequest(action) {
  try {
    const response = yield call(ChangePasswordService.shared.changePasswordRequest, action.data);
    yield put(responseChangePasswordAction(response));
  } catch (error) {
    yield put(errorChangePasswordAction(error));
  }
}

export function* changePasswordWatcherSaga() {
  yield takeLatest(PROFILE_ACTION.CHANGE_PASSWORD_REQUEST, changePasswordRequest);
}
