export const USER_ACTION = {
  RESET_USER_STATE: "RESET_USER_STATE",
  USER_REQUEST: "USER_REQUEST",
  USER_ERROR: "USER_ERROR",
  USER_RESPONSE: "USER_RESPONSE",

  // add user types list
  ADD_USER_TYPE_REQUEST: "ADD_USER_TYPE_REQUEST",
  ADD_USER_TYPE_RESPONSE: "ADD_USER_TYPE_RESPONSE",
  ADD_USER_TYPE_ERROR: "ADD_USER_TYPE_ERROR",

  // edit user types
  EDIT_USER_TYPE_REQUEST: "EDIT_USER_TYPE_REQUEST",
  EDIT_USER_TYPE_RESPONSE: "EDIT_USER_TYPE_RESPONSE",
  EDIT_USER_TYPE_ERROR: "EDIT_USER_TYPE_ERROR",

  // users List actions
  RESET_USERS_LIST_STATE: "RESET_USERS_LIST_STATE",
  USERS_LIST_RESPONSE: "USERS_LIST_RESPONSE",
  USERS_LIST_REQUEST: "USERS_LIST_REQUEST",
  USERS_LIST_ERROR: "USERS_LIST_ERROR",

  // add user actions
  RESET_USER_DETAILS_STATE: "RESET_USER_DETAILS_STATE",
  ADD_USER_REQUEST: "ADD_USER_REQUEST",
  ADD_USER_ERROR: "ADD_USER_ERROR",
  ADD_USER_RESPONCE: "ADD_USER_RESPONCE",

  // get user by id actions
  RESET_GET_USER_BY_ID: "RESET_GET_USER_BY_ID",
  GET_USER_BY_ID_REQUEST: "GET_USER_BY_ID_REQUEST",
  GET_USER_BY_ID_RESPONCE: "GET_USER_BY_ID_RESPONCE",
  GET_USER_BY_ID_ERROR: "GET_USER_BY_ID_ERROR",

  // edit user by id actions
  RESET_UPDATE_USER_STATE: "RESET_UPDATE_USER_STATE",
  EDIT_USER_BY_ID_REQUEST: "EDIT_USER_BY_ID_REQUEST",
  EDIT_USER_BY_ID_RESPONCE: "EDIT_USER_BY_ID_RESPONCE",
  EDIT_USER_BY_ID_ERROR: "EDIT_USER_BY_ID_ERROR",

  // Doctors Patients List actions
  RESET_DOCTORS_PATIENTS_LIST_STATE: "RESET_DOCTORS_PATIENTS_LIST_STATE",
  DOCTORS_PATIENTS_LIST_RESPONSE: "DOCTORS_PATIENTS_LIST_RESPONSE",
  DOCTORS_PATIENTS_LIST_REQUEST: "DOCTORS_PATIENTS_LIST_REQUEST",
  DOCTORS_PATIENTS_LIST_ERROR: "DOCTORS_PATIENTS_LIST_ERROR",

  // Default User Type State
  RESET_USER_TYPE_STATE: "RESET_USER_TYPE_STATE",
  USER_TYPE_REQUEST: "USER_TYPE_REQUEST",
  USER_TYPE_SET_PATH: "USER_TYPE_SET_PATH",

  RESET_USERS_LIST_PAGENATION_STATE: "RESET_USERS_LIST_PAGENATION_STATE",
  USERS_LIST_PAGENATION_REQUEST: "USERS_LIST_PAGENATION_REQUEST",
  USERS_LIST_PAGENATION_RESPONSE: "USERS_LIST_PAGENATION_RESPONSE",
  USERS_LIST_PAGENATION_ERROR: "USERS_LIST_PAGENATION_ERROR",

  RESET_ACTIVE_FLAG: "RESET_ACTIVE_FLAG",
  SET_ACTIVE_FLAG: "SET_ACTIVE_FLAG",
};

// USER TYPES
export const setResetUserState = () => ({
  type: USER_ACTION.RESET_USER_STATE,
  data: undefined,
});

export const requestUserTypesAction = () => ({
  type: USER_ACTION.USER_REQUEST,
  data: undefined,
});

export const responseUserTypesAction = (res) => ({
  type: USER_ACTION.USER_RESPONSE,
  data: res,
});

export const errorUserTypesAction = (err) => ({
  type: USER_ACTION.USER_ERROR,
  data: err,
});

// ADD USER TYPES
export const requestAddUserTypesAction = () => ({
  type: USER_ACTION.ADD_USER_TYPE_REQUEST,
  data: undefined,
});

export const responseAddUserTypesAction = (res) => ({
  type: USER_ACTION.ADD_USER_TYPE_RESPONSE,
  data: res,
});

export const errorAddUserTypesAction = (err) => ({
  type: USER_ACTION.ADD_USER_TYPE_ERROR,
  data: err,
});

// EDIT USER TYPES
export const requestEditUserTypesAction = (data, id) => ({
  type: USER_ACTION.EDIT_USER_TYPE_REQUEST,
  data: data,
  id:id
});

export const responseEditUserTypesAction = (res) => ({
  type: USER_ACTION.EDIT_USER_TYPE_RESPONSE,
  data: res,
});

export const errorEditUserTypesAction = (err) => ({
  type: USER_ACTION.EDIT_USER_TYPE_ERROR,
  data: err,
});

// USERS LIST
export const setResetUserListState = () => ({
  type: USER_ACTION.RESET_USERS_LIST_STATE,
  data: undefined,
});

export const requestUsersListAction = (status, typeOfUser) => ({
  type: USER_ACTION.USERS_LIST_REQUEST,
  data: status, typeOfUser
});

export const responseUsersListAction = (res) => ({
  type: USER_ACTION.USERS_LIST_RESPONSE,
  data: res,
});

export const errorUsersListAction = (err) => ({
  type: USER_ACTION.USERS_LIST_ERROR,
  data: err,
});

// ADD  USER REDUCER STATE
export const resetUserStateAction = () => ({
  type: USER_ACTION.RESET_USER_DETAILS_STATE,
  data: undefined,
});

export const addUserRequestAction = (param) => ({
  type: USER_ACTION.ADD_USER_REQUEST,
  data: param,
});

export const responceAddUserAction = (res) => ({
  type: USER_ACTION.ADD_USER_RESPONCE,
  data: res,
});

export const errorAddUserAction = (err) => ({
  type: USER_ACTION.ADD_USER_ERROR,
  data: err,
});

// EDIT USER REDUCER STATE
export const resetUpdateUserStateAction = () => ({
  type: USER_ACTION.RESET_UPDATE_USER_STATE,
  data: undefined,
});
export const editUserbyIdRequestAction = (data,id) => ({
  type: USER_ACTION.EDIT_USER_BY_ID_REQUEST,
  data: data,
  id: id
});
export const responceEditUserAction = (res) => ({
  type: USER_ACTION.EDIT_USER_BY_ID_RESPONCE,
  data: res,
});

export const errorEditUserAction = (err) => ({
  type: USER_ACTION.EDIT_USER_BY_ID_ERROR,
  data: err,
});

// GET USER BY ID REDUCER STATE

export const resetGetUserByIdStateAction = () => ({
  type: USER_ACTION.RESET_GET_USER_BY_ID,
});

export const getUserByIdRequestAction = (param) => ({
  type: USER_ACTION.GET_USER_BY_ID_REQUEST,
  data: param,
});

export const responceGetUserByIdAction = (resp) => ({
  type: USER_ACTION.GET_USER_BY_ID_RESPONCE,
  data: resp,
});

export const errorGetUserByIdAction = (err) => ({
  type: USER_ACTION.GET_USER_BY_ID_ERROR,
  data: err,
});

// GET DOCTORS PATIENTS LIST REDUCER STATE
export const resetDoctorPatientsListStateAction = () => ({
  type: USER_ACTION.RESET_DOCTORS_PATIENTS_LIST_STATE,
});

export const getDoctorPatientsListRequestAction = (param) => ({
  type: USER_ACTION.DOCTORS_PATIENTS_LIST_REQUEST,
  data: param,
});

export const responceDoctorPatientsListAction = (resp) => ({
  type: USER_ACTION.DOCTORS_PATIENTS_LIST_RESPONSE,
  data: resp,
});

export const errorDoctorPatientsListAction = (err) => ({
  type: USER_ACTION.DOCTORS_PATIENTS_LIST_ERROR,
  data: err,
});

// Default User Type State
export const resetUserTypeStateAction = () => ({
  type: USER_ACTION.RESET_USER_TYPE_STATE,
  data: undefined,
});

export const setUserTypeRequestAction = (req) => ({
  type: USER_ACTION.USER_TYPE_REQUEST,
  data: req,
});

export const setUserTypeSetPathAction = () => ({
  type: USER_ACTION.USER_TYPE_SET_PATH,
  data: undefined,
});

/**
 * @USERS LIST PAGENATION STATE
 */

export const resetUsersListPagenationStateAction = () => ({
  type: USER_ACTION.RESET_USERS_LIST_PAGENATION_STATE,
  data: undefined,
});

export const requestUsersListPagenationAction = (paylaod) => ({
  type: USER_ACTION.USERS_LIST_PAGENATION_REQUEST,
  data: paylaod
});

export const responseUsersListPagenationAction = (res) => ({
  type: USER_ACTION.USERS_LIST_PAGENATION_RESPONSE,
  data: res
});

export const errorUsersListPagenationAction = (err) => ({
  type: USER_ACTION.USERS_LIST_PAGENATION_ERROR,
  data: err
})

export const resetActiveFlagAction = () => ({
  type: USER_ACTION.RESET_ACTIVE_FLAG,
  data: undefined
})

export const setActiveFlagAction = (payload) => ({
  type: USER_ACTION.SET_ACTIVE_FLAG,
  data: payload
})