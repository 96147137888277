import React, { useState } from "react";
import Plot from "react-plotly.js";
import PropTypes, { array } from "prop-types";

const generateDateRange = (start, end) => {
  const dates = [];
  const currentDate = new Date(start);
  const endDate = new Date(end);

  while (currentDate <= endDate) {
    dates.push(currentDate.toISOString()); // Format as YYYY-MM-DDTHH:mm:ss.sssZ
    currentDate.setDate(currentDate.getDate() + 1);
  }

  return dates;
};

const processReadings = (readings, allDates) => {
  const pulseRates = [];
  const spO2Values = [];
  const xValues = [];

  const dateSet = new Set(allDates.map(date => date.split("T")[0]));

  const filteredReadings = readings.filter(reading =>
    dateSet.has(reading.timeOfReadingTaken.split("T")[0])
  );

  filteredReadings.forEach((reading) => {
    pulseRates.push(reading.pulseRateValue);
    spO2Values.push(reading.spO2Value);
    xValues.push(reading.timeOfReadingTaken); // Use the full timestamp
  });

  // Add null values for missing dates within the range
  allDates.forEach((date) => {
    const dateOnly = date.split("T")[0];
    const hasReadingForDate = filteredReadings.some(reading =>
      reading.timeOfReadingTaken.startsWith(dateOnly)
    );

    if (!hasReadingForDate) {
      pulseRates.push(null); // Use null to indicate missing data
      spO2Values.push(null);
      xValues.push(date);
    }
  });

  return { pulseRates, spO2Values, xValues };
};


const LineGraph = (props) => {
  const { data, id } = props;
  const [dateRange, setDateRange] = useState("30days"); // Default to 30 days
  const today = new Date();
  const endDate = today.toISOString().split("T")[0];
  const startDate =
    dateRange === "7days"
      ? new Date(today.setDate(today.getDate() - 7)).toISOString().split("T")[0]
      : dateRange === "30days" ? new Date(today.setDate(today.getDate() - 30))
          .toISOString()
          .split("T")[0] : new Date(today.setDate(today.getDate() - 45))

  const allDates = generateDateRange(startDate, endDate);
  const { pulseRates, spO2Values, xValues } = processReadings(data, allDates);

  const layout = {
    
  };

  const config = {
    displayModeBar: true,
    displaylogo: false,
    modeBarButtonsToRemove: [
      "lasso2d",
      "select2d",
      // "resetScale2d",
      // "zoomIn2d",
      // "zoomOut2d",
      "autoScale2d",
      "pan2d",
      "zoom2d",
      "hoverClosestCartesian",
      "hoverCompareCartesian",
      "toggleSpikelines",
    ],
  };

  return (
    <div className="">
       <div className="flex flex-row align-center justify-center">
        <div className={`m-2 cursor-pointer w-1/2 ${dateRange === "7days" && 'border-b-4 pb-3 border-primary'} `} onClick={() => setDateRange("7days")}>
          <p className={`font-poppins text-sm text-center ${dateRange === "7days" && 'font-bold text-primary  '}`}>7 days</p>
        </div>
        <div className={`m-2 cursor-pointer w-1/2 ${dateRange === "30days" && 'border-b-4 pb-3 border-primary'}`} onClick={() => setDateRange("30days")}>
          <p className={`font-poppins text-sm text-center ${dateRange === "30days" && 'font-bold text-primary  '}`}>30 days</p>
        </div>
        {/* <div className={`m-2 cursor-pointer w-1/2 ${dateRange === "45days" && 'border-b-4 pb-3 border-primary'}`} onClick={() => setDateRange("45days")}>
          <p className={`font-poppins text-sm text-center ${dateRange === "45days" && 'font-bold text-primary  '}`}>45 days</p>
        </div> */}
      </div>
      <p className="font-poppins text-xs lg:text-sm xl:text-sm mt-4 text-center">Pulse Rate and SpO2 Levels Over Time for the device with device reference id:
               #{id}</p>
        <Plot
          data={[
            {
              x: xValues,
              y: pulseRates,
              mode: "lines+markers",
              type: "scatter",
              line: { color: "green" },
              name: "Pulse Rate",
              hovertemplate:
                "%{x|%B-%d-%Y <br> %H:%M:%S}<br>Pulse Rate: %{y}<extra></extra>",
            },
            {
              x: xValues,
              y: spO2Values,
              mode: "lines+markers",
              type: "scatter",
              name: "SpO2",
              hovertemplate:
                "%{x|%B-%d-%Y <br> %H:%M:%S}<br>SpO2: %{y}<extra></extra>",
            },
          ]}
          layout={{
            ...layout,
            xaxis: {
              type: "date",
              tickformat: "%b-%d",
              tickangle: -45,
              tickmode: "linear",
              dtick: 86400000,
              showgrid: false,
              
            },
            yaxis: {
              range: [30, 140],
              showgrid: true,
            }
          }}
          config={config}
          useResizeHandler={true}
          className="font-poppins text-xs w-full xl:w-11/12"
          
        />
      </div>
    );
};

LineGraph.propTypes = {
  data: PropTypes.instanceOf(array),
  id: PropTypes.string,
};

export default LineGraph;
