import { call, put, takeLatest, all } from "@redux-saga/core/effects";
import {
  errorMonitoringDeviceAction,
  errorRefreshMonitoringDataAction,
  MONITORING_ACTION,
  responseMointoringDeviceAction,
  responseRefreshMonitoringDataAction,
  responseCurrentDeviceReadingsAction,
} from "./MonitoringActionCreator";
import MonitoringService from "../../Services/RestApiManager/MonitoringManager/MonitoringService";

export function* getMonitoringDeviceDataRequest(payload) {
  try {
    let deviceIdList = payload.data.data;
    const data = yield all(
      deviceIdList?.map((id) =>
        call(MonitoringService.shared.getMonitoringDeviceDataByIdRequest, id, payload.data.patientId)
      )
    );
    const dataWithIds = data.map((result, index) => ({
      id: deviceIdList[index],
      data: result,
    }));
    yield put(responseMointoringDeviceAction(dataWithIds));
    let currentReadings = dataWithIds?.map((item) => {
      if (item.data && item.data.length > 0) {
        return item.data[item.data.length - 1];
      }
      return null;
    }).filter(item => item !== null); 
    
    yield put(responseCurrentDeviceReadingsAction(currentReadings));
  } catch (error) {
    yield put(errorMonitoringDeviceAction(error));
  }
}

export function* refreshMonitoringDeviceDataRequest(payload) {
  try {
    let deviceIdList = payload.data.data;
    yield all(
      deviceIdList?.map((id) =>
        call(MonitoringService.shared.refreshMonitoringDeviceDataRequest, id, payload.data.patientId)
      )
    );
    try {
      const data = yield all(
        deviceIdList?.map((id) =>
          call(MonitoringService.shared.getMonitoringDeviceDataByIdRequest, id, payload.data.patientId)
        )
      );
      const dataWithIds = data.map((result, index) => ({
        id: deviceIdList[index],
        data: result,
      }));
      yield put(responseMointoringDeviceAction(dataWithIds));
      let currentReadings = dataWithIds?.map((item) => {
        if (item.data && item.data.length > 0) {
          return item.data[item.data.length - 1];
        }
        return null; 
      }).filter(item => item !== null); 
      yield put(responseRefreshMonitoringDataAction())
      yield put(responseCurrentDeviceReadingsAction(currentReadings));
    } catch (error) {
      yield put(errorMonitoringDeviceAction(error));
    }
  } catch (error) {
    yield put(errorRefreshMonitoringDataAction(error));
  }
}

export function* getMonitoringDeviceWatcherSaga() {
  yield takeLatest(
    MONITORING_ACTION.MONITORING_DEVICE_REQUEST,
    getMonitoringDeviceDataRequest
  );
}

export function* refreshMonitoringDeviceDataWatcherSaga() {
  yield takeLatest(
    MONITORING_ACTION.MONITORING_DATA_REFRESH_REQUEST,
    refreshMonitoringDeviceDataRequest
  );
}
