import React from "react";
import PropTypes from "prop-types";
import { Formik } from "formik";
import TextInput from "../../../../NewUiComponents/TextInput";
import CustomButton from "../../../../NewUiComponents/CustomButton";
import { appData } from "../../../../Constants";
import CustomRadio from "../../../../NewUiComponents/Radio";
import CustomCheckbox from "../../../../NewUiComponents/CustomCheckbox";
import CustomSelectPicker from "../../../../NewUiComponents/CustomSelect";
import { DeviceSchema } from "../../../../Utilites/Schema";
import { useSelector } from "react-redux";
import { transformArray } from "../../../../Utilites/Helpers";

const AddNewDeviceForm = (props) => {
  const { initialValues, buttonName, formSubmit, view, id } = props;
  const { deviceTypes } = useSelector((state) => state.DeviceTypesListReducer);
  const { vendorsList } = useSelector((state) => state.VendorListReducer);

  return (
    <div className="justify-center">
      <Formik
        initialValues={initialValues}
        validationSchema={DeviceSchema}
        onSubmit={(values, { setSubmitting }) => {
          const changedKeys = Object.keys(values).filter(
            (key) => values[key] !== initialValues[key]
          );
          // Create a new object with only the changed fields
          const changedValues = {};
          changedKeys.forEach((key) => {
            changedValues[key] = values[key];
          });
          setSubmitting(false);
          const formValues = {
            // deviceName: values.deviceName,
            deviceType: values.deviceType,
            active: values.active,
            available: values.available,
            vendorId: values.vendorId,
            description: values.description,
            device_ref_key: values.device_ref_key,
          };
          formSubmit(view === "editDevice" ? changedValues  : formValues, view, id);
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
        }) => (
          <form
            method="POST"
            onSubmit={handleSubmit}
            className="w-full max-h-[85vh] overflow-y-scroll space-y-1"
          >
            <div className="lg:flex lg:space-x-6">
              <div className="lg:w-1/2">
                <TextInput
                  name="device_ref_key"
                  placeHolder="Device Reference Id"
                  label="Device Reference Id"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values?.device_ref_key}
                  disabled={view === "editDevice" || view === "view"}
                />
                <span className="text-danger text-sm">
                  {errors.device_ref_key && touched.device_ref_key && errors.device_ref_key}
                </span>
              </div>
              <div className="lg:w-1/2 pt-3"> 
                <CustomSelectPicker
                  data={transformArray(deviceTypes)}
                  name={"deviceType"}
                  placeHolder="Select Device Type"
                  isMulti={false}
                  label={"Device Type"}
                  isDisabled={view === "editDevice" || view === "view"}
                />
                <span className="text-danger text-sm">
                  {errors.deviceType && touched.deviceType && errors.deviceType}
                </span>
              </div>
            </div>
            <div className="lg:flex lg:space-x-6">
              <div className="lg:w-1/2 pt-3">
                <CustomSelectPicker
                  data={vendorsList.map((item) => ({
                    id: item?.id,
                    value: item?.id,
                    label: item?.vendorName,
                  }))}
                  name={"vendorId"}
                  placeHolder="Select the Vendor"
                  isMulti={false}
                  label={"Vendor"}
                  isDisabled={view === "editDevice" || view === "view"}
                />
                <span className="text-danger text-sm">
                  {errors.vendorId && touched.vendorId && errors.vendorId}
                </span>
              </div>
            </div>

            <div className="lg:flex lg:space-x-6">
              <div className="lg:w-1/2">
                <CustomRadio
                  data={appData.status}
                  name={"active"}
                  label={"Active"}
                  disabled={view === "view"}
                />
                <span className="text-danger text-sm">
                  {errors.active && touched.active && errors.active}
                </span>
              </div>
              <div className="lg:w-1/2">
                <CustomCheckbox
                  value={values?.available === "Y" ? true : false}
                  name={"available"}
                  label={"Available"}

                  isDisabled={view === "editDevice" || view === "view" }
                  onChange={(event) => {
                    const { checked } = event.target;
                    console.log(checked, "checked");
                    handleChange({
                      target: {
                        name: "available",
                        value: checked && "Y" || "N",
                      },
                    });
                  }}
                />
              </div>
            </div>
            <div className="w-full pt-3">
              <TextInput
                type={"textarea"}
                name="description"
                placeHolder="Device Description"
                label="Description"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values?.description}
                disabled={view === "editDevice" || view === "view"}
              />
              <span className="text-danger text-sm">
                {errors.description &&
                  touched.description &&
                  errors.description}
              </span>
            </div>
            <div className="pt-4">
              {buttonName && (
                <CustomButton
                  name={buttonName}
                  type={"submit"}
                  isDisabled={isSubmitting}
                />
              )}
            </div>
          </form>
        )}
      </Formik>
    </div>
  );
};

AddNewDeviceForm.propTypes = {
  buttonName: PropTypes.string.isRequired,
  formSubmit: PropTypes.func.isRequired,
  view: PropTypes.string,
  id: PropTypes.number,
  initialValues: PropTypes.shape({
    device_ref_key: PropTypes.string,
    deviceType: PropTypes.string,
    active: PropTypes.string,
    available: PropTypes.bool,
    description: PropTypes.string,
    vendorId: PropTypes.string,
  }),
};

export default AddNewDeviceForm;
