import { call, put, takeLatest } from "@redux-saga/core/effects";
import {
  errorLoginAction,
  LOGIN_ACTION,
  responseLoginAction,
  FP_ACTION,
  responseFpAction,
  errorFpAction,
  RESET_PASSWORD_ACTION,
  responseResetPasswordAction,
  errorResetPasswordAction,
  VERIFY_EMAIL_ACTION,
  responseVerifyEmailAction,
  errorVerifyEmailAction
} from "./LoginActionsCreator";
import AuthService from "../../Services/RestApiManager/AuthManager/AuthService";

export function* loginRequest(action) {
  try {
    const response = yield call(AuthService.shared.loginRequest, action.data);
    yield put(responseLoginAction(response));
  } catch (error) {
    yield put(errorLoginAction(error));
  }
}

export function* fpRequest(action) {
  try {
    const response = yield call(AuthService.shared.fpRequest, action.data);
    yield put(responseFpAction(response));
  } catch (error) {
    yield put(errorFpAction(error));
  }
}

export function* resetPasswordRequest(action) {
  try {
    const response = yield call(AuthService.shared.resetPassword, action.data);
    yield put(responseResetPasswordAction(response));
  } catch (error) {
    yield put(errorResetPasswordAction(error));
  }
}


export function* verifyEmailRequest(action) {
  try {
    const response = yield call(AuthService.shared.verifyEmail, action.data);
    yield put(responseVerifyEmailAction(response));
  } catch (error) {
    yield put(errorVerifyEmailAction(error));
  }
}

export function* loginWatcherSaga() {
  yield takeLatest(LOGIN_ACTION.REQUEST, loginRequest);
  yield takeLatest(FP_ACTION.REQUEST, fpRequest);
  yield takeLatest(RESET_PASSWORD_ACTION.REQUEST, resetPasswordRequest);
  yield takeLatest(VERIFY_EMAIL_ACTION.REQUEST, verifyEmailRequest);
}
