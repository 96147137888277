import React from "react";
import PropTypes from "prop-types";

const TextInput = (props) => {
  const { id, name, type, label, placeHolder, onChange, onBlur, value, disabled,icon } = props;

  // Use a variable to determine whether to render an input or textarea
  const inputElement = type === "textarea" ? (
    <textarea
      id={id}
      name={name}
      placeholder={placeHolder}
      onChange={onChange}
      onBlur={onBlur}
      value={value}
      disabled={disabled}
      className="inline-block w-full font-poppins
      border
      border-gray-light
      rounded-lg
      p-3
      shadow-md 
      placeholder:text-gray text-xs"
    />
  ) : (
    <div className="relative">
    <input
      id={id}
      name={name}
      type={type}
      placeholder={placeHolder}
      onChange={onChange}
      onBlur={onBlur}
      value={value}
      disabled={disabled}
      className="inline-block w-full font-poppins
        border
        border-gray-light
        rounded-lg
        p-3
        shadow-md 
        placeholder:text-gray text-xs
      "
    />
     {icon && (
        <span className="absolute inset-y-0 right-0 flex items-center pr-3">
          {icon}
        </span>
      )}    
    </div>
  );

  return (
    <div className="mt-4">
      <label className="block text-xs my-1 font-poppins">
        {label}
      </label>
      {inputElement}
    </div>
  );
};

TextInput.propTypes = {
  id: PropTypes.string,
  name: PropTypes.string,
  type: PropTypes.oneOf(["text", "textarea"]),
  placeHolder: PropTypes.string,
  label: PropTypes.string,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  value: PropTypes.string,
  disabled: PropTypes.bool,
  icon: PropTypes.element,
};

export default TextInput;
