export const MONITORING_ACTION = {
    // device List types actions
    RESET_MONITORING_DEVICE_STATE: "RESET_MONITORING_DEVICE_STATE",
    MONITORING_DEVICE_RESPONSE: "MONITORING_DEVICE_RESPONSE",
    MONITORING_DEVICE_REQUEST: "MONITORING_DEVICE_REQUEST",
    MONITORING_DEVICE_ERROR: "MONITORING_DEVICE_ERROR",

    // refresh monitoring device data actions

    RESET_MONITORING_DATA_REFRESH_STATE: "RESET_MONITORING_DATA_REFRESH_STATE",
    MONITORING_DATA_REFRESH_REQUEST: "MONITORING_DATA_REFRESH_REQUEST",
    MONITORING_DATA_REFRESH_RESPONSE: "MONITORING_DATA_REFRESH_RESPONSE",
    MONITORING_DATA_REFRESH_ERROR: "MONITORING_DATA_REFRESH_ERROR",

    // current Device Readings

    RESET_CURRENT_DEVICE_READINGS_STATE: "RESET_CURRENT_DEVICE_READINGS_STATE",
    CURRENT_DEVICE_READINGS_REQUEST: "CURRENT_DEVICE_READINGS_REQUEST",
    CURRENT_DEVICE_READINGS_RESPONSE: "CURRENT_DEVICE_READINGS_RESPONSE",
    CURRENT_DEVICE_READINGS_ERROR: "CURRENT_DEVICE_READINGS_ERROR",

  };

  // DEVICE TYPES 
  export const setResetMonitoringDevcieState = () => ({
    type: MONITORING_ACTION.RESET_MONITORING_DEVICE_STATE,
    data: undefined,
  });
  
  export const requestMonitoringDeviceAction = (payload) => ({
    type: MONITORING_ACTION.MONITORING_DEVICE_REQUEST,
    data: payload,
  });
  
  export const responseMointoringDeviceAction = (res) => ({
    type: MONITORING_ACTION.MONITORING_DEVICE_RESPONSE,
    data: res,
  });
  
  export const errorMonitoringDeviceAction = (err) => ({
    type: MONITORING_ACTION.MONITORING_DEVICE_ERROR,
    data: err,
  });

  // REFRESH MONITORING DATA
  
  export const setResetMonitoringDataRefreshState = () => ({
    type: MONITORING_ACTION.RESET_MONITORING_DATA_REFRESH_STATE,
    data: undefined,
  });

  export const requestRefreshMonitoringDataAction = (payload) => ({
    type: MONITORING_ACTION.MONITORING_DATA_REFRESH_REQUEST,
    data: payload,
  });

  export const responseRefreshMonitoringDataAction = () => ({
    type: MONITORING_ACTION.MONITORING_DATA_REFRESH_RESPONSE,
    data: undefined,
  });

  export const errorRefreshMonitoringDataAction = (res) => ({
    type: MONITORING_ACTION.MONITORING_DATA_REFRESH_ERROR,
    data: res,
  });


  // CURRENT DEVICE READINGS

  export const setResetCurrentDeviceReadingsState = () => ({
    type: MONITORING_ACTION.RESET_CURRENT_DEVICE_READINGS_STATE,
    data: undefined,
  });

  export const requestCurrentDeviceReadingsAction = () => ({
    type: MONITORING_ACTION.CURRENT_DEVICE_READINGS_REQUEST,
    data: undefined,
  });

  export const responseCurrentDeviceReadingsAction = (res) => ({
    type: MONITORING_ACTION.CURRENT_DEVICE_READINGS_RESPONSE,
    data: res,
  });

  export const errorCurrentDeviceReadingsAction = (res) => ({
    type: MONITORING_ACTION.CURRENT_DEVICE_READINGS_ERROR,
    data: res,
  });

