import { ApiUrl } from "../../../Constants/ApiConstants";
import {
  REQUEST_METHOD,
  WebService,
} from "../../WebServiceManager/RequestService";
import ProfileRequestBody from "./ProfileRequestBody";

export default class ProfileService {
  request = new ProfileRequestBody();
  static shared = new ProfileService();

  changePasswordRequest = (payload) => {
    return new Promise((resolve, reject) => {
      WebService.clientShared
        .callServiceApi(
          ApiUrl.changePassword(),
          this.request.changePasswordRequest(payload, REQUEST_METHOD.PUT)
        )
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };
}
