import { ApiUrl } from "../../../Constants/ApiConstants";
import {
  REQUEST_METHOD,
  WebService,
} from "../../WebServiceManager/RequestService";
import PatientRequestBody from "./PatientRequestBody";

export default class PatientService {
  request = new PatientRequestBody();
  static shared = new PatientService();

  addPatientRequest = (payload) => {
    return new Promise((resolve, reject) => {
      WebService.clientShared
        .callServiceApi(
          ApiUrl.addPatients(),
          this.request.addPatientRequest(payload, REQUEST_METHOD.POST)
        )
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  updatePatientRequest = (payload) => {
    return new Promise((resolve, reject) => {
      WebService.clientShared
        .callServiceApi(
          ApiUrl.addPatients(),
          this.request.updatePatientRequest(payload, REQUEST_METHOD.PUT)
        )
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  patientListRequest = (payload) => {
    console.log(payload)
    return new Promise((resolve, reject) => {
      WebService.clientShared
        .callServiceApi(
          ApiUrl.getPatients(payload.patientListStatus),
          this.request.patientListRequest(payload, REQUEST_METHOD.GET)
        )
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  patientListPagenationRequest = (payload) => {
    return new Promise((resolve, reject) => {
      WebService.clientShared
        .callServiceApi(
          ApiUrl.getPatientsListPagenation(payload.status, payload.page, payload.size),
          this.request.patientListPagenationRequest(REQUEST_METHOD.GET)
        )
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  linkDevicePatientRequest = (payload) => {
    return new Promise((resolve, reject) => {
      WebService.clientShared
        .callServiceApi(
          ApiUrl.linkDevicePatients(),
          this.request.patientListRequest(payload, REQUEST_METHOD.PUT)
        )
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }


  linkDevicePatientListRequest = (payload) => {
    return new Promise((resolve, reject) => {
      WebService.clientShared
        .callServiceApi(
          ApiUrl.linkDevicePatientListRequest(payload.patientId),
          this.request.patientListRequest(payload, REQUEST_METHOD.GET)
        )
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }



  linkDoctorPatientRequest = (payload) => {
    return new Promise((resolve, reject) => {
      WebService.clientShared
        .callServiceApi(
          ApiUrl.linkDoctorPatients(),
          this.request.patientListRequest(payload, REQUEST_METHOD.PUT)
        )
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }


  linkDoctorPatientListRequest = (payload) => {
    return new Promise((resolve, reject) => {
      WebService.clientShared
        .callServiceApi(
          ApiUrl.linkDoctorPatientListRequest(payload.patientId),
          this.request.patientListRequest(payload, REQUEST_METHOD.GET)
        )
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }



  patientByIdRequest = (payload) => {
    return new Promise((resolve, reject) => {
      WebService.clientShared
        .callServiceApi(
          ApiUrl.getPatientById(payload.patientId),
          this.request.patientListRequest(payload, REQUEST_METHOD.GET)
        )
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }






}
