import React, { useState, useEffect } from 'react';
import AppNavigation from './Navigation';
import MobileViewWarning from '../src/NewUiComponents/MobileViewWarning';

const App = () => {

const [width, setWidth] = useState(window.innerWidth);

const  handleWindowSizeChange = () =>  {
    setWidth(window.innerWidth);
}

useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange);
    return () => {
        window.removeEventListener('resize', handleWindowSizeChange);
    }
}, []);

const isMobile = width < 768;


  return(
    isMobile ? <MobileViewWarning /> : <AppNavigation />
  )
}

export default App;