import React from "react";
import Toggle from "react-toggle";
import "react-toggle/style.css";
import PropTypes from "prop-types";


const CustomToggle = (props) => {
  const { isChecked,handleToggleChange }  = props;  
  return (
    <Toggle
      checked={isChecked}
      icons={false}
      onChange={handleToggleChange}
      size={1}
      
    />
  );
};

CustomToggle.propTypes = {
    handleToggleChange: PropTypes.func,
    isChecked: PropTypes.bool,
  };

export default CustomToggle;
