import * as ReportsActionCreator from "./ReportsActionCreator";

export function reportsState() {
  return {
    isReportsDataLoading: false,
    reportsData: null,
    reportsPatientQurey: null,
    reportsError: null,
    deviceRefId: null,
    page: 0,
    size: 10
  };
}
export const ReportsReducer = (state, action) => {
  if (
    !state ||
    action.type === ReportsActionCreator.REPORTS_ACTION.RESET_REPORTS_STATE
  ) {
    return { ...state, ...reportsState() };
  }

  if (
    action.type === ReportsActionCreator.REPORTS_ACTION.REQUEST_REPORTS_STATE
  ) {
    return {
      ...state,
      isReportsDataLoading: true,
      page: action.data?.page,
      size: action.data?.size,
      deviceRefId: action.data.selectDevice,
      reportsPatientQurey: {
        id: action.data?.patientRefId,
        name: action.data?.patient_name,
        from: action.data?.fromdate,
        to: action.data?.todate,
      },
    };
  }

  if (
    action.type === ReportsActionCreator.REPORTS_ACTION.RESPONCE_REPORTS_STATE
  ) {
    return { ...state, reportsData: action.data, isReportsDataLoading: false };
  }

  if (action.type === ReportsActionCreator.REPORTS_ACTION.ERROR_REPORTS_STATE) {
    return {
      ...state,
      reportsError: action.data,
      isReportsDataLoading: false,
      reportsData: null,
      reportsPatientId: null,
    };
  }

  return state;
};


export function downloadReportState() {
  return {
    isReportsDataLoading: false,
    reportsError: null
  };
}

export const DownloadReportReducer = (state, action) => {
  if (
    !state ||
    action.type === ReportsActionCreator.REPORTS_ACTION.RESET_DOWNLOAD_REPORT_STATE
  ) {
    return { ...state, ...downloadReportState() };
  }

  if (
    action.type === ReportsActionCreator.REPORTS_ACTION.REQUEST_DOWNLOAD_REPORT_STATE
  ) {
    return {
      ...state,
      isReportsDataLoading: true
    };
  }

  if (
    action.type === ReportsActionCreator.REPORTS_ACTION.RESPONCE_DOWNLOAD_REPORT_STATE
  ) {
    return { ...state, isReportsDataLoading: false };
  }

  if (action.type === ReportsActionCreator.REPORTS_ACTION.ERROR_DOWNLOAD_REPORT_STATE) {
    return {
      ...state,
      reportsError: action.data,
      isReportsDataLoading: false
    };
  }

  return state;
}