
import React from "react";
import { Outlet, Navigate } from "react-router-dom";
import LOCAL_KEYS, { parseLocalStorageJSON } from "../../Utilites/LocalStorage";

const PublicRoutes = () => {
  const accessToken = parseLocalStorageJSON(LOCAL_KEYS.ACCESS_TOKEN);
  return !accessToken  ? <Outlet /> : <Navigate to="/dashboard" />

};

export default PublicRoutes;

