import React from "react";
import PropTypes from "prop-types";
import Logo from '../../NewUiComponents/Logo';

const NavItem = ({ title }) => {
  return (
    <li className="flex flex-row">
      <p className="text-white text-sm font-poppins">{title}</p>
    </li>
  );
};

const LoginHeader = () => {
  return (
    <nav className="position-absolute flex flex-row justify-around items-center p-6">
      <Logo />
      <ul className="flex flex-row gap-12">
        <NavItem title={"Privacy Policy"} />
        <NavItem title={"Terms & Conditions"} />
        <NavItem title={"About us"} />
      </ul>
    </nav>
  );
};

NavItem.propTypes = {
  title: PropTypes.string,
};

export default LoginHeader;
