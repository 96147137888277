export const DASHBOARD_ACTION =  {
    RESET_DASHBOARD_STATE: 'RESET_DASHBOARD_STATE',
    DASHBOARD_COUNT_REQUEST: 'DASHBOARD_COUNT_REQUEST',
    DASHBOARD_COUNT_RESPONSE:  'DASHBOARD_COUNT_RESPONSE',
    DASHBOARD_COUNT_ERROR: 'DASHBOARD_COUNT_ERROR'
  }
  
  export const setResetDashboardState = () => ({
    type: DASHBOARD_ACTION.RESET_DASHBOARD_STATE,
    data: undefined
  });
  
  export const requestDashboardCountAction = (params) => ({
    type: DASHBOARD_ACTION.DASHBOARD_COUNT_REQUEST,
    data: params
  });
  
  export const responseDashboardCountAction = (res) => ({
    type: DASHBOARD_ACTION.DASHBOARD_COUNT_RESPONSE,
    data: res
  });
  
  export const errorDashboardCountAction = (err) => ({
    type: DASHBOARD_ACTION.DASHBOARD_COUNT_ERROR,
    data: err
  });