import React from "react";
import {
  FaHome,
  FaUsers,
  FaWheelchair,
  FaHeartbeat,
  FaUsersCog,
  FaSignOutAlt,
} from "react-icons/fa";

export const appMessages = {
  CHANGE_PASSWORD_SUCCESS: "Password update successfully",
  INVALID_SESSION: "Session invalid",
  UNAUTHORIZED_ACCESS: "Unauthorized access",
  ADD_PATIENT_SUCCESS: "Patient added successfully",
  ADD_PATIENT_ERROR: "Patient not added.Please try again later",
  PATIENT_LIST_ERROR: "Unable to fetch patient list.Please try again later",
  DOCTOR_LIST_ERROR: "Unable to fetch doctor list.Please try again later",
  DEVICE_LIST_ERROR: "Unable to fetch device list.Please try again later",
  LOGIN_SUCCESS: "Logged in successfully",
  LOGIN_ERROR: "Invalid Credentials",
  UPDATE_PATIENT_SUCCESS: "Patient details updated successfully",
  UPDATE_PATIENT_ERROR: "Patient not updated.Please try again later",
  DEVICE_PATIENT_REMOVE_ERROR:
    "Unable to remove association with device.Please try later",
  DOCTOR_PATIENT_REMOVE_ERROR:
    "Unable to remove association with doctor.Please try later",
  UPDATED_SUCCESSFULLY: "Updated successfully",
  ERROR_OCCURED: "Error occured.Please try again later",
  ADD_USER_SUCCESS: "User addes successfully",
  ADD_USER_ERROR: "User not added.Please try again later",
  UPDATE_USER_SUCCESSFULLY: "User details updated successfully",
  UPDATE_USER_ERROR: "User details not updated.Please try again later",
  ADD_DEVICE_SUCCESS: "Device added successfully",
  ADD_DEVICE_ERROR: "Device not added.Please try againn later",
  UPDATE_DEVICE_SUCCESSFULLY: "Device details updated successfully",
  UPDATE_DEVICE_ERROR: "Device details not updated.Please try again later",
  USERS_LIST_ERROR: "Unable to fetch users list.Please try again later",
  DEVICES_LIST_ERROR: "Unable to fetch devices list.Please try again later",
  PATIENTS_LIST_ERROR: "Unable to fetch patients list.Please try again later",
  DEVICE_MONITORING_DATA_ERROR:
    "Unable to fetch device monitoring data.Please try again later",
  REPORTS_ERROR: "Unable to fetch reports.Please try again later",
  FP_MESSAGE: "We have sent an password reset link to your registered email address",
  INVALID_TOKEN: 'Invalid token.Please try again later',
  RESET_SUCCESSFULL: 'Password updated successfully.Please sign in to your account',
  RESET_FAILED: 'Password reset failed.Please try again later'
};

export const appData = {
  gender: [
    {
      id: 1 + "male",
      value: "M",
      title: "Male",
    },
    {
      id: 2 + "female",
      value: "F",
      title: "Female",
    },
  ],
  status: [
    {
      id: 1 + "active",
      value: "Y",
      title: "Yes",
    },
    {
      id: 2 + "inactive",
      value: "N",
      title: "No",
    },
  ],
  intervalsInSeconds: [
    {
      id: 1,
      value: "15",
      label: "15",
    },
    {
      id: 2,
      value: "30",
      label: "30",
    },
    {
      id: 3,
      value: "45",
      label: "45",
    },
    {
      id: 4,
      value: "60",
      label: "60",
    },
  ],
  listFlag: [
    {
      id: 0 + "all",
      value: "all",
      label: "all",
    },
    {
      id: 1 + "active",
      value: "active",
      label: "active",
    },
    {
      id: 2 + "inactive",
      value: "inactive",
      label: "inactive",
    },
  ],
  statusList: [
    {
      id: 1 + "active",
      value: "Y",
      title: "Active",
    },
    {
      id: 2 + "inactive",
      value: "N",
      title: "Inactive",
    },
  ],
  roles: [
    { 
      id: 1 + "all", 
      value: "all", 
      label: "ALL" 
    },
    { 
      id: 2 + "HOSPITAL_ADMIN", 
      value: "HOSPITAL_ADMIN", 
      label: "HOSPITAL_ADMIN" 
    },
    { 
      id: 3 + "HOSPITAL_USER", 
      value: "HOSPITAL_USER", 
      label: "HOSPITAL_USER" 
    },
    { 
      id: 4 + "DOCTOR", 
      value: "DOCTOR", 
      label: "DOCTOR" 
    },
  ],
};
export const menu = [
  {
    id: 1,
    name: "Dashboard",
    path: "/dashboard",
    icon: <FaHome className="px-1 h-6 w-6" />,
    access: ["ADMIN", "DOCTOR", "HOSPITAL_ADMIN", "HOSPITAL_USER"],
  },
  {
    id: 2,
    name: "Users",
    path: "/users",
    icon: <FaUsers className="px-1 h-6 w-6" />,
    access: ["ADMIN", "HOSPITAL_ADMIN"],
  },
  {
    id: 3,
    name: "Patients",
    path: "/patients",
    icon: <FaWheelchair className="px-1 h-6 w-6" />,
    access: ["ADMIN", "HOSPITAL_ADMIN"],
  },
  {
    id: 4,
    name: "Devices",
    path: "/devices",
    icon: <FaHeartbeat className="px-1 h-6 w-6" />,
    access: ["ADMIN", "HOSPITAL_ADMIN"],
  },
  {
    id: 5,
    name: "Profile",
    icon: <FaUsersCog className="px-1 h-6 w-6" />,
    access: ["ADMIN", "DOCTOR", "HOSPITAL_ADMIN", "HOSPITAL_USER"],
    path: "/change-password",
  },
  {
    id: 6,
    name: "Logout",
    path: "/logout",
    icon: <FaSignOutAlt className="px-1 h-6 w-6" />,
    access: ["ADMIN", "DOCTOR", "HOSPITAL_ADMIN", "HOSPITAL_USER"],
  },
];

export const SidebarData = [
  {
    id: 1,
    onlyHeader: false,
    title: "Dashboard",
    path: "/dashboard",
    access: ["ADMIN", "DOCTOR", "HOSPITAL_ADMIN", "HOSPITAL_USER"],
    icon: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        strokeWidth={1.5}
        stroke="#000"
        className="size-5 lg:size-4 xl:size-5"
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          d="m2.25 12 8.954-8.955c.44-.439 1.152-.439 1.591 0L21.75 12M4.5 9.75v10.125c0 .621.504 1.125 1.125 1.125H9.75v-4.875c0-.621.504-1.125 1.125-1.125h2.25c.621 0 1.125.504 1.125 1.125V21h4.125c.621 0 1.125-.504 1.125-1.125V9.75M8.25 21h8.25"
        />
      </svg>
    ),
    activeIcon: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        strokeWidth={3}
        stroke="#1a4490"
        className="size-5 lg:size-4 xl:size-5"
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          d="m2.25 12 8.954-8.955c.44-.439 1.152-.439 1.591 0L21.75 12M4.5 9.75v10.125c0 .621.504 1.125 1.125 1.125H9.75v-4.875c0-.621.504-1.125 1.125-1.125h2.25c.621 0 1.125.504 1.125 1.125V21h4.125c.621 0 1.125-.504 1.125-1.125V9.75M8.25 21h8.25"
        />
      </svg>
    ),
  },
  {
    id: 2,
    onlyHeader: false,
    title: "Users",
    access: ["ADMIN", "HOSPITAL_ADMIN"],
    path: "/users",
    icon: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        strokeWidth={1.5}
        stroke="#000"
        className="size-5 lg:size-4 xl:size-5"
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M15 19.128a9.38 9.38 0 0 0 2.625.372 9.337 9.337 0 0 0 4.121-.952 4.125 4.125 0 0 0-7.533-2.493M15 19.128v-.003c0-1.113-.285-2.16-.786-3.07M15 19.128v.106A12.318 12.318 0 0 1 8.624 21c-2.331 0-4.512-.645-6.374-1.766l-.001-.109a6.375 6.375 0 0 1 11.964-3.07M12 6.375a3.375 3.375 0 1 1-6.75 0 3.375 3.375 0 0 1 6.75 0Zm8.25 2.25a2.625 2.625 0 1 1-5.25 0 2.625 2.625 0 0 1 5.25 0Z"
        />
      </svg>
    ),

    activeIcon: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        strokeWidth={3}
        stroke="#1a4490"
        className="size-5 lg:size-4 xl:size-5"
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M15 19.128a9.38 9.38 0 0 0 2.625.372 9.337 9.337 0 0 0 4.121-.952 4.125 4.125 0 0 0-7.533-2.493M15 19.128v-.003c0-1.113-.285-2.16-.786-3.07M15 19.128v.106A12.318 12.318 0 0 1 8.624 21c-2.331 0-4.512-.645-6.374-1.766l-.001-.109a6.375 6.375 0 0 1 11.964-3.07M12 6.375a3.375 3.375 0 1 1-6.75 0 3.375 3.375 0 0 1 6.75 0Zm8.25 2.25a2.625 2.625 0 1 1-5.25 0 2.625 2.625 0 0 1 5.25 0Z"
        />
      </svg>
    ),
  },
  {
    id: 3,
    onlyHeader: false,
    title: "Patients",
    path: "/patients",
    access: ["ADMIN", "HOSPITAL_ADMIN"],
    icon: (
      <svg
        fill="#000  "
        viewBox="0 0 16 16"
        className="size-5 lg:size-4 xl:size-5"
      >
        <path d="M8.5 4.5a.5.5 0 00-1 0v.634l-.549-.317a.5.5 0 10-.5.866L7 6l-.549.317a.5.5 0 10.5.866l.549-.317V7.5a.5.5 0 101 0v-.634l.549.317a.5.5 0 10.5-.866L9 6l.549-.317a.5.5 0 10-.5-.866l-.549.317V4.5zM5.5 9a.5.5 0 000 1h5a.5.5 0 000-1h-5zm0 2a.5.5 0 000 1h5a.5.5 0 000-1h-5z" />
        <path d="M2 2a2 2 0 012-2h8a2 2 0 012 2v12a2 2 0 01-2 2H4a2 2 0 01-2-2V2zm10-1H4a1 1 0 00-1 1v12a1 1 0 001 1h8a1 1 0 001-1V2a1 1 0 00-1-1z" />
      </svg>
    ),

    activeIcon: (
      <svg
        fill="#1a4490"
        viewBox="0 0 16 16"
        className="size-5 lg:size-4 xl:size-5"
        strokeWidth={3}
      >
        <path d="M8.5 4.5a.5.5 0 00-1 0v.634l-.549-.317a.5.5 0 10-.5.866L7 6l-.549.317a.5.5 0 10.5.866l.549-.317V7.5a.5.5 0 101 0v-.634l.549.317a.5.5 0 10.5-.866L9 6l.549-.317a.5.5 0 10-.5-.866l-.549.317V4.5zM5.5 9a.5.5 0 000 1h5a.5.5 0 000-1h-5zm0 2a.5.5 0 000 1h5a.5.5 0 000-1h-5z" />
        <path d="M2 2a2 2 0 012-2h8a2 2 0 012 2v12a2 2 0 01-2 2H4a2 2 0 01-2-2V2zm10-1H4a1 1 0 00-1 1v12a1 1 0 001 1h8a1 1 0 001-1V2a1 1 0 00-1-1z" />
      </svg>
    ),
  },

  {
    id: 33,
    onlyHeader: false,
    title: "Reports",
    path: "/reports",
    access: ["ADMIN", "HOSPITAL_ADMIN"],
    icon: (
      <svg
        fill="none"
        stroke="#000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={2}
        viewBox="0 0 24 24"
        className="size-5 lg:size-4 xl:size-5"
      >
        <path stroke="none" d="M0 0h24v24H0z" />
        <path d="M8 5H6a2 2 0 00-2 2v12a2 2 0 002 2h5.697M18 14v4h4M18 11V7a2 2 0 00-2-2h-2" />
        <path d="M10 3 H12 A2 2 0 0 1 14 5 V5 A2 2 0 0 1 12 7 H10 A2 2 0 0 1 8 5 V5 A2 2 0 0 1 10 3 z" />
        <path d="M22 18 A4 4 0 0 1 18 22 A4 4 0 0 1 14 18 A4 4 0 0 1 22 18 z" />
        <path d="M8 11h4M8 15h3" />
      </svg>
    ),
    activeIcon: (
      <svg
        fill="none"
        stroke="#1a4490"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={2}
        viewBox="0 0 24 24"
        className="size-5 lg:size-4 xl:size-5"
      >
        <path stroke="none" d="M0 0h24v24H0z" />
        <path d="M8 5H6a2 2 0 00-2 2v12a2 2 0 002 2h5.697M18 14v4h4M18 11V7a2 2 0 00-2-2h-2" />
        <path d="M10 3 H12 A2 2 0 0 1 14 5 V5 A2 2 0 0 1 12 7 H10 A2 2 0 0 1 8 5 V5 A2 2 0 0 1 10 3 z" />
        <path d="M22 18 A4 4 0 0 1 18 22 A4 4 0 0 1 14 18 A4 4 0 0 1 22 18 z" />
        <path d="M8 11h4M8 15h3" />
      </svg>
    ),
  },

  {
    id: 4,
    onlyHeader: false,
    title: "Devices",
    access: ["ADMIN", "HOSPITAL_ADMIN"],
    path: "/devices",
    icon: (
      <svg
        data-name="Layer 1"
        viewBox="0 0 24 24"
        fill="#000"
        className="size-6"
      >
        <path d="M21 10.41h-2.5a1 1 0 00-.71.3L16.55 12l-2.8-3.19a1 1 0 00-1.46 0l-1.7 1.7H9a1 1 0 000 2h2a1 1 0 00.71-.29L13 10.88l2.8 3.19a1 1 0 00.72.34 1 1 0 00.71-.29l1.7-1.71H21a1 1 0 000-2zm-7.39 5.3l-1.9 1.9a1 1 0 01-1.42 0L5.08 12.4a3.69 3.69 0 010-5.22 3.69 3.69 0 015.21 0 1 1 0 001.42 0 3.78 3.78 0 015.21 0 3.94 3.94 0 01.58.75 1 1 0 001.72-1 6 6 0 00-.88-1.13A5.68 5.68 0 0011 5.17a5.68 5.68 0 00-9 4.62 5.62 5.62 0 001.67 4L8.88 19a3 3 0 004.24 0L15 17.12a1 1 0 000-1.41 1 1 0 00-1.39 0z" />
      </svg>
    ),

    activeIcon: (
      <svg
        data-name="Layer 1"
        viewBox="0 0 24 24"
        fill="#1a4490"
        className="size-6"
        strokeWidth={3}
      >
        <path d="M21 10.41h-2.5a1 1 0 00-.71.3L16.55 12l-2.8-3.19a1 1 0 00-1.46 0l-1.7 1.7H9a1 1 0 000 2h2a1 1 0 00.71-.29L13 10.88l2.8 3.19a1 1 0 00.72.34 1 1 0 00.71-.29l1.7-1.71H21a1 1 0 000-2zm-7.39 5.3l-1.9 1.9a1 1 0 01-1.42 0L5.08 12.4a3.69 3.69 0 010-5.22 3.69 3.69 0 015.21 0 1 1 0 001.42 0 3.78 3.78 0 015.21 0 3.94 3.94 0 01.58.75 1 1 0 001.72-1 6 6 0 00-.88-1.13A5.68 5.68 0 0011 5.17a5.68 5.68 0 00-9 4.62 5.62 5.62 0 001.67 4L8.88 19a3 3 0 004.24 0L15 17.12a1 1 0 000-1.41 1 1 0 00-1.39 0z" />
      </svg>
    ),
  },
  {
    id: 5,
    onlyHeader: false,
    title: "Settings",
    access: ["ADMIN", "DOCTOR", "HOSPITAL_ADMIN", "HOSPITAL_USER"],
    path: "/change-password",
    icon: (
      <svg
        viewBox="0 0 512 512"
        fill="#000"
        className="size-5 lg:size-4 xl:size-5"
      >
        <path
          fill="none"
          stroke="#000"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={32}
          d="M262.29 192.31a64 64 0 1057.4 57.4 64.13 64.13 0 00-57.4-57.4zM416.39 256a154.34 154.34 0 01-1.53 20.79l45.21 35.46a10.81 10.81 0 012.45 13.75l-42.77 74a10.81 10.81 0 01-13.14 4.59l-44.9-18.08a16.11 16.11 0 00-15.17 1.75A164.48 164.48 0 01325 400.8a15.94 15.94 0 00-8.82 12.14l-6.73 47.89a11.08 11.08 0 01-10.68 9.17h-85.54a11.11 11.11 0 01-10.69-8.87l-6.72-47.82a16.07 16.07 0 00-9-12.22 155.3 155.3 0 01-21.46-12.57 16 16 0 00-15.11-1.71l-44.89 18.07a10.81 10.81 0 01-13.14-4.58l-42.77-74a10.8 10.8 0 012.45-13.75l38.21-30a16.05 16.05 0 006-14.08c-.36-4.17-.58-8.33-.58-12.5s.21-8.27.58-12.35a16 16 0 00-6.07-13.94l-38.19-30A10.81 10.81 0 0149.48 186l42.77-74a10.81 10.81 0 0113.14-4.59l44.9 18.08a16.11 16.11 0 0015.17-1.75A164.48 164.48 0 01187 111.2a15.94 15.94 0 008.82-12.14l6.73-47.89A11.08 11.08 0 01213.23 42h85.54a11.11 11.11 0 0110.69 8.87l6.72 47.82a16.07 16.07 0 009 12.22 155.3 155.3 0 0121.46 12.57 16 16 0 0015.11 1.71l44.89-18.07a10.81 10.81 0 0113.14 4.58l42.77 74a10.8 10.8 0 01-2.45 13.75l-38.21 30a16.05 16.05 0 00-6.05 14.08c.33 4.14.55 8.3.55 12.47z"
        />
      </svg>
    ),

    activeIcon: (
      <svg
        viewBox="0 0 512 512"
        fill="#1a4490"
        className="size-5 lg:size-4 xl:size-5"
      >
        <path
          fill="none"
          stroke="#1a4490"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={32}
          d="M262.29 192.31a64 64 0 1057.4 57.4 64.13 64.13 0 00-57.4-57.4zM416.39 256a154.34 154.34 0 01-1.53 20.79l45.21 35.46a10.81 10.81 0 012.45 13.75l-42.77 74a10.81 10.81 0 01-13.14 4.59l-44.9-18.08a16.11 16.11 0 00-15.17 1.75A164.48 164.48 0 01325 400.8a15.94 15.94 0 00-8.82 12.14l-6.73 47.89a11.08 11.08 0 01-10.68 9.17h-85.54a11.11 11.11 0 01-10.69-8.87l-6.72-47.82a16.07 16.07 0 00-9-12.22 155.3 155.3 0 01-21.46-12.57 16 16 0 00-15.11-1.71l-44.89 18.07a10.81 10.81 0 01-13.14-4.58l-42.77-74a10.8 10.8 0 012.45-13.75l38.21-30a16.05 16.05 0 006-14.08c-.36-4.17-.58-8.33-.58-12.5s.21-8.27.58-12.35a16 16 0 00-6.07-13.94l-38.19-30A10.81 10.81 0 0149.48 186l42.77-74a10.81 10.81 0 0113.14-4.59l44.9 18.08a16.11 16.11 0 0015.17-1.75A164.48 164.48 0 01187 111.2a15.94 15.94 0 008.82-12.14l6.73-47.89A11.08 11.08 0 01213.23 42h85.54a11.11 11.11 0 0110.69 8.87l6.72 47.82a16.07 16.07 0 009 12.22 155.3 155.3 0 0121.46 12.57 16 16 0 0015.11 1.71l44.89-18.07a10.81 10.81 0 0113.14 4.58l42.77 74a10.8 10.8 0 01-2.45 13.75l-38.21 30a16.05 16.05 0 00-6.05 14.08c.33 4.14.55 8.3.55 12.47z"
        />
      </svg>
    ),
  },
  {
    id: 6,
    onlyHeader: true,
    title: "Profile",
    access: ["ADMIN", "DOCTOR", "HOSPITAL_ADMIN", "HOSPITAL_USER"],
    path: "/profile",
    icon: (
      <svg viewBox="0 0 512 512" className="size-5 lg:size-4 xl:size-5">
        <path d="M256 112c-48.6 0-88 39.4-88 88s39.4 88 88 88 88-39.4 88-88-39.4-88-88-88zm0 128c-22.06 0-40-17.95-40-40 0-22.1 17.9-40 40-40s40 17.94 40 40c0 22.1-17.9 40-40 40zm0-240C114.6 0 0 114.6 0 256s114.6 256 256 256 256-114.6 256-256S397.4 0 256 0zm0 464c-46.73 0-89.76-15.68-124.5-41.79C148.8 389 182.4 368 220.2 368h71.69c37.75 0 71.31 21.01 88.68 54.21C345.8 448.3 302.7 464 256 464zm160.2-75.5c-27-42.2-73-68.5-124.4-68.5h-71.6c-51.36 0-97.35 26.25-124.4 68.48C65.96 352.5 48 306.3 48 256c0-114.7 93.31-208 208-208s208 93.31 208 208c0 50.3-18 96.5-47.8 132.5z" />
      </svg>
    ),

    activeIcon: (
      <svg
        viewBox="0 0 512 512"
        fill="#1a4490"
        className="size-5 lg:size-4 xl:size-5"
      >
        <path d="M256 112c-48.6 0-88 39.4-88 88s39.4 88 88 88 88-39.4 88-88-39.4-88-88-88zm0 128c-22.06 0-40-17.95-40-40 0-22.1 17.9-40 40-40s40 17.94 40 40c0 22.1-17.9 40-40 40zm0-240C114.6 0 0 114.6 0 256s114.6 256 256 256 256-114.6 256-256S397.4 0 256 0zm0 464c-46.73 0-89.76-15.68-124.5-41.79C148.8 389 182.4 368 220.2 368h71.69c37.75 0 71.31 21.01 88.68 54.21C345.8 448.3 302.7 464 256 464zm160.2-75.5c-27-42.2-73-68.5-124.4-68.5h-71.6c-51.36 0-97.35 26.25-124.4 68.48C65.96 352.5 48 306.3 48 256c0-114.7 93.31-208 208-208s208 93.31 208 208c0 50.3-18 96.5-47.8 132.5z" />
      </svg>
    ),
  },
  {
    id: 7,
    onlyHeader: false,
    title: "Logout",
    access: ["ADMIN", "DOCTOR", "HOSPITAL_ADMIN", "HOSPITAL_USER"],
    path: "/logout",
    icon: (
      <svg
        viewBox="0 0 24 24"
        fill="#e14240"
        className="size-5 lg:size-4 xl:size-5"
      >
        <path fill="none" d="M0 0h24v24H0z" />
        <path d="M12 22C6.477 22 2 17.523 2 12S6.477 2 12 2a9.985 9.985 0 018 4h-2.71a8 8 0 10.001 12h2.71A9.985 9.985 0 0112 22zm7-6v-3h-8v-2h8V8l5 4-5 4z" />
      </svg>
    ),

    activeIcon: (
      <svg
        viewBox="0 0 24 24"
        fill="#e14240"
        className="size-5 lg:size-4 xl:size-5"
        strokeWidth={3}
      >
        <path fill="none" d="M0 0h24v24H0z" />
        <path d="M12 22C6.477 22 2 17.523 2 12S6.477 2 12 2a9.985 9.985 0 018 4h-2.71a8 8 0 10.001 12h2.71A9.985 9.985 0 0112 22zm7-6v-3h-8v-2h8V8l5 4-5 4z" />
      </svg>
    ),
  },
];
