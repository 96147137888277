import * as ProfileActionCreator from "./ProfileActionCreator";

export function getChangePasswordState(state) {
  return state.ChangePasswordReducer;
}

export function defaultChangePaswordState() {
  return {
      isChangePasswordLoading: false,
      changePasswordResponse: null,
      changePasswordError: null,
  };
}
/**
 * Change password Reducer its take state and action
 * @param state
 * @param action
 */
export const ChangePasswordReducer = (state, action) => {
  if (
    !state ||
    action.type === ProfileActionCreator.PROFILE_ACTION.RESET_CHANGE_PASSWORD_STATE
  ) {
    return { ...state, ...defaultChangePaswordState() };
  }

  if (action.type === ProfileActionCreator.PROFILE_ACTION.CHANGE_PASSWORD_REQUEST) {
    return { ...state, isChangePasswordLoading: true };
  }

  if (action.type === ProfileActionCreator.PROFILE_ACTION.CHANGE_PASSWORD_RESPONSE) {
    return { ...state, changePasswordResponse: action.data, isChangePasswordLoading: false };
  }

  if (action.type === ProfileActionCreator.PROFILE_ACTION.CHANGE_PASSWORD_ERROR) {
    return { ...state, changePasswordError: action.data, isChangePasswordLoading: false };
  }

  return state;
};
