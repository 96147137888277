import { ApiUrl } from "../../../Constants/ApiConstants";
import {
  REQUEST_METHOD,
  WebService,
} from "../../WebServiceManager/RequestService";
import ReportsRequestBody from "./ReportsRequestBody";



export default class ReportsService {
  request = new ReportsRequestBody();
  static shared = new ReportsService();

  getReportsRequest = (payload) => {
    return new Promise((resolve, reject) => {
      WebService.clientShared
        .callServiceApi(
          ApiUrl.getReportsDataRequest(
            payload.selectDevice,
            payload.fromdate,
            payload.todate,
            payload.patientRefId,
            payload.page,
            payload.size
          ),
          this.request.getReportsRequest(REQUEST_METHOD.GET)
        )
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  downloadReportRequest = (payload) => {
    return new Promise((resolve, reject) => {
      WebService.clientShared
        .callServiceApi(
          ApiUrl.downloadReportRequest(
            payload.deviceRefId,
            payload.fromdate,
            payload.todate,
            payload.patientRefId
          ),
          this.request.downloadReportRequest(REQUEST_METHOD.GET),
          true
        )
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };
}
