export const REPORTS_ACTION = {
    RESET_REPORTS_STATE: "RESET_REPORTS_STATE",
    REQUEST_REPORTS_STATE: "REQUEST_REPORTS_STATE",
    RESPONCE_REPORTS_STATE: "RESPONCE_REPORTS_STATE",
    ERROR_REPORTS_STATE: "ERROR_REPORTS_STATE",

    // DOWNLOAD REPORTS STATE
    RESET_DOWNLOAD_REPORT_STATE: "RESET_DOWNLOAD_REPORT_STATE",
    REQUEST_DOWNLOAD_REPORT_STATE: "REQUEST_DOWNLOAD_REPORT_STATE",
    RESPONCE_DOWNLOAD_REPORT_STATE: "RESPONCE_DOWNLOAD_REPORT_STATE",
    ERROR_DOWNLOAD_REPORT_STATE: "ERROR_DOWNLOAD_REPORT_STATE",
  };
  
  export const setResetReportsState = () => ({
    type: REPORTS_ACTION.RESET_REPORTS_STATE,
    data: undefined,
  });
  
  export const requestReportsAction = (params) => ({
    type: REPORTS_ACTION.REQUEST_REPORTS_STATE,
    data: params,
  });
  
  export const responseReportsAction = (res) => ({
    type: REPORTS_ACTION.RESPONCE_REPORTS_STATE,
    data: res,
  });
  
  export const errorReportsAction = (err) => ({
    type: REPORTS_ACTION.ERROR_REPORTS_STATE,
    data: err,
  });

  // DOWNLOAD REPORT 
  
  export const setResetDownloadReportState = () => ({
    type: REPORTS_ACTION.RESET_DOWNLOAD_REPORT_STATE,
    data: undefined,
  });
  
  export const requestDownloadReportAction = (params) => ({
    type: REPORTS_ACTION.REQUEST_DOWNLOAD_REPORT_STATE,
    data: params,
  });
  
  export const responseDownloadReportAction = () => ({
    type: REPORTS_ACTION.RESPONCE_DOWNLOAD_REPORT_STATE,
    data: undefined,
  });
  
  export const errorDownloadReportAction = (err) => ({
    type: REPORTS_ACTION.ERROR_DOWNLOAD_REPORT_STATE,
    data: err,
  });

  