import { ApiUrl } from "../../../Constants/ApiConstants";
import {
  REQUEST_METHOD,
  WebService,
} from "../../WebServiceManager/RequestService";
import UserRequestBody from "./UserRequestBody";

export default class UserService {
  request = new UserRequestBody();
  static shared = new UserService();
  getUserTypesRequest = () => {
    return new Promise((resolve, reject) => {
      WebService.clientShared
        .callServiceApi(
          ApiUrl.getUserTypes(),
          this.request.getUserTypesRequest(REQUEST_METHOD.GET)
        )
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  getUserListRequest = (status) => {
    return new Promise((resolve, reject)=>{
      WebService.clientShared
      .callServiceApi(
        ApiUrl.getUsers(status),
        this.request.getUserListRequest(REQUEST_METHOD.GET)
      )
      .then((response)=>{
        resolve(response)
      })
      .catch((error)=>{
        reject(error)
      })
    })
  }

  getUserListPagenationRequest = (payload) => {
    return new Promise((resolve, reject)=>{
      WebService.clientShared
      .callServiceApi(
        ApiUrl.getUsersListPagenation(payload.typeOfUser === "ADMIN" && payload.status === "all" ? "a" : payload.status, payload.page, payload.size, payload.role),
        this.request.getUsersListPagenation(REQUEST_METHOD.GET)
      )
      .then((response)=>{
        resolve(response)
      })
      .catch((error)=>{
        reject(error)
      })
    })
  }

// get user by id status
  getUserListByStatusRequest = (status) => {
    return new Promise((resolve, reject)=>{
      WebService.clientShared
      .callServiceApi(
        ApiUrl.getUsersByStatus(status),
        this.request.getUserListByStatusRequest(REQUEST_METHOD.GET)
      )
      .then((response)=>{
        resolve(response)
      })
      .catch((error)=>{
        reject(error)
      })
    })
  }

  addUsersRequest = (payload) => {
    return new Promise((resolve, reject)=>{
      WebService.clientShared
      .callServiceApi(
        ApiUrl.addUsers(),
        this.request.addUserRequest(payload, REQUEST_METHOD.POST)
      )
      .then((response)=>{
        resolve(response)
      })
      .catch((error)=>{
        reject(error)
      })
    })
  }
  // edit user by id 
  editUserRequest = (data, id) => {
    return new Promise((resolve, reject)=>{
      WebService.clientShared
      .callServiceApi(
        ApiUrl.editUserById(),
        this.request.editUserRequest({...data,id}, REQUEST_METHOD.PUT)
      )
      .then((response)=>{
        resolve(response)
      })
      .catch((error)=>{
        reject(error)
      })
    })
  }

  // get user by id 

  getUserByIdRequest = (payload) => {
    return new Promise((resolve, reject)=>{
      WebService.clientShared
      .callServiceApi(
        ApiUrl.getUserById(payload),
        this.request.getUserByIdRequest(REQUEST_METHOD.GET)
      )
      .then((response)=>{
        resolve(response)
      })
      .catch((error)=>{
        reject(error)
      })
    })
  }

  // get patients list by doctor id

  getDoctorPatientsListRequest = (payload) => {
    return new Promise((resolve, reject)=>{
      WebService.clientShared
      .callServiceApi(
        ApiUrl.getDoctorsPatientsList(payload),
        this.request.getDoctorsPatientsListRequest(REQUEST_METHOD.GET)
      )
      .then((response)=>{
        resolve(response)
      })
      .catch((error)=>{
        reject(error)
      })
    })
  }

}
