import React from "react";
import UniRpmLogo from "../../Assets/Images/logo.png";
import PropTypes from "prop-types";

const Logo = ({ textClassName }) => {
  return (
    <div className="flex flex-row justify-center items-center gap-2 p-2">
      <img className="h-16  rounded-lg" src={UniRpmLogo} alt="RPM" />
    </div>
  );
};

Logo.propTypes = {
  textClassName: PropTypes.string,
};

export default Logo;
