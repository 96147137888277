import React, { useEffect, useState } from "react";
import withHoc from "../../../NewUiComponents/Layout";
import CustomButton from "../../../NewUiComponents/CustomButton";
import { useDispatch, useSelector } from "react-redux";
import {
  getDeviceByIdRequestAction,
  requestAddDeviceAction,
  requestDeviceListAction,
  requestDeviceListPaginationAction,
  requestDeviceTypesListAction,
  resetDeviceStateAction,
  resetUpdateDeviceByIdStateAction,
  setResetDeviceListPaginationState,
  setResetDeviceListsState,
  updateDeviceByIdRequestAction,
} from "../../../Redux/DeviceState/DeviceActionCreator";
import ModalComponent from "../../../NewUiComponents/ModalComponent";
import AddNewDeviceForm from "./AddNewDeviceForm";
import CusotmDataTable from "../../../NewUiComponents/Table";
import { requestVendorListAction } from "../../../Redux/VendorState/VendorActionCreator";
import { toast } from "react-toastify";
import { appData, appMessages } from "../../../Constants";
import { filterArrayWithKey } from "../../../Utilites/Helpers";
import LOCAL_KEYS, {
  parseLocalStorageJSON,
} from "../../../Utilites/LocalStorage";
import ActionIcons from "../../../NewUiComponents/Table/ActionIcons";
import Loader from '../../../NewUiComponents/Loader';

const Devices = () => {
  const DeviceListState = useSelector((state) => state.DeviceListReducer);
  const DeviceListPagenationState = useSelector((state) => state.DeviceListPagenationReducer);
  const GetdeviceByIdState = useSelector((state) => state.GetdeviceByIdReducer);
  const AddDeviceState = useSelector((state) => state.DeviceDetailsReducer);
  const UpdateDeviceReducer = useSelector((state) => state.UpdateDeviceReducer);
  const [activeFlag, setActiveFlag] = useState(appData.listFlag[1]);
  const typeOfUser = parseLocalStorageJSON(LOCAL_KEYS.USER_DETAILS).role;

  const [formView, setFormView] = useState({
    isOpen: false,
    title: "view",
    label: "view",
  });

  const dispatch = useDispatch();

  const updateDeviceStatus = (row) => {
    dispatch(
      updateDeviceByIdRequestAction({
        active: row.active === "Y" ? "N" : "Y",
        id: row.id,
      })
    );
  };

  const initialValues = {
    // deviceName: "",
    deviceType: "",
    active: "",
    available: "Y",
    vendorId: "",
    description: "",
    device_ref_key: "",
  };

  const clickHandler = (row, view) => {
    dispatch(getDeviceByIdRequestAction(row.id));
    if (view === "edit") {
      setFormView({
        isOpen: true,
        title: "editDevice",
        label: "Edit Device",
        id: row.id,
      });
    } else {
      setFormView({
        isOpen: true,
        title: "view",
        label: "View Device",
        id: row.id,
      });
    }
  };

  const columns = [
    {
      name: "S no",
      selector: (row) => row.id,
      sortable: true,
    },
    {
      name: "Device Reference Id",
      selector: (row) => row.device_ref_key,
      sortable: false,
    },
    {
      name: "Device Type",
      selector: (row) => row.deviceType,
      sortable: false,
    },
    {
      name: "Description",
      selector: (row) => row.description,
      sortable: false,
    },
    {
      name: "Action",
      cell: (row) => (
        <ActionIcons
          typeOfUser={typeOfUser}
          onEditClicked={() => {
            clickHandler(row, "edit");
          }}
          onViewClicked={() => {
            clickHandler(row, "view");
          }}
        />
      ),
    },
  ];

  const addDevice = () => {
    setFormView({ isOpen: true, title: "addDevice", label: "Add New Device" });
  };

  const closeModal = () => {
    setFormView({ isOpen: false, title: "view", label: "view" });
  };

  const addOrUpdateDetails = (formData, view, id) => {
    if (view === "addDevice") {
      dispatch(requestAddDeviceAction(formData));
    } else {
      dispatch(updateDeviceByIdRequestAction({ ...formData, id: id }));
    }
  };

  const customPageChangeFunction = (pageNo) => {
    dispatch(
      requestDeviceListPaginationAction({
        page: pageNo - 1,
        size: DeviceListPagenationState?.size,
        status: activeFlag.value,
      
      })
    );
  };

  const customPageSizeChangeFunction = (size) => {
    dispatch(
      requestDeviceListPaginationAction({
        page: 0,
        size: size,
        status: activeFlag.value,
      })
    );
  };

  useEffect(() => {
    dispatch(setResetDeviceListsState());
    dispatch(setResetDeviceListPaginationState())
    dispatch(requestDeviceTypesListAction());
    // dispatch(requestDeviceListAction(activeFlag.value));
    dispatch(requestDeviceListPaginationAction({status: activeFlag.value, page: DeviceListPagenationState?.page, size: DeviceListPagenationState?.size}));
    dispatch(requestVendorListAction());
  }, []);

  useEffect(() => {
    if (!DeviceListPagenationState?.isDeviceListLoading && DeviceListPagenationState?.deviceError) {
      toast.dismiss();
      toast.error(appMessages.DEVICE_LIST_ERROR);
    }
  }, [DeviceListPagenationState]);

  useEffect(() => {
    if (activeFlag) {
      toast.dismiss();
      dispatch(setResetDeviceListPaginationState())
      // dispatch(requestDeviceListAction(activeFlag.value));
      dispatch(requestDeviceListPaginationAction({status: activeFlag.value, page: DeviceListPagenationState?.page, size: DeviceListPagenationState?.size}));
    }
  }, [activeFlag]);

  useEffect(() => {
    if (AddDeviceState && AddDeviceState?.deviceDetails) {
      toast.success(appMessages.ADD_DEVICE_SUCCESS);
      setFormView({ isOpen: false, title: "view", label: "view" });
      dispatch(setResetDeviceListPaginationState())
      dispatch(resetDeviceStateAction());
      dispatch(requestDeviceListPaginationAction({status: activeFlag.value, page: DeviceListPagenationState?.page, size: DeviceListPagenationState?.size}));
      // dispatch(requestDeviceListAction(activeFlag.value));
    }
  }, [AddDeviceState]);

  useEffect(() => {
    if (AddDeviceState && AddDeviceState.deviceDetailsError) {
      toast.error(appMessages.ADD_DEVICE_ERROR);
    }
  }, [AddDeviceState]);

  useEffect(() => {
    if (UpdateDeviceReducer && UpdateDeviceReducer?.deviceDetails) {
      toast.success(appMessages.UPDATE_DEVICE_SUCCESSFULLY);
      dispatch(setResetDeviceListPaginationState())
      setFormView({ isOpen: false, title: "view", label: "view" });
      // dispatch(requestDeviceListAction(activeFlag.value));
      dispatch(requestDeviceListPaginationAction({status: activeFlag.value, page: DeviceListPagenationState?.page, size: DeviceListPagenationState?.size}));
      dispatch(resetUpdateDeviceByIdStateAction());
    }
  }, [UpdateDeviceReducer]);

  useEffect(() => {
    if (UpdateDeviceReducer && UpdateDeviceReducer?.isError) {
      toast.error(appMessages.UPDATE_DEVICE_ERROR);
      setFormView({ isOpen: false, title: "view", label: "view" });
      dispatch(resetUpdateDeviceByIdStateAction());
    }
  }, [UpdateDeviceReducer]);

  return (
    <div className="container-fluid px-4  overflow-y-scroll h-4/5">
       <Loader isLoading={  
        GetdeviceByIdState?.isDeviceDetailsLoading || 
        AddDeviceState?.isDeviceDetailsLoading ||
        DeviceListPagenationState?.isDeviceListLoading ||
        DeviceListState?.isDeviceListLoading}/>
      {!GetdeviceByIdState?.isDeviceDetailsLoading && (
        <ModalComponent
          onClose={closeModal}
          title={formView.label}
          show={formView.isOpen}
        >
          <AddNewDeviceForm
            initialValues={
              GetdeviceByIdState?.deviceDetails &&
              (formView.title === "editDevice" || formView.title === "view")
                ? GetdeviceByIdState?.deviceDetails
                : initialValues
            }
            buttonName={
              formView.title === "editDevice"
                ? "Update"
                : formView.title === "addDevice"
                ? "submit"
                : ""
            }
            formSubmit={addOrUpdateDetails}
            id={formView.title === "editDevice" && formView?.id}
            view={formView.title}
          />
        </ModalComponent>
      )}
      <div className="flex flex-row justify-end align-center m-4">
        {(typeOfUser === "ADMIN" || typeOfUser === "HOSPITAL_ADMIN") && (
          <CustomButton
            name=""
            icon={() => (
              <svg fill="none" viewBox="0 0 24 24" className="size-6">
                <path
                  fill="#fff"
                  d="M12 4a1 1 0 00-1 1v6H5a1 1 0 100 2h6v6a1 1 0 102 0v-6h6a1 1 0 100-2h-6V5a1 1 0 00-1-1z"
                />
              </svg>
            )}
            onClick={addDevice}
            fullwidth={false}
            isDisabled={false}
            rounded={true}
          />
        )}
      </div>
      <div className="bg-white rounded-2xl shadow-2xl p-3 my-5">
        {!DeviceListState?.isDeviceListLoading && (
          <CusotmDataTable
            filteredList
            filterListData={appData?.listFlag}
            enableSearch={true}
            filterKey="device_ref_key"
            pagination
            data={
              DeviceListPagenationState?.devicesList?.content || []
              // filterArrayWithKey(
              //   // DeviceListState?.devicesList,
              //   DeviceListPagenationState?.devicesList?.content,
              //   activeFlag.value
              // ) || []
            }
            columns={columns}
            activeFlag={activeFlag}
            setActiveFlag={setActiveFlag}
            serverPageNation
            customPageChange={customPageChangeFunction}
            customPageSizeChangeFunction={customPageSizeChangeFunction}
            paginationTotalRows={
              DeviceListPagenationState?.devicesList?.totalElements || 0}
          />
        )}
      </div>
    </div>
  );
};

export default withHoc(Devices);
