import { call, put, takeLatest } from "@redux-saga/core/effects";
import {
  DEVICE_ACTION,
  errorDeviceListActions,
  errorDeviceTypesListAction,
  responceDeviceListActions,
  responseDeviceTypesListAction,
  responseAddDeviceAction,
  errorAddDeviceAction,
  responceGetDeviceByIdAction,
  errorGetDeviceByIdAction,
  errorUpdateDeviceByIdAction,
  responceUpdateDeviceByIdAction,
  responceDeviceDetailsPaginationAction,
  errorDeviceDetailsPaginationAction,
  responceDeviceListPaginationAction,
  errorDeviceListPaginationAction
} from "./DeviceActionCreator";
import DeviceService from "../../Services/RestApiManager/DeviceManager/DeviceService";

export function* getDeviceTypesListRequest() {
  try {
    const response = yield call(DeviceService.shared.getDeviceTypesListRequest);
    yield put(responseDeviceTypesListAction(response));
  } catch (error) {
    yield put(errorDeviceTypesListAction(error));
  }
}

export function* getDeviceListRequest(payload) {
  try {
    const responce = yield call(DeviceService.shared.getDevicesListRequest, payload.data);
    yield put(responceDeviceListActions(responce));
  } catch (error) {
    yield put(errorDeviceListActions(error));
  }
}

export function* addDeviceRequest(payload) {
  try {
    const responce = yield call(DeviceService.shared.addDeviceRequest, payload.data);
    yield put(responseAddDeviceAction(responce));
    yield call(getDeviceListRequest)
  } catch (error) {
    yield put(errorAddDeviceAction(error));
  }
}

export function* getDeviceByIdRequest(payload){
  try {
    const response = yield call(DeviceService.shared.getDeviceByIdRequest, payload.data);
    yield put(responceGetDeviceByIdAction(response));
  } catch (error) {
    yield put(errorGetDeviceByIdAction(error))
  }
}
export function* updateDeviceByIdRequest(payload){
  try {
    const response = yield call(DeviceService.shared.updateDeviceByIdRequest, payload.data);
    yield put(responceUpdateDeviceByIdAction(response));
  } catch (error) {
    yield put(errorUpdateDeviceByIdAction(error))
  }
}

export function* getDeviceDetailsPaginationRequest(payload) {
  try {
    const response = yield call(DeviceService.shared.getDeviceDetailsPaginationRequest, payload.data);
    yield put(responceDeviceDetailsPaginationAction(response));
  } catch (error) {
    yield put(errorDeviceDetailsPaginationAction(error));
  }
}

export function* getDeviceListPagenationRequest(payload) {
  try {
    const response = yield call(DeviceService.shared.getDeviceListPagenationRequest, payload.data);
    yield put(responceDeviceListPaginationAction(response));
  } catch (error) {
    yield put(errorDeviceListPaginationAction(error));
  }
}

export function* getDeviceTypesListWatcherSaga() {
  yield takeLatest(
    DEVICE_ACTION.DEVICE_TYPES_LIST_REQUEST,
    getDeviceTypesListRequest
  );
}


export function* getDeviceListWatcherSaga() {
  yield takeLatest(DEVICE_ACTION.DEVICE_LIST_REQUEST, getDeviceListRequest);
}

export function* addDeviceWatcherSaga() {
  yield takeLatest(DEVICE_ACTION.ADD_DEVICE_REQUEST, addDeviceRequest);
}

export function* getDeviceByIdWatcherSaga() {
  yield takeLatest(DEVICE_ACTION.GET_DEVICE_BY_ID_REQUEST, getDeviceByIdRequest);
}

export function* updateDeviceByIdWatcherSaga() {
  yield takeLatest(DEVICE_ACTION.UPDATE_DEVICE_BY_ID_REQUEST, updateDeviceByIdRequest)
}

export function* deviceDetailsWithPagenationWatcherSaga() {
  yield takeLatest(DEVICE_ACTION.DEVICE_DETAILS_PAGINATION_REQUEST, getDeviceDetailsPaginationRequest);
}

export function* getDeviceListPagenationWatcherSaga() {
  yield takeLatest(DEVICE_ACTION.DEVICE_LIST_PAGINATION_REQUEST, getDeviceListPagenationRequest);
}