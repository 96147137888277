import { ApiUrl } from "../../../Constants/ApiConstants";
import {
  REQUEST_METHOD,
  WebService,
} from "../../WebServiceManager/RequestService";
import DeviceRequestBody from "./DeviceRequestBody";

export default class DeviceService {
  request = new DeviceRequestBody();
  static shared = new DeviceService();

  getDeviceTypesListRequest = () => {
    return new Promise((resolve, reject) => {
      WebService.clientShared
        .callServiceApi(
          ApiUrl.getDeviceTypesList(),
          this.request.getDeviceTypesListRequest(REQUEST_METHOD.GET)
        )
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }; 

  getDevicesListRequest = (status) => {
    return new Promise((resolve, reject) => {
      WebService.clientShared
      .callServiceApi(
        ApiUrl.getDeviceList(status),
        this.request.getDeviceListRequest(REQUEST_METHOD.GET)
      )
      .then((responce)=>{
        resolve(responce)
      })
      .catch((error)=>{
        reject(error);
      })
    })
  }

  getDeviceListPagenationRequest = (payload) => {
    return new Promise((resolve, reject)=>{
      WebService.clientShared
      .callServiceApi(
        ApiUrl.getDeviceListPagenation(payload.status, payload.page, payload.size),
        this.request.getDeviceListPagenationRequest(REQUEST_METHOD.GET)
      )
      .then((response)=>{
        resolve(response)
      })
      .catch((error)=>{
        reject(error)
      })
    })
  }

  addDeviceRequest = (payload) => {
    return new Promise((resolve, reject) => {
      WebService.clientShared
      .callServiceApi(
        ApiUrl.addDevice(),
        this.request.addDeviceRequest(payload, REQUEST_METHOD.POST)
      )
      .then((responce)=>{
        resolve(responce)
      })
      .catch((error)=>{
        reject(error);
      })
    })
  }
  
  getDeviceByIdRequest = (payload) => {
    return new Promise((resolve, reject)=>{
      WebService.clientShared
      .callServiceApi(
        ApiUrl.getDeviceById(payload),
        this.request.getDeviceByIdRequest(REQUEST_METHOD.GET)
      )
      .then((response)=>{
        resolve(response)
      })
      .catch((error)=>{
        reject(error)
      })
    })
  }

  updateDeviceByIdRequest = (payload) => {
    return new Promise((resolve, reject)=>{
      WebService.clientShared
      .callServiceApi(
        ApiUrl.updateDeviceById(),
        this.request.updateDeviceByIdRequest(payload, REQUEST_METHOD.PUT)
      )
      .then((response)=>{
        resolve(response)
      })
      .catch((error)=>{
        reject(error)
      })
    })
  }

  getDeviceDetailsPaginationRequest = (payload) => {
    return new Promise((resolve, reject)=>{
      WebService.clientShared
      .callServiceApi(
        ApiUrl.getDeviceDetailsPaginationRequest(payload.page, payload.size),
        this.request.getDeviceDetailsPaginationRequest(REQUEST_METHOD.GET)
      )
      .then((response)=>{
        resolve(response)
      })
      .catch((error)=>{
        reject(error)
      })
    })
  }
  
}
