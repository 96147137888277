export const DEVICE_ACTION = {
    // device List types actions
    RESET_DEVICE_TYPES_LIST_STATE: "RESET_DEVICE_TYPES_LIST_STATE",
    DEVICE_TYPES_LIST_RESPONSE: "DEVICE_TYPES_LIST_RESPONSE",
    DEVICE_TYPES_LIST_REQUEST: "DEVICE_TYPES_LIST_REQUEST",
    DEVICE_TYPES_LIST_ERROR: "DEVICE_TYPES_LIST_ERROR",
   
    // device list actions
    RESET_DEVICE_LIST_STATE: "RESET_DEVICE_LIST_STATE",
    DEVICE_LIST_REQUEST: "DEVICE_LIST_REQUEST",
    DEVICE_LIST_RESPONCE: "DEVICE_LIST_RESPONCE",
    DEVICE_LIST_ERROR: "DEVICE_LIST_ERROR",

    // device list pagenation actions
    RESET_DEVICE_LIST_PAGINATION_STATE: "RESET_DEVICE_LIST_PAGINATION_STATE",
    DEVICE_LIST_PAGINATION_REQUEST: "DEVICE_LIST_PAGINATION_REQUEST",
    DEVICE_LIST_PAGINATION_RESPONCE: "DEVICE_LIST_PAGINATION_RESPONCE",
    DEVICE_LIST_PAGINATION_ERROR: "DEVICE_LIST_PAGINATION_ERROR",

    // add new device actions
    RESET_DEVICE_DETAILS_STATE: "RESET_DEVICE_DETAILS_STATE",
    ADD_DEVICE_REQUEST: "ADD_DEVICE_REQUEST",
    ADD_DEVICE_RESPONCE: "ADD_DEVICE_RESPONCE",
    ADD_DEVICE_ERROR: "ADD_DEVICE_ERROR",

    // get device by Id 
    RESET_GET_DEVICE_BY_ID: "RESET_GET_DEVICE_BY_ID",
    GET_DEVICE_BY_ID_REQUEST: "GET_DEVICE_BY_ID_REQUEST",
    GET_DEVICE_BY_ID_RESPONCE: "GET_DEVICE_BY_ID_RESPONCE",
    GET_DEVICE_BY_ID_ERROR: "GET_DEVICE_BY_ID_ERROR",

    // update device by id
    RESET_UPDATE_DEVICE_BY_ID: "RESET_UPDATE_DEVICE_BY_ID",
    UPDATE_DEVICE_BY_ID_REQUEST: "UPDATE_DEVICE_BY_ID_REQUEST",
    UPDATE_DEVICE_BY_ID_RESPONCE: "UPDATE_DEVICE_BY_ID_RESPONCE",
    UPDATE_DEVICE_BY_ID_ERROR: "UPDATE_DEVICE_BY_ID_ERROR",

    // Device Details with pagenation
    RESET_DEVICE_DETAILS_PAGINATION: "RESET_DEVICE_DETAILS_PAGINATION",
    DEVICE_DETAILS_PAGINATION_REQUEST: "DEVICE_DETAILS_PAGINATION_REQUEST",
    DEVICE_DETAILS_PAGINATION_RESPONCE: "DEVICE_DETAILS_PAGINATION_RESPONCE",
    DEVICE_DETAILS_PAGINATION_ERROR: "DEVICE_DETAILS_PAGINATION_ERROR",

  };

  // DEVICE TYPES 
  export const setResetDeviceTypeState = () => ({
    type: DEVICE_ACTION.RESET_DEVICE_TYPES_LIST_STATE,
    data: undefined,
  });
  
  export const requestDeviceTypesListAction = () => ({
    type: DEVICE_ACTION.DEVICE_TYPES_LIST_REQUEST,
    data: undefined
  });
  
  export const responseDeviceTypesListAction = (res) => ({
    type: DEVICE_ACTION.DEVICE_TYPES_LIST_RESPONSE,
    data: res,
  });
  
  export const errorDeviceTypesListAction = (err) => ({
    type: DEVICE_ACTION.DEVICE_TYPES_LIST_ERROR,
    data: err,
  });

  // DEVICE LISTS TYPES

  export const setResetDeviceListsState = () => ({
    type: DEVICE_ACTION.RESET_DEVICE_LIST_STATE,
    data: undefined,
  });

  export const requestDeviceListAction = (payload) => ({
    type: DEVICE_ACTION.DEVICE_LIST_REQUEST,
    data: payload
  });

  export const responceDeviceListActions = (res) => ({
    type: DEVICE_ACTION.DEVICE_LIST_RESPONCE,
    data: res
  });

  export const errorDeviceListActions = (err) => ({
    type: DEVICE_ACTION.DEVICE_LIST_ERROR,
    data: err
  });

  // DEVICE LIST PAGINATION

  export const setResetDeviceListPaginationState = () => ({
    type: DEVICE_ACTION.RESET_DEVICE_LIST_PAGINATION_STATE,
    data: undefined,
  });

  export const requestDeviceListPaginationAction = (payload) => ({
    type: DEVICE_ACTION.DEVICE_LIST_PAGINATION_REQUEST,
    data: payload
  });

  export const responceDeviceListPaginationAction = (res) => ({
    type: DEVICE_ACTION.DEVICE_LIST_PAGINATION_RESPONCE,
    data: res
  });

  export const errorDeviceListPaginationAction = (err) => ({
    type: DEVICE_ACTION.DEVICE_LIST_PAGINATION_ERROR,
    data: err
  });

  // ADD NEW DEVICE 

  export const resetDeviceStateAction = () => ({
    type: DEVICE_ACTION.RESET_DEVICE_DETAILS_STATE
  });

  export const requestAddDeviceAction = (payload) => ({
    type: DEVICE_ACTION.ADD_DEVICE_REQUEST,
    data: payload,
  });
  
  export const responseAddDeviceAction = (resp) => ({
    type: DEVICE_ACTION.ADD_DEVICE_RESPONCE,
    data: resp,
  });
  
  export const errorAddDeviceAction = (err) => ({
    type: DEVICE_ACTION.ADD_DEVICE_ERROR,
    data: err,
  });

  // get device by ID 

  export const resetGetDeviceByIdStateAction = () => ({
    type: DEVICE_ACTION.RESET_GET_DEVICE_BY_ID
  });
  
  export const getDeviceByIdRequestAction = (param) => ({
    type: DEVICE_ACTION.GET_DEVICE_BY_ID_REQUEST,
    data: param,
  });
  
  export const responceGetDeviceByIdAction = (resp) => ({
    type: DEVICE_ACTION.GET_DEVICE_BY_ID_RESPONCE,
    data: resp,
  });
  
  export const errorGetDeviceByIdAction = (err) => ({
    type: DEVICE_ACTION.GET_DEVICE_BY_ID_ERROR,
    data: err,
  });

  // update device by ID

  export const resetUpdateDeviceByIdStateAction = () => ({
    type: DEVICE_ACTION.RESET_UPDATE_DEVICE_BY_ID
  });
  
  export const updateDeviceByIdRequestAction = (param) => ({
    type: DEVICE_ACTION.UPDATE_DEVICE_BY_ID_REQUEST,
    data: param,
  });
  
  export const responceUpdateDeviceByIdAction = (resp) => ({
    type: DEVICE_ACTION.UPDATE_DEVICE_BY_ID_RESPONCE,
    data: resp,
  });
  
  export const errorUpdateDeviceByIdAction = (err) => ({
    type: DEVICE_ACTION.UPDATE_DEVICE_BY_ID_ERROR,
    data: err,
  });

  // Device Details with pagenation

  export const resetDeviceDetailsPaginationStateAction = () => ({
    type: DEVICE_ACTION.RESET_DEVICE_DETAILS_PAGINATION
  });
  
  export const getDeviceDetailsPaginationRequestAction = (param) => ({
    type: DEVICE_ACTION.DEVICE_DETAILS_PAGINATION_REQUEST,
    data: param,
  });
  
  export const responceDeviceDetailsPaginationAction = (resp) => ({
    type: DEVICE_ACTION.DEVICE_DETAILS_PAGINATION_RESPONCE,
    data: resp,
  });
  
  export const errorDeviceDetailsPaginationAction = (err) => ({
    type: DEVICE_ACTION.DEVICE_DETAILS_PAGINATION_ERROR,
    data: err,
  });

  
  