import * as TokenActionCreator from "./TokenActionCreator";

export function getTokenState(state) {
  return state.TokenReducer;
}

export function defaultTokenState() {
  return {
    isRefreshTokenLoading: false,
    isValidTokenLoading: false,
    accessTokenResponse: null,
    accessTokenError: null,
    isAccessTokenValidResponse: false
  };
}
/**
 * Token Reducer its take state and action
 * @param state
 * @param action
 */
export const TokenReducer = (state = defaultTokenState(), action) => {
  
  if (action.type === TokenActionCreator.TOKEN_ACTION.REFRESH_TOKEN_REQUEST) {
    return { ...state, isRefreshTokenLoading: true };
  }

  if (action.type === TokenActionCreator.TOKEN_ACTION.IS_VALID_TOKEN_REQUEST) {
    return { ...state, isValidTokenLoading: true };
  }

  if (action.type === TokenActionCreator.TOKEN_ACTION.REFRESH_TOKEN_RESPONSE) {
    return { ...state, accessTokenResponse: action.data, isValidTokenLoading: false };
  }

  if (action.type === TokenActionCreator.TOKEN_ACTION.REFRESH_TOKEN_ERROR) {
    return { ...state, accessTokenError: action.data, isValidTokenLoading: false };
  }

  if (action.type === TokenActionCreator.TOKEN_ACTION.IS_VALID_TOKEN_RESPONSE) {
    return { ...state, isAccessTokenValid: action.data, isValidTokenLoading: false };
  }
  return state;
};

export default TokenReducer;
