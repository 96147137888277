export const LOGIN_ACTION = {
  RESET_LOGIN_STATE: "RESET_LOGIN_STATE",
  REQUEST: "LOGIN_REQUEST",
  ERROR: "LOGIN_ERROR",
  RESPONSE: "LOGIN_RESPONSE",
};

export const FP_ACTION = {
  RESET: "RESET_FP_STATE",
  REQUEST: "FP_REQUEST",
  ERROR: "FP_ERROR",
  RESPONSE: "FP_RESPONSE",
};

export const RESET_PASSWORD_ACTION = {
  RESET: "RESET_PASSWORD_STATE",
  REQUEST: "RESET_PASSWORD_REQUEST",
  ERROR: "RESET_PASSWORD_ERROR",
  RESPONSE: "RESET_PASSWORD_RESPONSE",
};


export const VERIFY_EMAIL_ACTION = {
  RESET: "VERIFY_EMAIL_STATE",
  REQUEST: "VERIFY_EMAIL_REQUEST",
  ERROR: "VERIFY_EMAIL_ERROR",
  RESPONSE: "VERIFY_EMAIL_RESPONSE",
};

export const RESET_STATE_ACTION = {
  RESET_ALL_STATES: "RESET_ALL_STATES",
}

export const setResetStateLogin = () => ({
  type: LOGIN_ACTION.RESET_LOGIN_STATE,
  data: undefined,
});

export const requestLoginAction = (params) => ({
  type: LOGIN_ACTION.REQUEST,
  data: params,
});

export const responseLoginAction = (res) => ({
  type: LOGIN_ACTION.RESPONSE,
  data: res,
});

export const errorLoginAction = (err) => ({
  type: LOGIN_ACTION.ERROR,
  data: err,
});

export const setResetStateFp = () => ({
  type: FP_ACTION.RESET,
  data: undefined,
});

export const requestFpAction = (params) => ({
  type: FP_ACTION.REQUEST,
  data: params,
});

export const responseFpAction = (res) => ({
  type: FP_ACTION.RESPONSE,
  data: res,
});

export const errorFpAction = (err) => ({
  type: FP_ACTION.ERROR,
  data: err,
});


export const setResetStateResetPassword = () => ({
  type: RESET_PASSWORD_ACTION.RESET,
  data: undefined,
});

export const requestResetPasswordAction = (params) => ({
  type: RESET_PASSWORD_ACTION.REQUEST,
  data: params,
});

export const responseResetPasswordAction = (res) => ({
  type: RESET_PASSWORD_ACTION.RESPONSE,
  data: res,
});

export const errorResetPasswordAction = (err) => ({
  type: RESET_PASSWORD_ACTION.ERROR,
  data: err,
});



export const setResetStateVerifyEmail = () => ({
  type: VERIFY_EMAIL_ACTION.RESET,
  data: undefined,
});

export const requestVerifyEmailAction = (params) => ({
  type: VERIFY_EMAIL_ACTION.REQUEST,
  data: params,
});

export const responseVerifyEmailAction = (res) => ({
  type: VERIFY_EMAIL_ACTION.RESPONSE,
  data: res,
});

export const errorVerifyEmailAction = (err) => ({
  type: VERIFY_EMAIL_ACTION.ERROR,
  data: err,
});

export const resetStateAction = () => ({
  type: RESET_STATE_ACTION.RESET_ALL_STATES,
  data: undefined,
})