import { ApiUrl } from "../../../Constants/ApiConstants";
import {
  REQUEST_METHOD,
  WebService,
} from "../../WebServiceManager/RequestService";
import MonitoringRequestBody from "./MonitoringRequestBody";


export default class MonitoringService {
  request = new MonitoringRequestBody();
  static shared = new MonitoringService();

  getMonitoringDeviceDataByIdRequest = (deviceId, patientId) => {
    return new Promise((resolve, reject) => {
      WebService.clientShared
        .callServiceApi(
          ApiUrl.getMonitoringDeviceDataByIdRequest(deviceId, patientId),
          this.request.getMonitoringDeviceDataByIdRequest(REQUEST_METHOD.GET)
        )
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }; 

  refreshMonitoringDeviceDataRequest = (deviceId, patientId) => {
    return new Promise((resolve, reject) => {
      WebService.clientShared
        .callServiceApi(
          ApiUrl.refreshMonitoringDeviceDataByIdRequest(deviceId, patientId),
          this.request.getMonitoringDeviceDataByIdRequest(REQUEST_METHOD.GET)
        )
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }; 
}
