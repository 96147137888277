import * as Yup from "yup";

const addressSchema = Yup.object().shape({
  addressLine1: Yup.string().required("Address Line 1 is required"),
  addressLine2: Yup.string(),
  city: Yup.string().required("City is required"),
  state: Yup.string().required("State is required"),
  zip: Yup.string()
    .matches(/^\d{5}$/, "Zip code must be 5 digits")
    .required("Zip code is required"),
  country: Yup.string().required("Country is required"),
});

export const SignInSchema = Yup.object().shape({
  username: Yup.string().required("Username is required"),
  password: Yup.string()
    .min(5, "Password too short")
    .max(100, "Password too long")
    .required("Password is required"),
});

export const ChangePasswordSchema = Yup.object().shape({
  currentPassword: Yup.string()
    .min(5, "Current Password too short")
    .max(100, "Current Password too long")
    .required("Current Password is required"),
  newPassword: Yup.string()
    .min(5, "New Password too short")
    .max(100, "New Password too long")
    .required("New Password is required"),
  confirmationPassword: Yup.string()
    .min(5, "New Password too short")
    .max(100, "New Password too long")
    .required("Re-enter your new password"),
});

export const UserDetailsSchems = Yup.object().shape({
  firstName: Yup.string().required("First name is required"),
  userName: Yup.string().required("User name is required"),
  email: Yup.string()
    .email("Invalid email address")
    .required("Email address is required"),
  // password: Yup.string()
  //   .min(5, "Password too short")
  //   .max(100, "Password too long")
  //   .required("Password is required"),
  role: Yup.string().required("User type is required"),
  active: Yup.string().required("Active flag is required"),
  phone: Yup.string().required("Phone number is required"),
  gender: Yup.string().required("Select the Gender"),
  address: addressSchema,
});

export const PatientSchema = Yup.object().shape({
  patient_name: Yup.string().required("Patient name is required"),
  fname: Yup.string().required("First name is required"),
  lname: Yup.string().required("Last name is required"),
  email: Yup.string()
    .email("Invalid email address")
    .required("Email address is required"),
  active: Yup.string().required("Active flag is required"),
  phone: Yup.string().required("Phone number is required"),
  gender: Yup.string().required("Select the Gender"),
  // address: Yup.object().shape({
  //   addressline1: Yup.string().required("Address Line 1 is required"),
  //   addressline2: Yup.string(),
  //   city: Yup.string().required("City is required"),
  //   state: Yup.string().required("State is required"),
  //   zip: Yup.string().required("Zip Code is required"),
  //   country: Yup.string().required("Country is required"),
  // }),
});
export const EditUserSchema = (isEdit) => {
  const schema = Yup.object().shape({
    // password: isEdit
    //   ? Yup.string().min(5, "Password too short").max(100, "Password too long")
    //   : Yup.string(),
    address: isEdit
      ? addressSchema
      : Yup.object().shape({
          addressLine1: Yup.string(),
          addressLine2: Yup.string(),
          city: Yup.string(),
          state: Yup.string(),
          zip: Yup.string(),
          country: Yup.string(),
        }),
  });
  return schema;
};

export const DeviceSchema = Yup.object().shape({
  // deviceName: Yup.string().required("Device Name is required"),
  device_ref_key: Yup.string().required("Device ref key is required"),
  deviceType: Yup.string().required("Device type is required"),
  active: Yup.string().required("Active flag is required"),
  vendorId: Yup.string().required("VendorId is required"),
  description: Yup.string().required("description is required"),
});

export const DeviceTypeSchema = Yup.object().shape({
  deviceTypeName: Yup.string().required("Device Type name is required"),
  monitoringParameters: Yup.array().of(
    Yup.object().shape({
      parameter: Yup.string().required("Parameter is required"),
      normalMinRange: Yup.number().required("min range required"),
      normalMaxRange: Yup.number()
        .required("max range required")
        .test("min-max-validation", "Min Range >= Max Range", function (value) {
          const { normalMinRange } = this.parent;
          return normalMinRange <= value;
        }),
    })
  ),
});

export const ReportSchema = Yup.object().shape({
  selectPatient: Yup.string().required("Patient is required"),
  selectDevice: Yup.string().required("Device is required"),
  fromdate: Yup.string().required("From Date is required"),
  todate: Yup.string().required("To Date is required"),
});

export const ForgotPasswordSchema = Yup.object().shape({
  email: Yup.string()
    .email("Invalid email address")
    .required("Email address is required"),
});

export const ResetPasswordSchema = Yup.object().shape({
  password: Yup.string()
    .min(5, "New Password too short")
    .max(100, "New Password too long")
    .required("New Password is required"),
  confirm_password: Yup.string()
    .min(5, "New Password too short")
    .max(100, "New Password too long")
    .required("Re-enter your new password")
    .oneOf([Yup.ref('password'), null],'Passwords must match'),
})

