import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Proptypes from "prop-types";
import { Formik } from "formik";

import {
  requestDeviceListAction,
  setResetDeviceListsState,
} from "../../../../../Redux/DeviceState/DeviceActionCreator";
import {
  requestUsersListAction,
  setResetUserListState,
} from "../../../../../Redux/UserState/UserActionCreator";
import CustomSelectPicker from "../../../../../NewUiComponents/CustomSelect";
import CustomButton from "../../../../../NewUiComponents/CustomButton";
import Loader from "../../../../../NewUiComponents/Loader";
import LOCAL_KEYS, { parseLocalStorageJSON } from "../../../../../Utilites/LocalStorage";

const AssociationForm = (props) => {
  const typeOfUser = parseLocalStorageJSON(LOCAL_KEYS.USER_DETAILS).role;
  const { associationFormProp, onAssociationSubmit } = props;
  const [formattedAssociationData, setFormattedAssociationData] = useState([]);
  const dispatch = useDispatch();

  const deviceListState = useSelector((state) => state.DeviceListReducer);
  const userListState = useSelector((state) => state.UsersListReducer);
  const linkDoctorPatientListReducerState = useSelector(
    (state) => state.LinkDoctorPatientListStateReducer
  );
  const linkDevicePatientListReducerState = useSelector(
    (state) => state.LinkDevicePatientListStateReducer
  );

  useEffect(() => {
    dispatch(setResetDeviceListsState());
    dispatch(setResetUserListState());
    if (associationFormProp.type === "device") {
      dispatch(requestDeviceListAction('active'));
    } else if (associationFormProp.type === "doctor") {
      dispatch(requestUsersListAction('active', typeOfUser));
    }
  }, [associationFormProp]);

  useEffect(() => {
    if (deviceListState?.devicesList) {
      let alreadyAssignedDevices = linkDevicePatientListReducerState?.linkDevicePatientListResponse?.map((item) => item.id);
      const tempDeviceListState = JSON.parse(
        JSON.stringify(deviceListState?.devicesList.filter((item)=> item.active === "Y" && item.available === "Y" && !alreadyAssignedDevices?.includes(item.id)))
      );
      console.log(tempDeviceListState, "tempDeviceListState");
      const formattedData = tempDeviceListState.map((item) => ({
        ...item,
        label: item.deviceType + "   (" + item.device_ref_key + ")",
        value: item.id,
      }));
      setFormattedAssociationData(formattedData);
    }
  }, [deviceListState]);

  useEffect(() => {
    if (userListState?.usersListResponce?.usersList) {
      const tempUsersList = JSON.parse(
        JSON.stringify(userListState?.usersListResponce?.usersList)
      );
      let alreadyAssignedDoctors = linkDoctorPatientListReducerState.linkDoctorPatientListResponse?.map((item) => item.id);
      const doctorsList = tempUsersList.filter((item) => {
        return item.role === "DOCTOR" && !alreadyAssignedDoctors?.includes(item.id);
      });
      const formattedData = doctorsList.map((item) => ({
        ...item,
        value: item.id,
        label: item.firstName + " " + item.lastName,
      }));
      setFormattedAssociationData(formattedData);
    }
  }, [userListState]);

  const getInitialValues = () => {
    if (associationFormProp.type === "device") {
      return {
        deviceId: "",
      };
    } else {
      return {
        doctorId: "",
      };
    }
  };

  const renderErrorMessage = (errors, touched) => {
    return associationFormProp.type === "device" ? (
      <span className="text-danger text-sm">
        {errors.deviceId && touched.deviceId && errors.deviceId}
      </span>
    ) : (
      <span className="text-danger text-sm">
        {errors.doctorId && touched.doctorId && errors.doctorId}
      </span>
    );
  };

  return (
    <div>
      <Loader
        isLoading={
          deviceListState?.isDeviceListLoading ||
          userListState?.isUsersListLoading
        }
      />
      {!deviceListState?.isDeviceListLoading &&
        !userListState?.isUsersListLoading && (
          <Formik
            initialValues={getInitialValues()}
            onSubmit={(values, { setSubmitting }) => {
              setSubmitting(false);
              onAssociationSubmit(values);
            }}
          >
            {({ errors, touched, handleSubmit, isSubmitting }) => (
              <form
                method="POST"
                onSubmit={handleSubmit}
                className="w-full max-h-[85vh] overflow-y-scroll space-y-32 my-4"
              >
                <div>
                  <CustomSelectPicker
                    isMulti={false}
                    handleChange
                    label={
                      associationFormProp.type === "device"
                        ? "Devices"
                        : "Doctors"
                    }
                    placeHolder={
                      associationFormProp.type === "device"
                        ? "Select device"
                        : "Select doctors"
                    }
                    data={formattedAssociationData}
                    name={
                      associationFormProp.type === "device"
                        ? "deviceId"
                        : "doctorId"
                    }
                  />
                  {renderErrorMessage(errors, touched)}
                </div>

                <CustomButton
                  isDisabled={isSubmitting}
                  name={"Submit"}
                  type={"submit"}
                />
              </form>
            )}
          </Formik>
        )}
    </div>
  );
};

AssociationForm.propTypes = {
  associationFormProp: Proptypes.object,
  onAssociationSubmit: Proptypes.func,
};

export default AssociationForm;
