import { call, put, takeLatest } from "@redux-saga/core/effects";
import { VENDOR_ACTION, errorVendorListAction, responseVendorListAction } from "./VendorActionCreator";
import VendorService from "../../Services/RestApiManager/VendorManager/VendorService";


export function* getVendorListRequest() {
  try {
    const responce = yield call(VendorService.shared.getVendorListRequest);
    yield put(responseVendorListAction(responce));
  } catch (error) {
    yield put(errorVendorListAction(error));
  }
}


export function* getVendorListWatcherSaga() {
  yield takeLatest(VENDOR_ACTION.VENDOR_LIST_REQUEST, getVendorListRequest);
}
