import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import LOCAL_KEYS, {
  parseLocalStorageJSON,
} from "../../../../Utilites/LocalStorage";
import {
  getDoctorPatientsListRequestAction,
  resetDoctorPatientsListStateAction,
} from "../../../../Redux/UserState/UserActionCreator";
import { filterArrayWithKey } from "../../../../Utilites/Helpers";
import { appData, appMessages } from "../../../../Constants";
import CustomTable from "../../../../NewUiComponents/Table";
import { useNavigate } from "react-router-dom";
import PatientsLayout from "../../Patients/Layout";
import { toast } from "react-toastify";
import PropTypes from "prop-types";
import Cards from "../../../../NewUiComponents/Card";
import { mockData } from "../AdminDashboard/mockData";
import { transformArray } from "../../../../Utilites/Helpers";

const DashboardCards = ({ title, imgUrl, value, path }) => {
  const navigate = useNavigate();
  const goToPath = () => {
    navigate(path);
  };
  return (
    <Cards
      className={
        "w-1/4 shadow-2xl rounded-4xl h-40 p-5 flex flex-row justify-between items-center"
      }
    >
      <div>
        <p className="font-poppins text-sm font-medium my-2">{title}</p>
        <p className="text-xl font-bold my-2">{value}</p>
        <p
          className="font-poppins text-xs text-gray underline underline-offset-4 my-2 cursor-pointer"
          onClick={goToPath}
        >
          View all {title}
        </p>
      </div>
      <div>
        <img src={imgUrl} className="w-24 h-24 object-contain" />
      </div>
    </Cards>
  );
};

const DoctorDashboard = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const DoctorPatientListState = useSelector(
    (state) => state.GetDoctorPatientsListReducer
  );
  const { id: doctorId, role: typeOfUser } = parseLocalStorageJSON(
    LOCAL_KEYS.USER_DETAILS
  );
  const [activeFlag, setActiveFlag] = useState(appData.listFlag[0]);

  const handleRowClickFunction = (row) => {
    goToPatientAssociations(row.patient_id || row.id);
  };

  const goToPatientAssociations = (patientId) => {
    navigate("/patient-associations/" + patientId);
  };

  useEffect(() => {
    dispatch(resetDoctorPatientsListStateAction());
    if (doctorId) {
      dispatch(getDoctorPatientsListRequestAction(doctorId));
    }
  }, []);

  useEffect(() => {
    if (
      DoctorPatientListState &&
      !DoctorPatientListState?.isLoading &&
      DoctorPatientListState?.isError
    ) {
      toast.dismiss();
      toast.error(appMessages.PATIENTS_LIST_ERROR);
    }
  }, [DoctorPatientListState]);

  const columns = [
    {
      name: "Patient Id",
      selector: (row) => (
        <div className="flex flex-row gap-2">
          <span
            className="text-primary underline cursor-pointer"
            onClick={() => goToPatientAssociations(row.patient_id || row.id)}
          >
            #{row.id}
          </span>
        </div>
      ),
    },
    {
      name: "Patient name",
      selector: (row) => row.patient_name,
      sortable: true,
    },
    {
      name: "Email",
      selector: (row) => row.email,
      sortable: true,
    },
    {
      name: "Phone Number",
      selector: (row) => row.phone,
      sortable: true,
    },
    {
      name: "Gender",
      selector: (row) =>
        row.gender === "M" || row.gender === "m" ? "Male" : "Female",
      sortable: true,
    },
  ];
  return typeOfUser === "HOSPITAL_USER" ? (
    <div className="m-5">
      <Cards>
        <p className="font-poppins text-md font-medium my-2 p-3">
          Patients with high alerts
        </p>
        <CustomTable
          columns={mockData.highAlertPatientsColumns}
          enableSearch={false}
          fixedHeaderScrollHeight={"40vh"}
          data={mockData.highAlertPatients}
        />
      </Cards>
      <PatientsLayout />
    </div>
  ) : (
    <div className="my-5">
      <Cards>
        <p className="font-poppins text-md font-medium my-5 p-3">
          Patients with high alerts
        </p>
        <CustomTable
          columns={mockData.highAlertPatientsColumns}
          enableSearch={false}
          fixedHeaderScrollHeight={"40vh"}
          data={mockData.highAlertPatients}
        />
      </Cards>

      <div className="bg-white rounded-2xl shadow-2xl p-3 my-5">
        <p className="font-poppins text-md font-medium">Patient List</p>
        {!DoctorPatientListState?.isLoading && (
          <CustomTable
            columns={columns}
            data={
              filterArrayWithKey(
                DoctorPatientListState?.doctorsPatientsList,
                activeFlag.value
              ) || []
            }
            pagination={true}
            filterKey={"patient_name"}
            filteredList={true}
            activeFlag={activeFlag}
            setActiveFlag={setActiveFlag}
            filterListData={appData.listFlag}
            activeFlagRequired={false}
            entireRowClick
            customRowClickFunction={handleRowClickFunction}
            title={"Patients List"}
          />
        )}
      </div>
    </div>
  );
};

DashboardCards.propTypes = {
  title: PropTypes.string,
  imgUrl: PropTypes.string,
  value: PropTypes.string,
  path: PropTypes.string,
};

export default DoctorDashboard;
