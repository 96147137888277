import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";

import CustomTable from "../../../../NewUiComponents/Table";
import PatientForm from "../Form";
import {
  addPatientRequest,
  addPatientReset,
  patientListRequest,
  patientListReset,
  updatePatientRequest,
  updatePatientReset,
  getPatientByIdReset,
  getPatientByIdRequest,
  patientListPagenationReset,
  patientListPagenationRequest,
} from "../../../../Redux/PatientState/PatientActionCreator";
import { appData, appMessages } from "../../../../Constants";
import Loader from "../../../../NewUiComponents/Loader";
import ModalComponent from "../../../../NewUiComponents/ModalComponent";
import CustomToggle from "../../../../NewUiComponents/Toggle";
import { filterArrayWithKey } from "../../../../Utilites/Helpers";
import LOCAL_KEYS, {
  parseLocalStorageJSON,
} from "../../../../Utilites/LocalStorage";
import CustomButton from "../../../../NewUiComponents/CustomButton";
import { setResetMonitoringDevcieState } from "../../../../Redux/MonitoringState/MonitoringActionCreator";
import { linkDevicePatientListReset } from "../../../../Redux/PatientState/PatientActionCreator";
import ActionIcons from '../../../../NewUiComponents/Table/ActionIcons';


const PatientsLayout = () => {
  let { patientId } = useParams();
  const typeOfUser = parseLocalStorageJSON(LOCAL_KEYS.USER_DETAILS).role;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const addPatientState = useSelector((state) => state.AddPatientStateReducer);
  const patientListState = useSelector((state) => state.PatientsListReducer);
  const patientListPagenationState = useSelector((state) => state.PatientsListPagenationReducer);


  const refreshMonitoringDeviceReducerState = useSelector(
    (state) => state.RefreshMonitoringDeviceReducer
  );
  const updatePatientState = useSelector(
    (state) => state.UpdatePatientStateReducer
  );
  const patientByIdReducerState = useSelector(
    (state) => state.GetPatientByIdReducer
  );

  const [formView, setFormView] = useState({
    isOpen: false,
    title: "view",
    label: "view",
  });
  const [activeFlag, setActiveFlag] = useState(appData.listFlag[1]);
  const [actionType, setActionType] = useState("");
  const [isDrawerOpen, setDrawerOpen] = useState(false);

  const handleDrawerClose = () => {
    setDrawerOpen(false);
  };

  const handleDrawerOpen = () => {
    setDrawerOpen(true);
  };

  const handleRowClickFunction = (row) => {
    goToPatientAssociations(row.patient_id);
  };

  const customPageChangeFunction = (pageNo) => {
    dispatch(
      patientListPagenationRequest({
        page: pageNo - 1,
        size: patientListPagenationState?.size,
        status: activeFlag.value,
      
      })
    );
  };

  const customPageSizeChangeFunction = (size) => {
    dispatch(
      patientListPagenationRequest({
        page: 0,
        size: size,
        status: activeFlag.value,
      })
    );
  };

  useEffect(() => {
    if (patientByIdReducerState?.patientByIdResponse) {
      if (actionType === "view") {
        setFormView({
          isOpen: true,
          title: "patientDetails",
          label: "Patient Details",
          data: patientByIdReducerState?.patientByIdResponse,
        });
      } else if (actionType === "edit") {
        setFormView({
          isOpen: true,
          title: "editPatient",
          label: "Update Patient",
          data: patientByIdReducerState?.patientByIdResponse,
        });
      }
    }
    if (patientByIdReducerState?.patientByIdError) {
      toast(appMessages.ERROR_OCCURED);
    }
  }, [patientByIdReducerState]);

  useEffect(() => {
    dispatch(patientListReset());
    dispatch(updatePatientReset());
    dispatch(addPatientReset());
    dispatch(getPatientByIdReset());
    setActionType("");
    dispatch(setResetMonitoringDevcieState())
    dispatch(patientListPagenationReset())
    // dispatch(patientListRequest({ patientListStatus: activeFlag.value }));
    dispatch(patientListPagenationRequest({status: activeFlag.value, page: patientListPagenationState?.page, size: patientListPagenationState?.size}));
  }, [activeFlag]);

  useEffect(() => {
    dispatch(setResetMonitoringDevcieState());
    dispatch(linkDevicePatientListReset());
  }, [patientId]);

  useEffect(() => {
    if (updatePatientState && updatePatientState.updatePatientResponse) {
      toast.success(appMessages.UPDATE_PATIENT_SUCCESS);
      dispatch(patientListPagenationReset())
      // dispatch(patientListRequest({ patientListStatus: activeFlag.value }));
      dispatch(patientListPagenationRequest({status: activeFlag.value, page: patientListPagenationState?.page, size: patientListPagenationState?.size}));
      dispatch(updatePatientReset());
      setFormView({
        isOpen: false,
        title: "view",
        label: "view",
      });
    }
  }, [updatePatientState]);

  useEffect(() => {
    if (updatePatientState && updatePatientState.updatePatientError) {
      toast.error(appMessages.UPDATE_PATIENT_ERROR);
    }
  }, [updatePatientState]);

  useEffect(() => {
    if (addPatientState && addPatientState.addPatientResponse) {
      toast.success(appMessages.ADD_PATIENT_SUCCESS);
      setFormView({ isOpen: false, title: "view", label: "view", data: null });
      dispatch(patientListPagenationReset())
      // dispatch(patientListRequest({ patientListStatus: activeFlag.value }));
      dispatch(patientListPagenationRequest({status: activeFlag.value, page: patientListPagenationState?.page, size: patientListPagenationState?.size}));
      dispatch(addPatientReset());
    }
  }, [addPatientState]);

  useEffect(() => {
    if (addPatientState && addPatientState.addPatientError) {
      toast.error(appMessages.ADD_PATIENT_ERROR);
      setFormView({ isOpen: false, title: "view", label: "view", data: null });
    }
  }, [addPatientState]);

  useEffect(() => {
    if (
      !patientListState?.patientListLoading &&
      patientListState?.patientListError
    ) {
      toast.dismiss();
      toast.error(appMessages.PATIENT_LIST_ERROR);
    }
  }, [patientListState]);

  useEffect(() => {
    if (
      !patientListPagenationState?.patientListLoading &&
      patientListPagenationState?.patientListError
    ) {
      toast.dismiss();
      toast.error(appMessages.PATIENT_LIST_ERROR);
    }
  }, [patientListPagenationState]);

  // useEffect(() => {
  //   if (
  //     !patientListPagenationState?.patientListLoading &&
  //     patientListPagenationState?.patientList
  //   ) {
  //     dispatch(updatePatientReset());
  //   }
  // }, [patientListPagenationState]);


  const addOrUpdatePatient = (formData) => {
    if (actionType === "edit") {
      const updatedFormData = {
        ...formData,
        id: patientByIdReducerState?.patientByIdResponse.id,
      };
      dispatch(updatePatientReset());
      dispatch(updatePatientRequest(updatedFormData));
    } else {
      dispatch(addPatientReset());
      dispatch(addPatientRequest(formData));
    }
  };

  const initialValues = {
    patient_name: "",
    fname: "",
    lname: "",
    email: "",
    phone: "",
    active: "N",
    address: {
      addressLine1: "",
      addressLine2: "",
      city: "",
      state: "",
      zip: "",
      country: "",
    },
    gender: "",
  };

  const closeModal = () => {
    setFormView({ isOpen: false, data: null });
  };

  const addPatient = () => {
    setFormView({
      isOpen: true,
      title: "AddPatient",
      label: "Add New Patient",
      data: null,
    });
  };

  const clickHandler = (row, action) => {
    dispatch(getPatientByIdReset());
    dispatch(getPatientByIdRequest({ patientId: row.patient_id }));
    setActionType(action);
  };

  const updatePatientStatus = (patientItem) => {
    dispatch(updatePatientReset());
    const payload = {
      id: patientItem.patient_id,
      active: patientItem.active === "Y" ? "N" : "Y",
    };
    dispatch(updatePatientRequest(payload));
  };

  const goToPatientAssociations = (patientId) => {
    navigate("/patient-associations/" + patientId);
  };

  const columns = [
    {
      name: "Patient Id",
      selector: (row) => (
        <div className="flex flex-row gap-2">
          <span
            className="text-primary underline cursor-pointer"
            onClick={() => goToPatientAssociations(row.patient_id)}
          >
            #{row.patient_id}
          </span>
        </div>
      ),
    },
    {
      name: "Patient name",
      selector: (row) => row.patient_name,
      sortable: true,
    },
    {
      name: "Email",
      selector: (row) => row.email,
      sortable: true,
    },
    {
      name: "Phone Number",
      selector: (row) => row.phone,
      sortable: true,
    },
    {
      name: "Gender",
      selector: (row) =>
        row.gender === "M" || row.gender === "m" ? "Male" : "Female",
      sortable: true,
    },
    ...(typeOfUser === "ADMIN" || typeOfUser === "HOSPITAL_ADMIN"
      ? [
          {
            name: "Status",
            selector: (row) => (
              <CustomToggle
                isChecked={row.active === "Y" ? true : false}
                handleToggleChange={() => updatePatientStatus(row)}
              />
            ),
          },
        ]
      : []),
    ...(typeOfUser === "ADMIN" ||
    typeOfUser === "HOSPITAL_ADMIN" ||
    typeOfUser === "HOSPITAL_USER"
      ? [
          {
            name: "Action",
            cell: (row) => (
              <ActionIcons
                typeOfUser={typeOfUser}
                onEditClicked={() => {
                  clickHandler(row, "edit");
                }}
                onViewClicked={() => {
                  clickHandler(row, "view");
                }}
              />
            ),
          },
        ]
      : []),
  ];

  useEffect(() => {
    return () => {
      dispatch(updatePatientReset());
    };
  }, []);

  return (
    <div className="container-fluid px-4 overflow-y-scroll h-4/5">
      <Loader
        isLoading={
          addPatientState?.addPatientLoading ||
          patientListState?.patientListLoading ||
          updatePatientState?.updatePatientLoading ||
          patientByIdReducerState?.patientByIdLoading ||
          refreshMonitoringDeviceReducerState?.isRefreshMonitoringDataLoading ||
          patientListPagenationState?.patientListLoading
        }
      />
      <ModalComponent
        show={formView.isOpen}
        onClose={closeModal}
        title={formView.label}
      >
        <PatientForm
          buttonName={formView.title === "AddPatient" ? "Submit" : "Update"}
          formSubmit={addOrUpdatePatient}
          initialValues={
            formView.title === "patientDetails" ||
            formView.title === "editPatient"
              ? formView.data
              : initialValues
          }
          viewPatient={formView.title === "patientDetails" ? true : false}
          editPatient={formView.title === "editPatient" ? true : false}
          addPatient={formView.title === "AddPatient" ? true : false}
        />
      </ModalComponent>
      <div className="flex flex-row justify-end align-center m-4">
        {(typeOfUser === "ADMIN" || typeOfUser === "HOSPITAL_ADMIN") && (
          <CustomButton
            name=""
            icon={() => (
              <svg fill="none" viewBox="0 0 24 24" className="size-6">
                <path
                  fill="#fff"
                  d="M12 4a1 1 0 00-1 1v6H5a1 1 0 100 2h6v6a1 1 0 102 0v-6h6a1 1 0 100-2h-6V5a1 1 0 00-1-1z"
                />
              </svg>
            )}
            onClick={addPatient}
            fullwidth={false}
            isDisabled={false}
            rounded={true}
          />
        )}
       
      </div>
      <div className="bg-white rounded-2xl shadow-2xl p-3 my-5">
        {!patientListState?.patientListLoading && (
        <CustomTable
          columns={columns}
          data={ patientListPagenationState.patientList?.content || [] }
          pagination={true}
          filterKey={"patient_name"}
          filteredList={true}
          activeFlag={activeFlag}
          setActiveFlag={setActiveFlag}
          filterListData={appData.listFlag}
          activeFlagRequired={typeOfUser === "DOCTOR" ? false : true}
          entireRowClick
          customRowClickFunction={handleRowClickFunction}
          serverPageNation
          customPageChange={customPageChangeFunction}
            customPageSizeChangeFunction={customPageSizeChangeFunction}
          paginationTotalRows={
            patientListPagenationState?.patientList?.totalElements || 0}
        />
        )}
      </div>
    </div>
  );
};

export default PatientsLayout;
