import * as VendorActionCreator from "./VendorActionCreator";

export function vendorListState() {
  return {
    isVendorListLoading: false,
    vendorsList: null,
    vendorError: null,
  };
}

export const VendorListReducer = (state, action) => {
  if (!state || action.type === VendorActionCreator.VENDOR_ACTION.RESET_VENDOR_LIST_STATE) {
    return { ...state, ...vendorListState() };
  }

  if (action.type === VendorActionCreator.VENDOR_ACTION.VENDOR_LIST_REQUEST) {
    return { ...state, isVendorListLoading: true };
  }

  if (action.type === VendorActionCreator.VENDOR_ACTION.VENDOR_LIST_RESPONCE) {
    return { ...state, isVendorListLoading: false, vendorsList: action.data };
  }

  if (action.type === VendorActionCreator.VENDOR_ACTION.VENDOR_LIST_ERROR) {
    return { ...state, vendorError: action.data, isVendorListLoading: false };
  }

  return state;
};


