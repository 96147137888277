import React from "react";
import { TrashIcon } from "@heroicons/react/24/solid";
import Proptypes from "prop-types";
import CustomTable from "../../../../../NewUiComponents/Table";
import Swal from "sweetalert2";
import LOCAL_KEYS, {
  parseLocalStorageJSON,
} from "../../../../../Utilites/LocalStorage";

const DoctorAssociationTable = (props) => {
  const {
    removeDoctorPatientAssociation,
    removeDevicePatientAssociation,
    data,
    type,
  } = props;
  const typeOfUser = parseLocalStorageJSON(LOCAL_KEYS.USER_DETAILS).role;
  const removeDoctorOrDevicePatientAssociation = (row, key) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        key === "doctor"
          ? removeDoctorPatientAssociation(row)
          : removeDevicePatientAssociation(row);
      }
    });
  };

  const doctorColumns = [
    {
      name: "Id",
      selector: (row) => row.id,
    },
    {
      name: "Doctor name",
      selector: (row) => `${row.firstName} ${row.lastName}`,
    },
    {
      name: "Email",
      selector: (row) => `${row.email}`,
    },
    {
      name: "Mobile",
      selector: (row) => `${row.phone}`,
    },
    ...(typeOfUser === "ADMIN" || typeOfUser === "HOSPITAL_ADMIN"
      ? [
          {
            name: "Action",
            cell: (row) => (
              <div className="grid grid-cols-2 cursor-pointer">
                {/* add the logic to disable this icon based on perminsion from that user group */}
                <TrashIcon
                  onClick={() =>
                    removeDoctorOrDevicePatientAssociation(row, "doctor")
                  }
                  className="h-4 w-4 text-danger"
                />
              </div>
            ),
          },
        ]
      : []),
  ];

  const deviceColumns = [
    {
      name: "Id",
      selector: (row) => row.id,
    },
    {
      name: "Device Key",
      selector: (row) => row.device_ref_key,
    },
    {
      name: "Device Type",
      selector: (row) => row.deviceType,
    },
    {
      name: "Description",
      selector: (row) => row.description ?? "NA",
    },
    {
      name: "Status",
      selector: (row) => (row.active === "Y" ? "Active" : "Inactive"),
    },
    ...(typeOfUser === "ADMIN" || typeOfUser === "HOSPITAL_ADMIN"
      ? [
          {
            name: "Action",
            cell: (row) => (
              <div className="grid grid-cols-2 cursor-pointer">
                {/* add the logic to disable this icon based on perminsion from that user group */}
                <TrashIcon
                  onClick={() =>
                    removeDoctorOrDevicePatientAssociation(row, "device")
                  }
                  className="h-4 w-4 text-danger"
                />
              </div>
            ),
          },
        ]
      : []),
  ];

  return (
    <CustomTable
      columns={type === "doctor" ? doctorColumns : deviceColumns}
      data={data}
      pagination={true}
      enableSearch={false}
    />
  );
};

DoctorAssociationTable.propTypes = {
  removeDoctorPatientAssociation: Proptypes.func,
  removeDevicePatientAssociation: Proptypes.func,
  data: Proptypes.array,
  type: Proptypes.string,
};

export default DoctorAssociationTable;
