import React, { useEffect } from "react";
import withHoc from "../../../NewUiComponents/Layout";
import LOCAL_KEYS, {
  parseLocalStorageJSON,
} from "../../../Utilites/LocalStorage";
import AdminDashboard from "./AdminDashboard";
import HospitalDashboard from "./HospitalDashboard";
import DoctorDashboard from "./DoctorDashboard";
import { useDispatch, useSelector } from "react-redux";
import { requestUserTypesAction } from "../../../Redux/UserState/UserActionCreator";
import { requestDeviceTypesListAction } from "../../../Redux/DeviceState/DeviceActionCreator";
import Loader from "../../../NewUiComponents/Loader";

const Dashboard = () => {
  const { role } = parseLocalStorageJSON(LOCAL_KEYS.USER_DETAILS);

  const { userTypes, isUserLoading } = useSelector(
    (state) => state.UserTypesReducer
  );
  const { deviceTypes, isDeviceTypesLoading } = useSelector(
    (state) => state.DeviceTypesListReducer
  );
  const dispatch = useDispatch();

  useEffect(() => {
    if (userTypes === null && !isUserLoading) {
      dispatch(requestUserTypesAction());
    }
    if (role === "HOSPITAL_ADMIN" || role === "ADMIN") {
      if (deviceTypes === null && !isDeviceTypesLoading) {
        dispatch(requestDeviceTypesListAction());
      }
    }
  }, []);

  return (
    <div className="container-fluid p-4 overflow-y-scroll h-5/6">
      <Loader isLoading={isUserLoading || isDeviceTypesLoading} />
      {!isUserLoading && (
        <span>
          {role === "ADMIN" && <AdminDashboard />}
          {role === "HOSPITAL_ADMIN" && <HospitalDashboard />}
          {(role === "DOCTOR" || role === "HOSPITAL_USER") && (
            <DoctorDashboard />
          )}
        </span>
      )}
    </div>
  );
};

export default withHoc(Dashboard);
