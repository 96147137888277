import React from "react";
import Select from "react-select";
import PropTypes, { array } from "prop-types";
import { Field } from "formik";


const CustomSelect = ({
    className,
    placeholder,
    field,
    form,
    options,
    isMulti = false,
    isDisabled = false,
    customFunctionEnabled = false,
    handleSelectChange
  }) => {
    
    const onChange = (option) => {
      form.setFieldValue(
        field.name,
        isMulti ? option.map((item) => item.value) : option?.value || ""
      );
      if (customFunctionEnabled) {
        handleSelectChange(option);
      }
    };

    const getValue = () => {
      if (options) {
        return isMulti
          ? options.filter((option) => field.value?.indexOf(option.value) >= 0)
          : options.find((option) => option.value === field.value) || null;
      } else {
        return isMulti ? [] : null;
      }
    };

    return (
      <Select
        className={className}
        name={field.name}
        value={getValue()}
        onChange={onChange}
        placeholder={placeholder}
        options={options}
        isMulti={isMulti}
        isDisabled={isDisabled}
      />
    );
  };


const CustomSelectPicker = (props) => {
  const { data, name, placeHolder, isMulti, label, isDisabled, customFunctionEnabled, handleSelectChange } = props;

  return (
    <>
      <label className="text-xs my-1 font-poppins">
        {label} 
      </label>
      <Field
        className="font-poppins
        rounded-lg
        shadow-md 
        placeholder:text-gray text-xs"
        name={name}
        options={data}
        component={CustomSelect}
        placeholder={placeHolder}
        isMulti={isMulti}
        isDisabled={isDisabled}
        customFunctionEnabled={customFunctionEnabled}
        handleSelectChange={handleSelectChange}
      />
    </>
  );
};

CustomSelectPicker.propTypes = {
  data: PropTypes.instanceOf(array),
  name: PropTypes.string,
  placeHolder: PropTypes.string,
  isMulti: PropTypes.bool,
  isDisabled: PropTypes.bool,
  label: PropTypes.string,
  customFunctionEnabled: PropTypes.bool,
  handleSelectChange: PropTypes.func
};

CustomSelect.propTypes = {
    className: PropTypes.string,
    field: PropTypes.any,
    form: PropTypes.any,
    placeholder: PropTypes.string,
    isMulti: PropTypes.bool,
    isDisabled: PropTypes.bool,
    label: PropTypes.string,
    options: PropTypes.instanceOf(array),
    customFunctionEnabled: PropTypes.bool,
    handleSelectChange: PropTypes.func
}

export default CustomSelectPicker;
