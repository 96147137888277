export const ACCEPT_SERVICE = {
  APPLICATION_JSON: "application/json",
  MULTIPART_DATA: "multipart/form-data",
  FORM_URL_ENCODED: "application/x-www-form-urlencoded",
  SPREADSHEAT_DATA: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
};
export const DEFAULT_AUTH_TOKEN = "Basic c3RyaXgtdWk6";
export const DEFAULT_ACCEPT_SERVICE = ACCEPT_SERVICE.FORM_URL_ENCODED;
export const GOOGLE_NETWORK_PING_URL =
  "https://clients3.google.com/generate_204";
export const BASE_URL = "http://3.19.34.141:9091/rpm-user-service/api/";

export const BASE_URL1 = "http://3.19.34.141:9091/rpm-user-service/";

export const MONITORING_BASE_URL = "http://3.19.34.141:9092/"


export class ApiUrl {
  static loginRequest = () => {
    return BASE_URL1 + "api/auth/authenticate";
  };
  static refreshToken = () => {
    return BASE_URL + "auth/refresh-token";
  };
  static changePassword = () => {
    return BASE_URL1 + "api/user/change-password";
  };
  static getUserTypes = () => {
    return BASE_URL1 + "api/user/roles";
  };
  static getUsers = (status) => {
    return BASE_URL + `user/all/${status}`;
  };
  static getUsersByStatus = (status) => {
    return BASE_URL + `user/by/${status}`;
  };
  static getUsersListPagenation = (status, page, size, role) => {
    return BASE_URL + `user/all/${status}/pageable?role=${role}&page=${page}&size=${size}`;
  }
  static addUsers = () => {
    return BASE_URL + "auth/register";
  };
  static getUserById = (id) => {
    return BASE_URL + `user/${id}`;
  };
  static editUserById = () => {
    return BASE_URL1 + `api/user`;
  };
  static addPatients = () => {
    return BASE_URL + `patient`;
  };
  static getPatients = (status) => {
    return BASE_URL + `patient/all/${status}`;
  };

  static getPatientsListPagenation = (status, page, size) => {
    return BASE_URL + `patient/all/${status}/pageable?page=${page}&size=${size}`;
  }
  
  static getUsersDevicesPatientsCount = (user) => {
    return BASE_URL + `user/active-counts?createdBy=${user}`;
  }
  // device to patient
  static linkDevicePatients = () => {
    return BASE_URL1 + `device/assign-device-patient`;
  };
  // list of devices to that patient
  static linkDevicePatientListRequest = (patientId) => {
    return BASE_URL + `patient/${patientId}/devices`;
  };
  // assign doctor to patient
  static linkDoctorPatients = () => {
    return BASE_URL + `patient/assign-patient-doctor`;
  };
  // list of doctor to that patient
  static linkDoctorPatientListRequest = (patientId) => {
    return BASE_URL + `patient/patient-doctor/${patientId}`;
  };
  static getPatientById = (patientId) => {
    return BASE_URL + `patient/${patientId}`;
  };

  static getDeviceTypesList = () => {
    return BASE_URL1 + `device/types`;
  };

  static getDeviceList = (status) => {
    return BASE_URL1 + `device/all/${status}`;
  };

  static getDeviceListPagenation = (status, page, size) => {
    return BASE_URL1 + `device/all/${status}/pageable?page=${page}&size=${size}`
  }

  static addDevice = () => {
    return BASE_URL1 + `device`;
  };

  static getDeviceById = (id) => {
    return BASE_URL1 + `device/${id}`;
  };

  // update device by ID
  static updateDeviceById = () => {
    return BASE_URL1 + `device/update_device`;
  };

  static getVendorsList = () => {
    return BASE_URL1 + `device/vendor`;
  };

  // doctor patients list
  static getDoctorsPatientsList = (id) => {
    return BASE_URL + `patient/doctor/${id}`;
  };

  // Monitoring Api's
  static getMonitoringDeviceDataByIdRequest = (deviceId, patientId) => {
    return MONITORING_BASE_URL + `uni-portal-service/api/ipulseox/${deviceId}/${patientId}`;
  }

  static getDeviceDetailsPaginationRequest = (page, size) => {
    return MONITORING_BASE_URL + `uni-portal-service/api/ipulseox/350916066722433?page=${page}&size=${size}`;
  }

  // Refresh monitoring api data
  static refreshMonitoringDeviceDataByIdRequest = (deviceId, patientId) => {
    return MONITORING_BASE_URL + `uni-portal-service/api/ipulseox/${deviceId}/${patientId}/refresh`;
  }

  // get Reports Data
  static getReportsDataRequest = ( refId, from, to, pRefId, page, size ) => {
    return MONITORING_BASE_URL + `uni-portal-service/api/ipulseox/report/generate?devicerefid=${refId}&patientrefid=${pRefId}&fromdate=${from}&todate=${to}&page=${page}&size=${size}`;
  }

  // download report api

  static downloadReportRequest = ( refId, from, to, pRefId ) => {
    return MONITORING_BASE_URL + `uni-portal-service/api/ipulseox/report/download?devicerefid=${refId}&patientrefid=${pRefId}&fromdate=${from}&todate=${to}`;
  }

  static fpRequest = () => {
    return BASE_URL + "auth/forgotpassword";
  }

  static resetPasswordRequest = () => {
    return BASE_URL + "auth/resetpassword";
  }

  static verifyEmailRequest = () => {
    return BASE_URL + "auth/setpassword"
  }
}
