import * as LoginActionsCreator from "./LoginActionsCreator";

export function getLoginState(state) {
  return state.LoginReducer;
}

export function defaultLoginInState() {
  return {
    isLoading: false,
    loginResponse: null,
    loginError: null,
  };
}
/**
 * Login Reducer its take state and action
 * @param state
 * @param action
 */
export const LoginReducer = (state, action) => {
  if (
    !state ||
    action.type === LoginActionsCreator.LOGIN_ACTION.RESET_LOGIN_STATE
  ) {
    return { ...state, ...defaultLoginInState() };
  }

  if (action.type === LoginActionsCreator.LOGIN_ACTION.REQUEST) {
    return { ...state, isLoading: true };
  }

  if (action.type === LoginActionsCreator.LOGIN_ACTION.RESPONSE) {
    return { ...state, loginResponse: action.data, isLoading: false };
  }

  if (action.type === LoginActionsCreator.LOGIN_ACTION.ERROR) {
    return { ...state, loginError: action.data, isLoading: false };
  }

  return state;
};


export function getFpState(state) {
  return state.FpReducer;
}

export function defaultFpState() {
  return {
    isLoading: false,
    fpResponse: null,
    fpError: null,
  };
}

export const FpReducer = (state, action) => {
  if (
    !state ||
    action.type === LoginActionsCreator.FP_ACTION.RESET
  ) {
    return { ...state, ...defaultFpState() };
  }

  if (action.type === LoginActionsCreator.FP_ACTION.REQUEST) {
    return { ...state, isLoading: true };
  }

  if (action.type === LoginActionsCreator.FP_ACTION.RESPONSE) {
    return { ...state, fpResponse: action.data, isLoading: false };
  }

  if (action.type === LoginActionsCreator.FP_ACTION.ERROR) {
    return { ...state, fpError: action.data, isLoading: false };
  }

  return state;
};




export function getResetPasswordState(state) {
  return state.ResetPasswordReducer;
}

export function defaultResetPasswordState() {
  return {
    isLoading: false,
    resetPasswordResponse: null,
    resetPasswordError: null,
  };
}

export const ResetPasswordReducer = (state, action) => {
  if (
    !state ||
    action.type === LoginActionsCreator.RESET_PASSWORD_ACTION.RESET
  ) {
    return { ...state, ...defaultResetPasswordState() };
  }

  if (action.type === LoginActionsCreator.RESET_PASSWORD_ACTION.REQUEST) {
    return { ...state, isLoading: true };
  }

  if (action.type === LoginActionsCreator.RESET_PASSWORD_ACTION.RESPONSE) {
    return { ...state, resetPasswordResponse: action.data, isLoading: false };
  }

  if (action.type === LoginActionsCreator.RESET_PASSWORD_ACTION.ERROR) {
    return { ...state, resetPasswordError: action.data, isLoading: false };
  }

  return state;
};



export function getVerifyEmailState(state) {
  return state.VerifyEmailReducer;
}

export function defaultVerifyEmailState() {
  return {
    isLoading: false,
    verifyEmailResponse: null,
    verifyEmailError: null,
  };
}


export const VerifyEmailReducer = (state, action) => {
  if (
    !state ||
    action.type === LoginActionsCreator.VERIFY_EMAIL_ACTION.RESET
  ) {
    return { ...state, ...defaultVerifyEmailState() };
  }

  if (action.type === LoginActionsCreator.VERIFY_EMAIL_ACTION.REQUEST) {
    return { ...state, isLoading: true };
  }

  if (action.type === LoginActionsCreator.VERIFY_EMAIL_ACTION.RESPONSE) {
    return { ...state, verifyEmailResponse: action.data, isLoading: false };
  }

  if (action.type === LoginActionsCreator.VERIFY_EMAIL_ACTION.ERROR) {
    return { ...state, verifyEmailError: action.data, isLoading: false };
  }

  return state;
};


export default LoginReducer;
