import React from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import PropTypes from 'prop-types';
import { format } from 'date-fns';
import "../../index.css"

const CustomDatePicker = (props) => {
    const { name, selected, placeholder, onChange, label, maxDate = new Date(), isDisabled = false  } = props;
  
    const handleDateChange = (date) => {
        const formattedDate = date ? format(date, "yyyy-MM-dd 00:00:00") : '';
        onChange(formattedDate);
    };

    return (
      <>
        <label className="block text-xs mt-2 font-poppins">
          {label}
        </label>
        <DatePicker
          showIcon
          selected={selected}
          dateFormat="dd-MMM-yyyy"
          name={name}
          maxDate={maxDate}
          className="w-full border
          border-gray-light
          rounded-lg
          shadow-md 
          placeholder:text-gray text-xs font-poppins"
          wrapperClassName="custom-datepicker"
          placeholderText={placeholder}
          onChange={handleDateChange}
          disabled={isDisabled}
          popperClassName="custom-datepicker-popper"
        /> 
      </>
    );
};

CustomDatePicker.propTypes = {
    name: PropTypes.string.isRequired,
    selected: PropTypes.instanceOf(Date),
    placeholder: PropTypes.string,
    onChange: PropTypes.func.isRequired,
    label: PropTypes.string,
    maxDate: PropTypes.instanceOf(Date),
    isDisabled: PropTypes.bool
};

export default CustomDatePicker;
