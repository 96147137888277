import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { useParams } from "react-router-dom";
import Swal from "sweetalert2";

import AssociationTable from "../Details/AssociationTable";
import {
  linkDoctorPatientListRequest,
  linkDoctorPatientListReset,
  linkDevicePatientListRequest,
  linkDevicePatientListReset,
  linkDevicePatientReset,
  linkDevicePatientRequest,
  linkDoctorPatientReset,
  linkDoctorPatientRequest,
  getPatientByIdRequest,
  getPatientByIdReset,
} from "../../../../Redux/PatientState/PatientActionCreator";
import CustomButton from "../../../../NewUiComponents/CustomButton";
import { appData, appMessages } from "../../../../Constants";
import AssociationForm from "./AssociationForm";
import ModalComponent from "../../../../NewUiComponents/ModalComponent";
import withHoc from "../../../../NewUiComponents/Layout";
import Loader from "../../../../NewUiComponents/Loader";
import GraphView1 from "./GraphView";
import LOCAL_KEYS, {
  parseLocalStorageJSON,
} from "../../../../Utilites/LocalStorage";
import Card from "../../../../NewUiComponents/Card";
import { convertDateTime, getFullKey } from "../../../../Utilites/Helpers";
import {
  requestRefreshMonitoringDataAction,
  setResetMonitoringDataRefreshState,
  setResetMonitoringDevcieState,
} from "../../../../Redux/MonitoringState/MonitoringActionCreator";

const PatientDetails = () => {
  const typeOfUser = parseLocalStorageJSON(LOCAL_KEYS.USER_DETAILS).role;
  const dispatch = useDispatch();
  let { patientId } = useParams();
  const [patientItem, setPatientItem] = useState(null);
  const [associationFormProp, setAssociationFormProp] = useState({
    visible: false,
    type: "",
  });

  const patientByIdReducerState = useSelector(
    (state) => state.GetPatientByIdReducer
  );
  const linkDoctorPatientListReducerState = useSelector(
    (state) => state.LinkDoctorPatientListStateReducer
  );
  const linkDevicePatientListReducerState = useSelector(
    (state) => state.LinkDevicePatientListStateReducer
  );
  const linkDevicePatientReducerState = useSelector(
    (state) => state.LinkDevicePatientStateReducer
  );
  const linkDoctorPatientReducerState = useSelector(
    (state) => state.LinkDoctorPatientStateReducer
  );

  const monitoringDeviceDataReducerState = useSelector(
    (state) => state.MonitoringDeviceReducer
  );

  const currentDeviceReadingsState = useSelector(
    (state) => state.CurrentDeviceReadingsReducer
  );

  const refreshMonitoringDeviceReducerState = useSelector(
    (state) => state.RefreshMonitoringDeviceReducer
  );

  console.log(
    monitoringDeviceDataReducerState?.monitoringData,
    "MonitoringDeviceReducer"
  );

  const refreshDeviceData = () => {
    dispatch(setResetMonitoringDataRefreshState());
    let data =
      linkDevicePatientListReducerState?.linkDevicePatientListResponse?.map(
        (item) => item.device_ref_key
      );
    dispatch(requestRefreshMonitoringDataAction({data,patientId}));
  };

  useEffect(() => {
    const payload = { patientId };
    dispatch(setResetMonitoringDataRefreshState());
    dispatch(linkDoctorPatientListReset());
    dispatch(linkDevicePatientListReset());
    dispatch(setResetMonitoringDevcieState());
    dispatch(linkDevicePatientReset());
    dispatch(linkDoctorPatientReset());
    dispatch(getPatientByIdReset());
    dispatch(getPatientByIdRequest(payload));
    dispatch(linkDoctorPatientListRequest(payload));
    dispatch(linkDevicePatientListRequest(payload));
  }, [patientId]);

  useEffect(() => {
    if (patientByIdReducerState?.patientByIdResponse) {
      setPatientItem(patientByIdReducerState?.patientByIdResponse);
    }
    if (patientByIdReducerState?.patientByIdError) {
      toast(appMessages.ERROR_OCCURED);
      setPatientItem(null);
    }
  }, [patientByIdReducerState]);

  useEffect(() => {
    if (linkDevicePatientReducerState?.linkDevicePatientResponse) {
      const payload = {
        patientId,
      };
      dispatch(linkDevicePatientListRequest(payload));
      toast.success(appMessages.UPDATED_SUCCESSFULLY);
      setAssociationFormProp({
        visible: false,
        type: "",
      });
    }
    if (linkDevicePatientReducerState?.linkDevicePatientError) {
      toast.error(appMessages.ERROR_OCCURED);
    }
  }, [linkDevicePatientReducerState]);

  useEffect(() => {
    if (linkDoctorPatientReducerState?.linkDoctorPatientResponse) {
      const payload = {
        patientId,
      };
      dispatch(linkDoctorPatientListRequest(payload));
      toast.success(appMessages.UPDATED_SUCCESSFULLY);
      setAssociationFormProp({
        visible: false,
        type: "",
      });
    }
    if (linkDoctorPatientReducerState?.linkDoctorPatientError) {
      toast.error(appMessages.ERROR_OCCURED);
    }
  }, [linkDoctorPatientReducerState]);

  const handleRemoveDoctorPatientAssociation = (doctorAssociationItem) => {
    const payload = {
      patientId: patientId,
      doctorId: doctorAssociationItem.id,
      status: "REMOVE",
    };
    dispatch(linkDoctorPatientReset());
    dispatch(linkDoctorPatientRequest(payload));
  };

  const handleRemoveDevicePatientAssociation = (deviceAssociationItem) => {
    const payload = {
      patientId: patientId,
      deviceId: deviceAssociationItem.id,
      status: "REMOVE",
    };
    dispatch(linkDevicePatientReset());
    dispatch(linkDevicePatientRequest(payload));
  };

  const handleAddPatientAssociation = (associationType) => {
    if (patientItem?.active === "Y") {
      setAssociationFormProp({
        visible: true,
        type: associationType,
      });
    } else {
      Swal.fire({
        title: "Alert",
        text: `${associationType} associations cannot be added while patient is inactive`,
        icon: "warning",
      });
    }
  };

  const handleCloseAddPatientAssociation = () => {
    setAssociationFormProp({
      visible: false,
      type: "",
    });
  };

  const handleAssociationSubmit = (formData) => {
    const payload = {
      ...formData,
      patientId: patientId,
      status: "ADD",
    };
    dispatch(linkDevicePatientReset());
    dispatch(linkDoctorPatientReset());
    associationFormProp.type === "doctor"
      ? dispatch(linkDoctorPatientRequest(payload))
      : dispatch(linkDevicePatientRequest(payload));
  };

  const renderPatientAssociations = (type, associationsData) => {
    const renderAssociationFormOrList = () => {
      return (
        <>
          <AssociationTable
            type={type}
            removeDoctorPatientAssociation={
              handleRemoveDoctorPatientAssociation
            }
            removeDevicePatientAssociation={
              handleRemoveDevicePatientAssociation
            }
            data={associationsData}
            associationFormProp={associationFormProp}
          />
        </>
      );
    };

    return (
      <>
        <div className="flex flex-row align-center justify-between">
          <p className="font-poppins font-bold  text-sm lg:text-md xl:text-md">
            {type === "doctor" ? "Doctor Associations" : "Device Attachments"}
          </p>
          {(typeOfUser === "ADMIN" || typeOfUser === "HOSPITAL_ADMIN") && (
            <CustomButton
              isDisabled={false}
              name={type === "doctor" ? "Associate doctors" : "Attach devices"}
              type={"button"}
              onClick={() => handleAddPatientAssociation(type)}
            />
          )}
        </div>
        {renderAssociationFormOrList()}
      </>
    );
  };

  useEffect(() => {
    return () => {
      dispatch(getPatientByIdReset());
      dispatch(linkDevicePatientReset());
      dispatch(linkDoctorPatientReset());
    };
  }, []);

  return (
    <div className="container-fluid overflow-y-scroll h-4/5">
      <Loader
        isLoading={
          patientByIdReducerState?.patientByIdLoading ||
          linkDoctorPatientListReducerState?.linkDoctorPatientListLoading ||
          linkDoctorPatientReducerState?.linkDoctorPatientLoading ||
          linkDevicePatientListReducerState?.linkDevicePatientListLoading ||
          linkDevicePatientReducerState?.linkDevicePatientLoading ||
          currentDeviceReadingsState?.isCurrentDeviceReadingsLoading || 
          refreshMonitoringDeviceReducerState?.isRefreshMonitoringDataLoading
        }
      />
      <ModalComponent
        show={associationFormProp.visible}
        onClose={handleCloseAddPatientAssociation}
        title={
          associationFormProp.type === "device"
            ? "Manage Devices"
            : "Manage Doctors"
        }
      >
        <AssociationForm
          associationFormProp={associationFormProp}
          onAssociationSubmit={handleAssociationSubmit}
        />
      </ModalComponent>
      <div className="p-6 m-4 bg-white rounded-2xl shadow-2xl">
        <p className="text-xl font-bold font-poppins text-primary">
          Patient Profile
        </p>
        <div className="flex flex-row gap-4">
          <div className="w-1/2 lg:1/3 xl:1/3 my-4">
            <Card className={"rounded-2xl shadow-lg p-5"}>
              <div className="flex items-center gap-4 my-1">
                <img
                  src={require("../../../../Assets/Images/profile.png")}
                  className="w-20 h-20 rounded-3xl border-4 border-gray-light"
                />
                <div className="">
                  <p className="font-poppins text-sm text-center font-bold my-1">{`${patientByIdReducerState?.patientByIdResponse?.patient_name}`}</p>
                </div>
              </div>
              <p className="font-poppins font-bold text-sm lg:text-md xl:text-md my-2 my-1">
                Contact Details
              </p>
              <div className="flex flex-row gap-2 items-center my-3">
                <svg viewBox="0 0 1024 1024" fill="#1a4490" className="size-5">
                  <path d="M744 62H280c-35.3 0-64 28.7-64 64v768c0 35.3 28.7 64 64 64h464c35.3 0 64-28.7 64-64V126c0-35.3-28.7-64-64-64zm-8 824H288V134h448v752zM472 784a40 40 0 1080 0 40 40 0 10-80 0z" />
                </svg>
                <p className="font-poppins text-sm">
                  {patientByIdReducerState?.patientByIdResponse?.phone}
                </p>
              </div>
              <div className="flex flex-row gap-2 items-center my-3">
                <svg viewBox="0 0 24 24" fill="#1a4490" className="size-5">
                  <path d="M22 6c0-1.1-.9-2-2-2H4c-1.1 0-2 .9-2 2v12c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V6m-2 0l-8 5-8-5h16m0 12H4V8l8 5 8-5v10z" />
                </svg>
                <p className="font-poppins text-sm">
                  {patientByIdReducerState?.patientByIdResponse?.email}
                </p>
              </div>
              <div className="flex flex-row gap-2 items-center my-3">
                <svg viewBox="0 0 1000 1000" fill="#1a4490" className="size-5">
                  <path d="M426 50c13.333 0 20 6.667 20 20v860c0 13.333-6.667 20-20 20h-46c-13.333 0-20-6.667-20-20V490H184c-10.667 0-20-2-28-6-8-1.333-16.667-5.333-26-12L10 390c-6.667-4-10-9.333-10-16s3.333-12 10-16l120-82c9.333-6.667 18-10.667 26-12 5.333-2.667 14.667-4 28-4h176V70c0-13.333 6.667-20 20-20h46m564 208c6.667 4 10 9.333 10 16s-3.333 12-10 16l-118 82c-14.667 8-23.333 12-26 12-9.333 4-18.667 6-28 6H516l-40-230h342c12 0 21.333 1.333 28 4 6.667 2.667 15.333 6.667 26 12l118 82" />
                </svg>
                <p className="font-poppins text-sm">
                  {Object.values(
                    patientByIdReducerState?.patientByIdResponse?.address || {}
                  ).join(", ")}
                </p>
              </div>
            </Card>
          </div>
          <div className="w-2/3 my-4">
            <Card className={"rounded-2xl shadow-lg p-5"}>
              <p className="font-poppins font-bold  text-sm lg:text-md xl:text-md my-1">Overview</p>
              <div className="flex flex-row items-center justify-between">
                <div>
                  <p className="font-poppins text-xs text-gray">Patient id</p>
                  <p className="font-poppins  text-sm lg:text-md xl:text-md">#{patientId}</p>
                </div>
                <div>
                  <p className="font-poppins text-xs text-gray">Gender</p>
                  <p className="font-poppins  text-sm lg:text-md xl:text-md">
                    {
                      getFullKey(
                        appData?.gender,
                        patientByIdReducerState?.patientByIdResponse?.gender
                      )?.title
                    }
                  </p>
                </div>
                <div>
                  <p className="font-poppins text-xs text-gray">
                    Admit date
                  </p>
                  <p className="font-poppins  text-sm lg:text-md xl:text-md">01-04-2024</p>
                </div>
                <div>
                  <p className="font-poppins text-xs text-gray">Status</p>
                  <p className="font-poppins  text-sm lg:text-md xl:text-md">
                    {
                      getFullKey(
                        appData.statusList,
                        patientByIdReducerState?.patientByIdResponse?.active
                      )?.title
                    }
                  </p>
                </div>
              </div>
            </Card>
            <Card className={"rounded-2xl shadow-lg p-5 my-4"}>
              <div className="flex flex-row items-center gap-3">
                <p className="font-poppins font-bold  text-sm lg:text-md xl:text-md">
                  Current Device Readings
                </p>
                <div
                  className="flex flex-row items-center cursor-pointer"
                  onClick={() => refreshDeviceData()}
                >
                  <svg viewBox="0 0 24 24" fill="#4e58a8" className="size-5">
                    <path d="M17.65 6.35A7.958 7.958 0 0012 4a8 8 0 00-8 8 8 8 0 008 8c3.73 0 6.84-2.55 7.73-6h-2.08A5.99 5.99 0 0112 18a6 6 0 01-6-6 6 6 0 016-6c1.66 0 3.14.69 4.22 1.78L13 11h7V4l-2.35 2.35z" />
                  </svg>
                  <p className="font-poppins text-xs underline underline-offset-4">
                    Refresh readings
                  </p>
                </div>
              </div>
              <div className="flex flex-row items-center justify-between my-3">
                {currentDeviceReadingsState?.currentDeviceReadings?.map(
                  (item) => {
                    return (
                      <div
                        className="flex flex-row gap-3 items-center justify-center"
                        key={item.id}
                      >
                        <img
                          src={require("../../../../Assets/Images/oxymeter.png")}
                          className="w-20 h-20"
                        />
                        <div className="">
                          <p className="font-poppins text-sm">
                            Pulse Oxymeter
                          </p>
                          <p className="font-poppins text-lg font-bold my-1">
                            {item.pulseRateValue || null} bpm
                          </p>
                          <p className="font-poppins text-lg font-bold my-1 ">
                            {item?.spO2Value || null}% Spo2
                          </p>
                          <p className="font-poppins text-gray text-xs my-1">
                            {convertDateTime(item?.timeOfReadingTaken) || null}
                          </p>
                        </div>
                      </div>
                    );
                  }
                )}
              </div>
            </Card>
          </div>
        </div>
        <Card className={"rounded-2xl shadow-lg p-5"}>
          {linkDevicePatientListReducerState?.linkDevicePatientListResponse &&
            linkDevicePatientListReducerState?.linkDevicePatientListResponse
              ?.length > 0 && <GraphView1 />}
        </Card>
        <Card className={"rounded-2xl shadow-lg p-5 my-4"}>
          {renderPatientAssociations(
            "doctor",
            linkDoctorPatientListReducerState?.linkDoctorPatientListResponse ||
              []
          )}
        </Card>

        <Card className={"rounded-2xl shadow-lg px-8 py-6 my-4"}>
          {renderPatientAssociations(
            "device",
            linkDevicePatientListReducerState?.linkDevicePatientListResponse ||
              []
          )}
        </Card>
      </div>
    </div>
  );
};

export default withHoc(PatientDetails);
