import * as PatientActionCreator from "./PatientActionCreator";

export function defaultaddPatientState() {
  return {
    addPatientLoading: false,
    addPatientResponse: null,
    addPatientError: null,
  };
}

export const AddPatientStateReducer = (state, action) => {
  if (
    !state ||
    action.type === PatientActionCreator.PATIENT_ACTION.ADD_PATIENT_STATE_RESET
  ) {
    return { ...state, ...defaultaddPatientState() };
  }

  if (
    action.type ===
    PatientActionCreator.PATIENT_ACTION.ADD_PATIENT_STATE_REQUEST
  ) {
    return { ...state, addPatientLoading: true };
  }

  if (
    action.type ===
    PatientActionCreator.PATIENT_ACTION.ADD_PATIENT_STATE_RESPONSE
  ) {
    return {
      ...state,
      addPatientResponse: action.data,
      addPatientLoading: false,
    };
  }

  if (
    action.type === PatientActionCreator.PATIENT_ACTION.ADD_PATIENT_STATE_ERROR
  ) {
    return { ...state, addPatientError: action.data, addPatientLoading: false };
  }

  return state;
};

export function defaultPatientListState() {
  return {
    patientListLoading: false,
    patientListResponse: null,
    patientListError: null,
  };
}

export const PatientsListReducer = (state, action) => {
  if (
    !state ||
    action.type === PatientActionCreator.PATIENT_ACTION.PATIENT_LIST_RESET
  ) {
    return { ...state, ...defaultPatientListState() };
  }

  if (
    action.type === PatientActionCreator.PATIENT_ACTION.PATIENT_LIST_REQUEST
  ) {
    return { ...state, patientListLoading: true };
  }

  if (
    action.type === PatientActionCreator.PATIENT_ACTION.PATIENT_LIST_RESPONSE
  ) {
    return {
      ...state,
      patientListResponse: action.data,
      patientListLoading: false,
    };
  }

  if (action.type === PatientActionCreator.PATIENT_ACTION.PATIENT_LIST_ERROR) {
    return {
      ...state,
      patientListError: action.data,
      patientListLoading: false,
    };
  }

  return state;
};

export function defaultPatientsListPagenationState(){
  return {
    patientListLoading: false,
    patientList: null,
    patientListError: null,
    page: 0,
    size: 10
  }
}

export const PatientsListPagenationReducer = (state, action) => {
  if (
    !state ||
    action.type === PatientActionCreator.PATIENT_ACTION.PATIENTS_LIST_PAGENATION_RESET
  ) {
    return { ...state, ...defaultPatientsListPagenationState() };
  }

  if (
    action.type === PatientActionCreator.PATIENT_ACTION.PATIENTS_LIST_PAGENATION_REQUEST
  ) {
    return { ...state, patientListLoading: true, page: action.data.page, size: action.data.size };
  }

  if (
    action.type === PatientActionCreator.PATIENT_ACTION.PATIENTS_LIST_PAGENATION_RESPONSE
  ) {
    return {
      ...state,
      patientList: action.data,
      patientListLoading: false,
    };
  }

  if (action.type === PatientActionCreator.PATIENT_ACTION.PATIENTS_LIST_PAGENATION_ERROR) {
    return {
      ...state,
      patientListError: action.data,
      patientListLoading: false,
    };
  }

  return state;
}

export function defaultGetPatientByIdState() {
  return {
    patientByIdLoading: false,
    patientByIdResponse: null,
    patientByIdError: null,
  };
}

export const GetPatientByIdReducer = (state, action) => {
  if (
    !state ||
    action.type ===
      PatientActionCreator.PATIENT_ACTION.GET_PATIENT_BY_ID_STATE_RESET
  ) {
    return { ...state, ...defaultGetPatientByIdState() };
  }

  if (
    action.type ===
    PatientActionCreator.PATIENT_ACTION.GET_PATIENT_BY_ID_STATE_REQUEST
  ) {
    return { ...state, patientByIdLoading: true };
  }

  if (
    action.type ===
    PatientActionCreator.PATIENT_ACTION.GET_PATIENT_BY_ID_STATE_RESPONSE
  ) {
    return {
      ...state,
      patientByIdResponse: action.data,
      patientByIdLoading: false,
    };
  }

  if (
    action.type ===
    PatientActionCreator.PATIENT_ACTION.GET_PATIENT_BY_ID_STATE_ERROR
  ) {
    return {
      ...state,
      patientByIdError: action.data,
      patientByIdLoading: false,
    };
  }

  return state;
};

export function defaultUpdatePatientState() {
  return {
    updatePatientLoading: false,
    updatePatientResponse: null,
    updatePatientError: null,
  };
}

export const UpdatePatientStateReducer = (state, action) => {
  if (
    !state ||
    action.type ===
      PatientActionCreator.PATIENT_ACTION.UPDATE_PATIENT_STATE_RESET
  ) {
    return { ...state, ...defaultUpdatePatientState() };
  }

  if (
    action.type ===
    PatientActionCreator.PATIENT_ACTION.UPDATE_PATIENT_STATE_REQUEST
  ) {
    return { ...state, updatePatientLoading: true };
  }

  if (
    action.type ===
    PatientActionCreator.PATIENT_ACTION.UPDATE_PATIENT_STATE_RESPONSE
  ) {
    return {
      ...state,
      updatePatientResponse: action.data,
      updatePatientLoading: false,
    };
  }

  if (
    action.type ===
    PatientActionCreator.PATIENT_ACTION.UPDATE_PATIENT_STATE_ERROR
  ) {
    return {
      ...state,
      updatePatientError: action.data,
      updatePatientLoading: false,
    };
  }

  return state;
};

export function defaultLinkDoctorPatientState() {
  return {
    linkDoctorPatientLoading: false,
    linkDoctorPatientResponse: null,
    linkDoctorPatientError: null,
  };
}

export const LinkDoctorPatientStateReducer = (state, action) => {
  if (
    !state ||
    action.type ===
      PatientActionCreator.PATIENT_ACTION.LINK_DOCTOR_PATIENT_STATE_RESET
  ) {
    return { ...state, ...defaultLinkDoctorPatientState() };
  }

  if (
    action.type ===
    PatientActionCreator.PATIENT_ACTION.LINK_DOCTOR_PATIENT_STATE_REQUEST
  ) {
    return { ...state, linkDoctorPatientLoading: true };
  }

  if (
    action.type ===
    PatientActionCreator.PATIENT_ACTION.LINK_DOCTOR_PATIENT_STATE_RESPONSE
  ) {
    return {
      ...state,
      linkDoctorPatientResponse: action.data,
      linkDoctorPatientLoading: false,
    };
  }

  if (
    action.type ===
    PatientActionCreator.PATIENT_ACTION.LINK_DOCTOR_PATIENT_STATE_ERROR
  ) {
    return {
      ...state,
      linkDoctorPatientError: action.data,
      linkDoctorPatientLoading: false,
    };
  }

  return state;
};

export function defaultLinkDoctorPatientListState() {
  return {
    linkDoctorPatientListLoading: false,
    linkDoctorPatientListResponse: null,
    linkDoctorPatientListError: null,
  };
}

export const LinkDoctorPatientListStateReducer = (state, action) => {
  if (
    !state ||
    action.type ===
      PatientActionCreator.PATIENT_ACTION.LINK_DOCTOR_PATIENT_LIST_STATE_RESET
  ) {
    return { ...state, ...defaultLinkDoctorPatientListState() };
  }

  if (
    action.type ===
    PatientActionCreator.PATIENT_ACTION.LINK_DOCTOR_PATIENT_LIST_STATE_REQUEST
  ) {
    return { ...state, linkDoctorPatientListLoading: true };
  }

  if (
    action.type ===
    PatientActionCreator.PATIENT_ACTION.LINK_DOCTOR_PATIENT_LIST_STATE_RESPONSE
  ) {
    return {
      ...state,
      linkDoctorPatientListResponse: action.data,
      linkDoctorPatientListLoading: false,
    };
  }

  if (
    action.type ===
    PatientActionCreator.PATIENT_ACTION.LINK_DOCTOR_PATIENT_LIST_STATE_ERROR
  ) {
    return {
      ...state,
      linkDoctorPatientListError: action.data,
      linkDoctorPatientListLoading: false,
    };
  }

  return state;
};

export function defaultLinkDevicePatientState() {
  return {
    linkDevicePatientLoading: false,
    linkDevicePatientResponse: null,
    linkDevicePatientError: null,
  };
}

export const LinkDevicePatientStateReducer = (state, action) => {
  if (
    !state ||
    action.type ===
      PatientActionCreator.PATIENT_ACTION.LINK_DEVICE_PATIENT_STATE_RESET
  ) {
    return { ...state, ...defaultLinkDevicePatientState() };
  }

  if (
    action.type ===
    PatientActionCreator.PATIENT_ACTION.LINK_DEVICE_PATIENT_STATE_REQUEST
  ) {
    return { ...state, linkDevicePatientLoading: true };
  }

  if (
    action.type ===
    PatientActionCreator.PATIENT_ACTION.LINK_DEVICE_PATIENT_STATE_RESPONSE
  ) {
    return {
      ...state,
      linkDevicePatientResponse: action.data,
      linkDevicePatientLoading: false,
    };
  }

  if (
    action.type ===
    PatientActionCreator.PATIENT_ACTION.LINK_DEVICE_PATIENT_STATE_ERROR
  ) {
    return {
      ...state,
      linkDevicePatientError: action.data,
      linkDevicePatientLoading: false,
    };
  }

  return state;
};

export function defaultLinkDevicePatientListState() {
  return {
    linkDevicePatientListLoading: false,
    linkDevicePatientListResponse: null,
    linkDevicePatientListError: null,
  };
}

export const LinkDevicePatientListStateReducer = (state, action) => {
  if (
    !state ||
    action.type ===
      PatientActionCreator.PATIENT_ACTION.LINK_DEVICE_PATIENT_LIST_STATE_RESET
  ) {
    return { ...state, ...defaultLinkDevicePatientListState() };
  }

  if (
    action.type ===
    PatientActionCreator.PATIENT_ACTION.LINK_DEVICE_PATIENT_LIST_STATE_REQUEST
  ) {
    return { ...state, linkDevicePatientListLoading: true };
  }

  if (
    action.type ===
    PatientActionCreator.PATIENT_ACTION.LINK_DEVICE_PATIENT_LIST_STATE_RESPONSE
  ) {
    return {
      ...state,
      linkDevicePatientListResponse: action.data,
      linkDevicePatientListLoading: false,
    };
  }

  if (
    action.type ===
    PatientActionCreator.PATIENT_ACTION.LINK_DEVICE_PATIENT_LIST_STATE_ERROR
  ) {
    return {
      ...state,
      linkDevicePatientListError: action.data,
      linkDevicePatientListLoading: false,
    };
  }

  return state;
};
