import { ApiUrl } from "../../../Constants/ApiConstants";
import {
  REQUEST_METHOD,
  WebService,
} from "../../WebServiceManager/RequestService";
import DashboardRequestBody from "./DashboardRequestBody";

export default class DashboardService {
  request = new DashboardRequestBody();
  static shared = new DashboardService();

  dashboardRequest = (payload) => {
    return new Promise((resolve, reject) => {
      WebService.clientShared
        .callServiceApi(
          ApiUrl.getUsersDevicesPatientsCount(payload),
          this.request.dashboardRequest(REQUEST_METHOD.GET)
        )
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };
}
