import React from "react";
import Logo from "../../Logo";
import PropTypes from "prop-types";
import { useLocation, useNavigate } from "react-router-dom";
import LOCAL_KEYS, {
  parseLocalStorageJSON,
} from "../../../Utilites/LocalStorage";
import { SidebarData } from "../../../Constants";
import { useDispatch, useSelector } from "react-redux";
import { resetUserTypeStateAction } from "../../../Redux/UserState/UserActionCreator";

export const SidebarItem = ({ item, onClickLogout }) => {
  const DefaultUserState = useSelector((state) => state.DefaultUserStaeReducer);
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const navigationToPath = (item) => {
    if(DefaultUserState?.previousPath){
      dispatch(resetUserTypeStateAction())
    }
    navigate(item.path);
  };

  const handleNavigation = (item) => {
    item.path === "/logout" ? onClickLogout() : navigationToPath(item);
  };

  return (
    item.access.includes(parseLocalStorageJSON(LOCAL_KEYS.USER_DETAILS).role) &&
    !item.onlyHeader && (
      <>
        <div
          className="flex flex-row items-center justify-center my-10 cursor-pointer"
          onClick={() => handleNavigation(item)}
        >
          {location.pathname === item.path && (
            <div className="bg-primary h-10 w-1 rounded-2xl absolute left-1" />
          )}

          <div className="w-12">
            {location.pathname === item.path ? item.activeIcon : item.icon}
          </div>
          <div className="w-20">
            <p
              className={`font-poppins text-xs xl:text-sm  ${
                item.id === 6
                  ? "text-danger"
                  : location.pathname == item.path
                  ? "text-primary font-bold"
                  : "text-black"
              }`}
            >
              {item.title}
            </p>
          </div>
        </div>
        <div>
          {item.children &&
            (location.pathname === item.path ||
              item.children.some(
                (child) => location.pathname === child.path
              )) && (
              <div className="ml-10">
                {item.children.map((child) => (
                  <SidebarItem
                    item={child}
                    key={child.id}
                    onClickLogout={onClickLogout}
                  />
                ))}
              </div>
            )}
        </div>
      </>
    )
  );
};

const Sidebar = ({ handleLogout }) => {
  const navigate = useNavigate();

  return (
    <div className="h-screen relative z-10 overflow-none  shadow-2xl bg-white">
      <div
        className="flex border-b-2 border-primary justify-center items-center cursor-pointer"
        onClick={() => navigate("/")}
      >
        <Logo textClassName={"text-white"} />
      </div>
      {SidebarData.map((item) => (
        <SidebarItem item={item} key={item.id} onClickLogout={handleLogout} />
      ))}
      <div className="">
        <img
          src={require("../../../Assets/Images/sidebar.png")}
          className="absolute  bottom-0"
        />
      </div>
    </div>
  );
};

SidebarItem.propTypes = {
  item: PropTypes.any,
  onClickLogout: PropTypes.func,
};

Sidebar.propTypes = {
  handleLogout: PropTypes.func,
};

export default Sidebar;
