import React from "react";
import Proptypes from "prop-types";

const Footer = ({ expandMenuBar }) => {
  return (
    <div
      className={`bg-gradient-to-r from-primary via-secondary to-primary fixed   bottom-0 h-12 p-3 ${
        expandMenuBar ? "w-screen lg:w-5/6" : "w-screen"
      }`}
    >
      <p className="text-sm text-white text-center font-bold">
        Copyright © 2024 RPM All rights reserved.
      </p>
    </div>
  );
};

Footer.propTypes = {
  expandMenuBar: Proptypes.bool,
};

export default Footer;
 