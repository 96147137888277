import React from "react";
import TextInput from "../../../../NewUiComponents/TextInput";
import CustomButton from "../../../../NewUiComponents/CustomButton";
import { Formik } from "formik";
import { EditUserSchema, UserDetailsSchems } from "../../../../Utilites/Schema";
import PhoneInputComponent from "../../../../NewUiComponents/PhoneNumber";
import PropTypes from "prop-types";
import zipcodes from "zipcodes";
import CustomSelectPicker from "../../../../NewUiComponents/CustomSelect";
import { useSelector } from "react-redux";
import { appData } from "../../../../Constants";
import { transformArray } from "../../../../Utilites/Helpers";
import CustomRadio from "../../../../NewUiComponents/Radio";
import LOCAL_KEYS, {
  parseLocalStorageJSON,
} from "../../../../Utilites/LocalStorage";

const UserDetailsForm = (props) => {
  const { buttonName, formSubmit, initialValues, view, id } = props;
  const UserTypesReducer = useSelector((state) => state.UserTypesReducer);
  const { userTypes } = UserTypesReducer;

  const userRole = parseLocalStorageJSON(LOCAL_KEYS.USER_DETAILS).role;

  const transformArrayFunction = () => {
    if (userRole === "ADMIN") {
      return transformArray(userTypes);
    } else {
      return transformArray(userTypes.filter((item) => item !== "ADMIN"));
    }
  };

  return (
    <div className="justify-center">
      <Formik
        initialValues={initialValues}
        validationSchema={
          view === "addUser"
            ? UserDetailsSchems
            : EditUserSchema(view === "editUser")
        }
        onSubmit={(values, { setSubmitting }) => {
          const changedKeys = Object.keys(values).filter(
            (key) => values[key] !== initialValues[key]
          );
          // Create a new object with only the changed fields
          const changedValues = {};
          changedKeys.forEach((key) => {
            changedValues[key] = values[key];
          });
          setSubmitting(false);
          const formValues = {
            userName: values.userName,
            email: values.email,
            // password: values.password,
            firstName: values.firstName,
            lastName: values.lastName,
            gender: values.gender,
            phone: values.phone,
            active: values.active,
            role: values.role,
            address: {
              addressLine1: values.address.addressLine1,
              addressLine2: values.address.addressLine2,
              city: values.address.city,
              state: values.address.state,
              zip: values.address.zip,
              country: values.address.country,
            },
          };
          formSubmit(
            view === "editUser" ? changedValues : formValues,
            view,
            id
          );
        }}
        validationContext={{ $isEdit: view === "editUser" }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
        }) => (
          <form
            method="POST"
            onSubmit={handleSubmit}
            className="w-full max-h-[85vh] overflow-y-scroll space-y-1"
          >
            <div className="lg:flex lg:space-x-6">
              <div className="lg:w-1/2">
                <TextInput
                  name="firstName"
                  placeHolder="First name"
                  label="First Name"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.firstName}
                  disabled={view === "editUser" || view === "view"}
                />
                <span className="text-danger text-sm">
                  {errors.firstName && touched.firstName && errors.firstName}
                </span>
              </div>
              <div className="lg:w-1/2">
                <TextInput
                  name={"lastName"}
                  placeHolder="Last name"
                  label="Last Name"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.lastName}
                  disabled={view === "editUser" || view === "view"}
                />
              </div>
            </div>
            <div className="lg:flex lg:space-x-6">
              <div className="lg:w-1/2">
                <TextInput
                  placeHolder="unisoft"
                  name={"userName"}
                  label="User Name"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.userName}
                  disabled={view === "editUser" || view === "view"}
                />
                <span className="text-danger text-sm">
                  {errors.userName && touched.userName && errors.userName}
                </span>
              </div>
              {/* <div className="lg:w-1/2">
                <TextInput
                  name={"password"}
                  placeHolder="********"
                  label="Password"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.password}
                  disabled={view === "view"}
                />
                <span className="text-danger text-sm">
                  {errors.password && touched.password && errors.password}
                </span>
              </div> */}
            </div>
            <div className="lg:flex lg:items-center lg:space-x-6">
              <div className="lg:w-1/2 lg:mt-4">
                <p className="block text-xs my-1 font-poppins">Phone Number</p>
                <PhoneInputComponent
                  name={"phone"}
                  label="Phone Number"
                  onChange={(phone) => {
                    handleChange({
                      target: {
                        name: "phone",
                        value: phone,
                      },
                    });
                  }}
                  value={values.phone}
                  onBlur={handleBlur}
                  disabled={view === "view"}
                />
                <span className="text-danger text-sm">
                  {errors.phone && touched.phone && errors.phone}
                </span>
              </div>
              <div className="lg:w-1/2">
                <TextInput
                  name={"email"}
                  placeHolder="example@gmail.com"
                  label="Email"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.email}
                  disabled={view === "view"}
                />
                <span className="text-danger text-sm">
                  {errors.email && touched.email && errors.email}
                </span>
              </div>
            </div>
            <div className="lg:flex lg:items-center lg:space-x-6">
              <div className="lg:w-1/2">
                <CustomRadio
                  data={appData.gender}
                  name={"gender"}
                  label={"Gender"}
                  disabled={view === "editUser" || view === "view"}
                />
                <span className="text-danger text-sm">
                  {errors.gender && touched.gender && errors.gender}
                </span>
              </div>
              <div className="lg:w-1/2">
                <CustomRadio
                  data={appData.status}
                  name={"active"}
                  label={"Active"}
                  disabled={view === "view" || view === "addUser"}
                />
                <span className="text-danger text-sm">
                  {errors.active && touched.active && errors.active}
                </span>
              </div>
            </div>

            <div>
              <CustomSelectPicker
                data={transformArrayFunction()}
                name={"role"}
                placeHolder={"Select Type of  User"}
                isMulti={false}
                label={"User Type"}
                isDisabled={view === "view"}
              />
              <span className="text-danger text-sm">
                {errors.role && touched.role && errors.role}
              </span>
            </div>
            <div>
              <>
                <TextInput
                  placeHolder="street adress Line 1"
                  label="Address"
                  value={values.address?.addressLine1}
                  name={"address.addressLine1"}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  disabled={view === "view"}
                />
                <span className="text-danger text-sm">
                  {errors.address &&
                    touched.address &&
                    errors.address.addressLine1}
                </span>
              </>
              <TextInput
                placeHolder="street adress Line 2"
                label=""
                value={values.address?.addressLine2}
                name={"address.addressLine2"}
                onChange={handleChange}
                onBlur={handleBlur}
                disabled={view === "view"}
              />
            </div>
            <div className="lg:flex lg:space-x-6">
              <div className="lg:w-1/2">
                <TextInput
                  placeHolder=""
                  label="Postal / Zip Code"
                  onChange={(code) => {
                    handleChange(code);
                    // Lookup zip code information and update the state
                    const lookupResult = zipcodes.lookup(code.target.value);
                    handleChange({
                      target: {
                        name: "address",
                        value: {
                          ...values.address,
                          zip: code.target.value,
                          city: lookupResult?.city || "",
                          state: lookupResult?.state || "",
                          country: lookupResult?.country || "",
                        },
                      },
                    });
                  }}
                  onBlur={handleBlur}
                  value={values.address?.zip}
                  name={"zip"}
                  disabled={view === "view"}
                />
                <span className="text-danger text-sm">
                  {errors.address && touched.address && errors.address.zip}
                </span>
              </div>
              <div className="lg:w-1/2">
                <TextInput
                  placeHolder="select country"
                  label="Country"
                  // onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.address?.country}
                  name={"country"}
                  disabled={true}
                />
              </div>
            </div>

            <div className="lg:flex lg:space-x-6">
              <div className="lg:w-1/2">
                <TextInput
                  placeHolder="select state"
                  label="State"
                  // onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.address?.state}
                  name={"state"}
                  disabled={true}
                />
              </div>
              <div className="lg:w-1/2">
                <TextInput
                  placeHolder="clty"
                  label="City"
                  // onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.address?.city}
                  name={"city"}
                  disabled={true}
                />
              </div>
            </div>
            <div className="pt-4">
              {buttonName && (
                <CustomButton
                  name={buttonName}
                  type={"submit"}
                  isDisabled={isSubmitting}
                />
              )}
            </div>
          </form>
        )}
      </Formik>
    </div>
  );
};
UserDetailsForm.propTypes = {
  title: PropTypes.string,
  id: PropTypes.number,
  buttonName: PropTypes.string.isRequired,
  formSubmit: PropTypes.func.isRequired,
  view: PropTypes.string.isRequired,
  initialValues: PropTypes.shape({
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    userName: PropTypes.string,
    email: PropTypes.string,
    password: PropTypes.string,
    gender: PropTypes.string,
    phone: PropTypes.string,
    role: PropTypes.string,
    active: PropTypes.string,
    address: PropTypes.shape({
      addressLine1: PropTypes.string,
      addressLine2: PropTypes.string,
      city: PropTypes.string,
      state: PropTypes.string,
      zip: PropTypes.string,
      country: PropTypes.string,
    }),
  }),
};

export default UserDetailsForm;
