export const TOKEN_ACTION =  {
  IS_VALID_TOKEN_REQUEST: 'IS_VALID_TOKEN_REQUEST',
  IS_VALID_TOKEN_RESPONSE: 'IS_VALID_TOKEN_RESPONSE',
  IS_VALID_TOKEN_ERROR: 'IS_VALID_TOKEN_ERROR',
  REFRESH_TOKEN_REQUEST: 'REFRESH_TOKEN_REQUEST',
  REFRESH_TOKEN_RESPONSE:  'REFRESH_TOKEN_RESPONSE',
  REFRESH_TOKEN_ERROR:  'REFRESH_TOKEN_ERROR'
}

export const refreshTokenRequest = (params) => ({
  type: TOKEN_ACTION.REFRESH_TOKEN_REQUEST,
  data: params
});

export const refreshTokenResponse = (res) => ({
  type: TOKEN_ACTION.REFRESH_TOKEN_RESPONSE,
  data: res
});

export const refreshTokenError = (err) => ({
  type: TOKEN_ACTION.REFRESH_TOKEN_ERROR,
  data: err
});

export const isValidTokenRequest = () => ({
  type: TOKEN_ACTION.IS_VALID_TOKEN_REQUEST
});

export const isValidTokenResponse = (res) => ({
  type: TOKEN_ACTION.IS_VALID_TOKEN_RESPONSE,
  data: res
});

export const isValidTokenError = (err) => ({
  type: TOKEN_ACTION.IS_VALID_TOKEN_ERROR,
  data: err
});

