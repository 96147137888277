import { call, put, takeLatest } from "@redux-saga/core/effects";
import { DASHBOARD_ACTION, errorDashboardCountAction, responseDashboardCountAction } from "./DashboardActionCreator";
import DashboardService from "../../Services/RestApiManager/DashboardManager/DashboardService";


export function* dashboardRequest(action) {
  try {
    const response = yield call(DashboardService.shared.dashboardRequest, action.data);
    yield put(responseDashboardCountAction(response));
  } catch (error) {
    yield put(errorDashboardCountAction(error));
  }
}

export function* dashboardWatcherSaga() {
  yield takeLatest(DASHBOARD_ACTION.DASHBOARD_COUNT_REQUEST, dashboardRequest);
}
