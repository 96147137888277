import * as AppconfigActioncreator from "./AppconfigActionCreator";

export function appconfigState() {
  return {
    expandMenuBar: true,
    appLoading: false,
  };
}

export const AppConfigReducer = (state, action) => {
  if (
    !state ||
    action.type === AppconfigActioncreator.APPCONFIGACTION.RESET_TOGGLE_MENU_BAR
  ) {
    return { ...state, ...appconfigState() };
  }

  if (action.type === AppconfigActioncreator.APPCONFIGACTION.TOGGLE_MENU_BAR) {
    return { ...state, expandMenuBar: action.data };
  }

  return state;
};
