import React from "react";
import moment from 'moment';


export const getHeaderName = (pathName) => {

  switch (pathName) {

    case "/dashboard":
      return "Dashboard";

    case "/users":
      return "Users";

    case "/patients":
      return "Patients";

    case "/devices":
      return "Devices";

    case "/change-password":
      return "Change Password";

    case "/profile":
      return "User profile";

    case "/reports":
      return "Reports";

    default:
      return "";
  }
};

export const converDateToReadableFormat = (dateValue) => {
  if(dateValue){
    return moment(dateValue).format('DD-MM-YY HH:mm a')
  }
  return 'N/A';
}

export const downloadBufferToExcel = (arrayBuffer, fileName) => {
  const a = document.createElement("a");
  a.href = URL.createObjectURL(
    new Blob([arrayBuffer], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    })
  );
  a.download = fileName;
  a.click();
};


