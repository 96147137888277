import { combineReducers } from "redux";
import LoginReducer, {
  FpReducer,
  ResetPasswordReducer,
  VerifyEmailReducer,
} from "../LoginState/LoginReducer";
import TokenReducer from "../TokenState/TokenActionReducer";
import { ChangePasswordReducer } from "../ProfileState/ProfileReducer";
import {
  UserTypesReducer,
  UsersListReducer,
  AddUserReducer,
  GetUserByIdReducer,
  UpdateUserReducer,
  GetDoctorPatientsListReducer,
  DefaultUserStaeReducer,
  UsersListPagenationReducer,
  ActiveFlagReducer,
} from "../UserState/UserReducer";
import {
  AddPatientStateReducer,
  PatientsListReducer,
  UpdatePatientStateReducer,
  LinkDoctorPatientStateReducer,
  LinkDoctorPatientListStateReducer,
  LinkDevicePatientStateReducer,
  LinkDevicePatientListStateReducer,
  GetPatientByIdReducer,
  PatientsListPagenationReducer,
} from "../PatientState/PatientReducer";
import {
  DeviceDetailsReducer,
  DeviceDetailsWithPagenationReducer,
  DeviceListPagenationReducer,
  DeviceListReducer,
  DeviceTypesListReducer,
  GetdeviceByIdReducer,
  UpdateDeviceReducer,
} from "../DeviceState/DeviceReducer";
import { VendorListReducer } from "../VendorState/VendorReducer";
import { AppConfigReducer } from "../AppconifgStage/AppconfigReducer";
import {
  CurrentDeviceReadingsReducer,
  MonitoringDeviceReducer,
  RefreshMonitoringDeviceReducer,
} from "../MonitoringState/MonitoringReducer";
import {
  DownloadReportReducer,
  ReportsReducer,
} from "../ReportsState/ReportsReducer";
import DashboardReducer from "../DashboardState/DashboardReducer";

const rootReducer = combineReducers({
  LoginReducer,
  TokenReducer,
  DashboardReducer,
  ChangePasswordReducer,
  UserTypesReducer,
  UsersListReducer,
  AddUserReducer,
  UpdateUserReducer,
  GetUserByIdReducer,
  AddPatientStateReducer,
  UsersListPagenationReducer,
  PatientsListReducer,
  PatientsListPagenationReducer,
  UpdatePatientStateReducer,
  LinkDoctorPatientStateReducer,
  LinkDoctorPatientListStateReducer,
  LinkDevicePatientStateReducer,
  LinkDevicePatientListStateReducer,
  GetPatientByIdReducer,
  DeviceTypesListReducer,
  DeviceListReducer,
  DeviceListPagenationReducer,
  DeviceDetailsReducer,
  VendorListReducer,
  GetdeviceByIdReducer,
  UpdateDeviceReducer,
  AppConfigReducer,
  GetDoctorPatientsListReducer,
  MonitoringDeviceReducer,
  RefreshMonitoringDeviceReducer,
  CurrentDeviceReadingsReducer,
  DeviceDetailsWithPagenationReducer,
  ReportsReducer,
  DownloadReportReducer,
  DefaultUserStaeReducer,
  FpReducer,
  ResetPasswordReducer,
  VerifyEmailReducer,
  ActiveFlagReducer,
});
const rootR = (state, action) => {
  if (action.type === "RESET_ALL_STATES") {
    // Reset the entire state by setting it to undefined
    state = undefined;
  }

  // Call rootReducer as usual
  return rootReducer(state, action);
};
export default rootR;
