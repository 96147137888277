import { ApiUrl } from "../../../Constants/ApiConstants";
import {
  REQUEST_METHOD,
  WebService,
} from "../../WebServiceManager/RequestService";
import VendorRequestBody from "./VendorRequestBody";

export default class VendorService {
  request = new VendorRequestBody();
  static shared = new VendorService();

  getVendorListRequest = () => {
    return new Promise((resolve, reject) => {
      WebService.clientShared
      .callServiceApi(
        ApiUrl.getVendorsList(),
        this.request.getVendorListRequest(REQUEST_METHOD.GET)
      )
      .then((responce)=>{
        resolve(responce)
      })
      .catch((error)=>{
        reject(error);
      })
    })
  }

}
