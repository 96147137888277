import { call, put, takeLatest } from "@redux-saga/core/effects";
import {
  PATIENT_ACTION,
  addPatientError,
  addPatientResponse,
  patientListError,
  patientListResponse,
  updatePatientResponse,
  updatePatientError,
  linkDevicePatientResponse,
  linkDevicePatientError,
  linkDevicePatientListResponse,
  linkDevicePatientListError,
  linkDoctorPatientResponse,
  linkDoctorPatientError,
  linkDoctorPatientListResponse,
  linkDoctorPatientListError,
  getPatientByIdError,
  getPatientByIdResponse,
  patientListPagenationResponse,
  patientListPagenationError
} from "../PatientState/PatientActionCreator";
import PatientService from "../../Services/RestApiManager/PatientManager/PatientService";

export function* addPatientRequestCall(payload) {
  try {
    const response = yield call(
      PatientService.shared.addPatientRequest,
      payload.data
    );
    yield put(addPatientResponse(response));
  } catch (error) {
    yield put(addPatientError(error));
  }
}

export function* addPatientRequestSaga() {
  yield takeLatest(
    PATIENT_ACTION.ADD_PATIENT_STATE_REQUEST,
    addPatientRequestCall
  );
}

export function* patientListRequestCall(payload) {
  try {
    const response = yield call(
      PatientService.shared.patientListRequest,
      payload.data
    );
    yield put(patientListResponse(response));
  } catch (error) {
    yield put(patientListError(error));
  }
}

export function* patientListRequestSaga() {
  yield takeLatest(PATIENT_ACTION.PATIENT_LIST_REQUEST, patientListRequestCall);
}

export function* updatePatientRequestCall(payload) {
  try {
    const response = yield call(
      PatientService.shared.updatePatientRequest,
      payload.data
    );
    yield put(updatePatientResponse(response));
  } catch (error) {
    yield put(updatePatientError(error));
  }
}

export function* updatePatientRequestSaga() {
  yield takeLatest(
    PATIENT_ACTION.UPDATE_PATIENT_STATE_REQUEST,
    updatePatientRequestCall
  );
}

export function* linkDevicePatientRequestCall(payload) {
  try {
    const response = yield call(
      PatientService.shared.linkDevicePatientRequest,
      payload.data
    );
    yield put(linkDevicePatientResponse(response));
  } catch (error) {
    yield put(linkDevicePatientError(error));
  }
}

export function* linkDevicePatientRequestSaga() {
  yield takeLatest(
    PATIENT_ACTION.LINK_DEVICE_PATIENT_STATE_REQUEST,
    linkDevicePatientRequestCall
  );
}

export function* linkDevicePatientListRequestCall(payload) {
  try {
    const response = yield call(
      PatientService.shared.linkDevicePatientListRequest,
      payload.data
    );
    yield put(linkDevicePatientListResponse(response));
  } catch (error) {
    yield put(linkDevicePatientListError(error));
  }
}

export function* linkDevicePatientListRequestSaga() {
  yield takeLatest(
    PATIENT_ACTION.LINK_DEVICE_PATIENT_LIST_STATE_REQUEST,
    linkDevicePatientListRequestCall
  );
}

export function* linkDoctorPatientRequestCall(payload) {
  try {
    const response = yield call(
      PatientService.shared.linkDoctorPatientRequest,
      payload.data
    );
    yield put(linkDoctorPatientResponse(response));
  } catch (error) {
    yield put(linkDoctorPatientError(error));
  }
}

export function* patientListPagenationRequest(payload){
  try {
    const response = yield call(
      PatientService.shared.patientListPagenationRequest,
      payload.data
    );
    yield put(patientListPagenationResponse(response));
  } catch (error) {
    yield put(patientListPagenationError(error));
  }
}

export function* linkDoctorPatientRequestSaga() {
  yield takeLatest(
    PATIENT_ACTION.LINK_DOCTOR_PATIENT_STATE_REQUEST,
    linkDoctorPatientRequestCall
  );
}

export function* linkDoctorPatientListRequestCall(payload) {
  try {
    const response = yield call(
      PatientService.shared.linkDoctorPatientListRequest,
      payload.data
    );
    yield put(linkDoctorPatientListResponse(response));
  } catch (error) {
    yield put(linkDoctorPatientListError(error));
  }
}

export function* linkDoctorPatientListRequestSaga() {
  yield takeLatest(
    PATIENT_ACTION.LINK_DOCTOR_PATIENT_LIST_STATE_REQUEST,
    linkDoctorPatientListRequestCall
  );
}



export function* patientByIdRequestCall(payload) {
  try {
    const response = yield call(
      PatientService.shared.patientByIdRequest,
      payload.data
    );
    yield put(getPatientByIdResponse(response));
  } catch (error) {
    yield put(getPatientByIdError(error));
  }
}

export function* patientByIdRequestCallSaga() {
  yield takeLatest(
    PATIENT_ACTION.GET_PATIENT_BY_ID_STATE_REQUEST,
    patientByIdRequestCall
  );
}

export function* patientsListPagenationWatcherSaga() {
  yield takeLatest(PATIENT_ACTION.PATIENTS_LIST_PAGENATION_REQUEST, patientListPagenationRequest);
}

