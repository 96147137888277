import { all, fork } from "redux-saga/effects";
import { loginWatcherSaga } from "../LoginState/LoginSaga";
import { changePasswordWatcherSaga } from "../ProfileState/ProfileSaga";
import {
  getUserTypesWatcherSaga,
  getUsersListWatcherSaga,
  addUsersWatcherSaga,
  getUserByIdWatcherSaga,
  editUserByIdWatcherSaga,
  getDoctorsPatientsListWatcherSaga,
  getUsersListPagenationWatcherSaga,
} from "../UserState/UserSaga";
import {
  isTokenValidWatcherSaga,
  refreshTokenWatcherSaga,
} from "../TokenState/TokenActionSaga";
import {
  addPatientRequestSaga,
  patientListRequestSaga,
  updatePatientRequestSaga,
  linkDevicePatientRequestSaga,
  linkDevicePatientListRequestSaga,
  linkDoctorPatientRequestSaga,
  linkDoctorPatientListRequestSaga,
  patientByIdRequestCallSaga,
  patientsListPagenationWatcherSaga,
} from "../PatientState/PatientSaga";
import {
  addDeviceWatcherSaga,
  getDeviceByIdWatcherSaga,
  getDeviceListWatcherSaga,
  getDeviceTypesListWatcherSaga,
  updateDeviceByIdWatcherSaga,
  deviceDetailsWithPagenationWatcherSaga,
  getDeviceListPagenationWatcherSaga
} from "../DeviceState/DeviceSaga";
import { getVendorListWatcherSaga } from "../VendorState/VendorSaga";
import { getMonitoringDeviceWatcherSaga, refreshMonitoringDeviceDataWatcherSaga } from "../MonitoringState/MonitoringSaga";
import { downloadReportWatcherSaga, getReportsWatcherSaga } from "../ReportsState/ReportsSaga";
import { dashboardWatcherSaga } from "../DashboardState/DashboardSaga";

export default function* rootSaga() {
  yield all([
    fork(loginWatcherSaga),
    fork(changePasswordWatcherSaga),
    fork(isTokenValidWatcherSaga),
    fork(refreshTokenWatcherSaga),
    fork(getUserTypesWatcherSaga),
    fork(getUsersListWatcherSaga),
    fork(getUsersListPagenationWatcherSaga),
    fork(addUsersWatcherSaga),
    fork(getUserByIdWatcherSaga),
    fork(addPatientRequestSaga),
    fork(patientListRequestSaga),
    fork(patientsListPagenationWatcherSaga),
    fork(editUserByIdWatcherSaga),
    fork(updatePatientRequestSaga),
    fork(linkDevicePatientRequestSaga),
    fork(linkDevicePatientListRequestSaga),
    fork(linkDoctorPatientRequestSaga),
    fork(linkDoctorPatientListRequestSaga),
    fork(patientByIdRequestCallSaga),
    fork(getDeviceTypesListWatcherSaga),
    fork(getDeviceListWatcherSaga),
    fork(getDeviceListPagenationWatcherSaga),
    fork(getVendorListWatcherSaga),
    fork(addDeviceWatcherSaga),
    fork(getDeviceByIdWatcherSaga),
    fork(updateDeviceByIdWatcherSaga),
    fork(getMonitoringDeviceWatcherSaga),
    fork(getDoctorsPatientsListWatcherSaga),
    fork(refreshMonitoringDeviceDataWatcherSaga),
    fork(getReportsWatcherSaga),
    fork(deviceDetailsWithPagenationWatcherSaga),
    fork(downloadReportWatcherSaga),
    fork(dashboardWatcherSaga),
  ]);
}
