import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { getHeaderName } from "../../../Utilites/Wrapper";
import { SidebarData } from "../../../Constants";
import LOCAL_KEYS, {
  parseLocalStorageJSON,
} from "../../../Utilites/LocalStorage";
import { SidebarItem } from "../SideBar";

const HeaderMenuItem = ({ svgIcon, onClickIcon, text }) => {
  return (
    <div
      className="bg-screenBg w-10 h-10 flex justify-center items-center rounded-3xl shadow-md cursor-pointer"
      onClick={onClickIcon}
    >
      {text ? text : svgIcon()}
    </div>
  );
};

const Header = ({ onMenubarToggle, expandMenuBar, handleLogout, onOpenMobileMenu, showMobileMenu }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [userDetails, setUserDetails] = useState(null);

  useEffect(() => {
    const userDetails = parseLocalStorageJSON(LOCAL_KEYS.USER_DETAILS);
    setUserDetails(userDetails);
  }, []);


  const renderMenuItems = (isMobileMenu) => {
    return(
      isMobileMenu ? <>
        {userDetails && SidebarData?.map((item) => {
              const hasAccess = item.access.includes(
                userDetails?.role
              );
              if (hasAccess) {
                return (
                  <SidebarItem
                    item={item}
                    key={item.id}
                    onClickLogout={handleLogout}
                  />
                )
              } 
              
        })
      }
      
      </> :
      <div className={`flex flex-row ${!expandMenuBar ? 'gap-12' : 'gap-3'} px-4`}>
            {userDetails && SidebarData?.map((item) => {
              const hasAccess = item.access.includes(
                userDetails?.role
              );
              const userName =
                parseLocalStorageJSON(LOCAL_KEYS.USER_DETAILS)
                  .lastName.split("")[0]
                  .toUpperCase() +
                parseLocalStorageJSON(LOCAL_KEYS.USER_DETAILS)
                  .firstName.split("")[0]
                  .toUpperCase();
  
              if (item.onlyHeader || (hasAccess && !expandMenuBar)) {
                return (
                  <>
                  <HeaderMenuItem
                    key={item.id}
                    item={item}
                    text={
                      item.onlyHeader &&
                      expandMenuBar && (
                        <p
                          className={`font-poppins text-sm font-bold ${
                            location.pathname === item.path && "text-primary"
                          }`}
                        >
                          {userName}
                        </p>
                      )
                    }
                    svgIcon={() =>
                      location.pathname === item.path ? item.activeIcon : item.icon
                    }
                    onClickIcon={() =>
                      item.path === "/logout" ? handleLogout() : navigate(item.path)
                  
                    }
                  />
                  {
                    (item.path === '/profile' && expandMenuBar) &&  <div>
                      <p className="font-poppins text-white text-sm my-1">{userDetails?.firstName} {userDetails?.lastName}</p>
                      <p className="font-poppins text-white text-xs">{userDetails?.role}</p>
                    </div> 
                  }
                  </>
                );
              }
              return null;
            })}
          </div>
    )
  }



  return (
    <>
      {
        showMobileMenu &&  <div className="bg-white m-4 absolute top-12 left-0 w-1/3 z-10 shadow-xl rounded-lg">
            {renderMenuItems(true)}
        </div>
      }
      
      <div className="shadow-2xl  bg-gradient-to-r from-primary via-secondary to-primary border-gray-light h-20 flex flex-row justify-between items-center gap-4 px-4">
        <div className=" flex flex-row justify-start items-center gap-2">
          {expandMenuBar ? (
            <>
              <svg
                viewBox="0 0 16 16"
                fill="#fff"
                className="text-primary size-5 cursor-pointer hidden lg:block"
                onClick={onMenubarToggle}
              >
                <path
                  fillRule="evenodd"
                  d="M1 2.75A.75.75 0 011.75 2h12.5a.75.75 0 110 1.5H1.75A.75.75 0 011 2.75zm0 5A.75.75 0 011.75 7h12.5a.75.75 0 110 1.5H1.75A.75.75 0 011 7.75zM1.75 12a.75.75 0 100 1.5h12.5a.75.75 0 100-1.5H1.75z"
                />
              </svg>
              <svg
                viewBox="0 0 16 16"
                fill="#fff"
                className="text-primary size-5 cursor-pointer hidden md:block lg:hidden"
                onClick={onOpenMobileMenu}
              >
                <path
                  fillRule="evenodd"
                  d="M1 2.75A.75.75 0 011.75 2h12.5a.75.75 0 110 1.5H1.75A.75.75 0 011 2.75zm0 5A.75.75 0 011.75 7h12.5a.75.75 0 110 1.5H1.75A.75.75 0 011 7.75zM1.75 12a.75.75 0 100 1.5h12.5a.75.75 0 100-1.5H1.75z"
                />
              </svg>
            </>
            
          ) : (
            <svg
              viewBox="0 0 16 16"
              fill="#fff"
              className="text-primary size-5 cursor-pointer"
              onClick={onMenubarToggle}
            >
              <path
                fill="#fff"
                d="M8 0a8 8 0 100 16A8 8 0 008 0zm0 14.5a6.5 6.5 0 110-13 6.5 6.5 0 010 13z"
              />
              <path
                fill="#fff"
                d="M10.5 4L8 6.5 5.5 4 4 5.5 6.5 8 4 10.5 5.5 12 8 9.5l2.5 2.5 1.5-1.5L9.5 8 12 5.5z"
              />
            </svg>
          )}

          <p className="font-poppins text-md  lg:text-lg xl:text-xl font-bold text-white tracking-wider">
            {getHeaderName(location.pathname)}
          </p>
        </div>
        {renderMenuItems(false)}
      </div>
    </>
    
  );
};

Header.propTypes = {
  onMenubarToggle: PropTypes.func,
  expandMenuBar: PropTypes.bool,
  handleLogout: PropTypes.func,
  onOpenMobileMenu: PropTypes.func,
  showMobileMenu: PropTypes.bool
};

HeaderMenuItem.propTypes = {
  svgIcon: PropTypes.any,
  onClickIcon: PropTypes.func,
  text: PropTypes.element,
};

export default Header;
