export const APPCONFIGACTION = {
  RESET_TOGGLE_MENU_BAR: "RESET_TOGGLE_MENU_BAR",
  TOGGLE_MENU_BAR: "TOGGLE_MENU_BAR",
};

// DEVICE TYPES
export const resetToggleMenuBar = () => ({
  type: APPCONFIGACTION.RESET_TOGGLE_MENU_BAR,
  data: undefined,
});

export const toggleMenubar = (data) => ({
  type: APPCONFIGACTION.TOGGLE_MENU_BAR,
  data,
});
