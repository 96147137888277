import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../../../../../NewUiComponents/Loader";
import {
  requestMonitoringDeviceAction,
  setResetMonitoringDevcieState,
} from "../../../../../Redux/MonitoringState/MonitoringActionCreator";
import LineGraph from "../../../../../NewUiComponents/LineGraph";
import { toast } from "react-toastify";
import { appMessages } from "../../../../../Constants";
import { useParams } from "react-router-dom";

const GraphView1 = () => {
  let { patientId } = useParams();
  const dispatch = useDispatch();
  const linkDevicePatientListReducerState = useSelector(
    (state) => state.LinkDevicePatientListStateReducer
  );
  const monitoringDeviceDataReducerState = useSelector(
    (state) => state.MonitoringDeviceReducer
  );

  useEffect(() => {
    dispatch(setResetMonitoringDevcieState());
    let data =
      linkDevicePatientListReducerState?.linkDevicePatientListResponse?.map(
        (item) => item.device_ref_key
      );
    dispatch(requestMonitoringDeviceAction({data, patientId}));
  }, []);

  useEffect(() => {
    if (
      !monitoringDeviceDataReducerState?.isMonitoringDataLoading &&
      monitoringDeviceDataReducerState?.monitoringDataError
    ) {
      toast.dismiss();
      toast.error(appMessages.DEVICE_MONITORING_DATA_ERROR);
    }
  }, [monitoringDeviceDataReducerState]);

  return (
    <>
      <Loader
        isLoading={
          linkDevicePatientListReducerState?.linkDevicePatientListLoading ||
          monitoringDeviceDataReducerState?.isMonitoringDataLoading
        }
      />
      {monitoringDeviceDataReducerState?.monitoringData?.map((item) => {
        if (item?.data?.length > 0) {
          return <LineGraph key={item.id} data={item?.data} id={item?.id} />;
        }
        return null;
      })}
    </>
  );
};

export default GraphView1;
