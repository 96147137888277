import { call, put, takeLatest } from "@redux-saga/core/effects";
import { jwtDecode } from "jwt-decode";

import {
  TOKEN_ACTION,
  isValidTokenResponse,
  isValidTokenError,
  refreshTokenResponse,
  refreshTokenError,
} from "./TokenActionCreator";
import LOCAL_KEYS, {parseLocalStorageJSON} from '../../Utilites/LocalStorage'
import AuthService from "../../Services/RestApiManager/AuthManager/AuthService";

export function* isValidTokenRequest() {
  try {
    const accessToken = parseLocalStorageJSON(LOCAL_KEYS.ACCESS_TOKEN);
    const currentDate = new Date();
    const decodedToken = jwtDecode(accessToken);
    if (decodedToken.exp * 1000 < currentDate.getTime()) {
      yield put(isValidTokenError(false));
    } else {
      yield put(isValidTokenResponse(true));
    }
  } catch (error) {
    yield put(isValidTokenError(false));
  }
}

export function* isTokenValidWatcherSaga() {
  yield takeLatest(TOKEN_ACTION.IS_VALID_TOKEN_REQUEST, isValidTokenRequest);
}



export function* refreshTokenRequest(action) {
  try {
    const response = yield call(AuthService.shared.refreshTokenRequest, action.data);
    yield put(refreshTokenResponse(response));
  } catch (error) {
    yield put(refreshTokenError(error));
  }
}

export function* refreshTokenWatcherSaga() {
  yield takeLatest(TOKEN_ACTION.REFRESH_TOKEN_REQUEST, refreshTokenRequest);
}
