import React, { useEffect } from "react";
import { Formik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import Hoc from "../../../NewUiComponents/Layout";
import {
  linkDevicePatientListRequest,
  patientListRequest,
} from "../../../Redux/PatientState/PatientActionCreator";
import CustomButton from "../../../NewUiComponents/CustomButton";
import CustomDatePicker from "../../../NewUiComponents/DatePicker";
import CustomSelectPicker from "../../../NewUiComponents/CustomSelect";
import {
  requestDownloadReportAction,
  requestReportsAction,
  setResetReportsState,
} from "../../../Redux/ReportsState/ReportsActionCreator";
import CusotmDataTable from "../../../NewUiComponents/Table";
import { convertDateTime } from "../../../Utilites/Helpers";
import { appMessages } from "../../../Constants";
import { toast } from "react-toastify";
import { ReportSchema } from "../../../Utilites/Schema";
import Loader from "../../../NewUiComponents/Loader";
import { format } from 'date-fns';
import { getDeviceDetailsPaginationRequestAction } from "../../../Redux/DeviceState/DeviceActionCreator";

const Reports = () => {
  const columns = [
    {
      name: "ID",
      selector: (row) => row.id,
      sortable: true,
    },
    {
      name: "Device Reference ID",
      selector: (row) => row.deviceReferenceId,
      sortable: true,
    },
    {
      name: "Pulse Rate",
      selector: (row) => row.pulseRateValue,
      sortable: true,
    },
    {
      name: "SpO2 Value",
      selector: (row) => row.spO2Value,
      sortable: true,
    },
    {
      name: "Time of Reading",
      selector: (row) => row.timeOfReadingTaken,
      sortable: true,
      format: (row) => convertDateTime(row.timeOfReadingTaken), // Format the date for better readability
    },
  ];

  const columns1 = [
    {
      name: "ID",
      selector: (row) => row.id,
      sortable: true,
    },
    {
      name: "Device Reference ID",
      selector: (row) => row.deviceReferenceId,
      sortable: true,
    },
    {
      name: "Pulse Rate",
      selector: (row) => row.pulseRateValue,
      sortable: true,
    },
    {
      name: "SpO2 Value",
      selector: (row) => row.spO2Value,
      sortable: true,
    },
  ];

  const initialValues = {
    selectPatient: "",
    selectDevice: "",
    todate: format(new Date(), "yyyy-MM-dd 00:00:00"),
    fromdate: format(new Date(new Date().setDate(new Date().getDate() - 30)), "yyyy-MM-dd 00:00:00") ,
    patientRefId: null,
  };

  const dispatch = useDispatch();
  const patientsListReducerSate = useSelector(
    (state) => state.PatientsListReducer
  );

  const linkDevicePatientListState = useSelector(
    (state) => state.LinkDevicePatientListStateReducer
  );

  const reportsReducerState = useSelector((state) => state.ReportsReducer);
  console.log(reportsReducerState?.reportsPatientId, "reportsReducerState");

  const DeviceDetailsPagenationReducer = useSelector((state) => state.DeviceDetailsWithPagenationReducer);


  const customPageChangeFunction = (page) => {
    dispatch(requestReportsAction({ 
      page: page - 1, 
      size: reportsReducerState?.size, 
      patient_name: reportsReducerState?.reportsPatientQurey?.name,
      patientRefId: reportsReducerState?.reportsPatientQurey?.id,
      fromdate: reportsReducerState?.reportsPatientQurey?.from,
      todate: reportsReducerState?.reportsPatientQurey?.to,
      selectDevice: reportsReducerState?.deviceRefId,
    }));
  }

  const customPageSizeChangeFunction = (size) => {
    dispatch(requestReportsAction({
      page: 0, 
      size: size, 
      patient_name: reportsReducerState?.reportsPatientQurey?.name,
      patientRefId: reportsReducerState?.reportsPatientQurey?.id,
      fromdate: reportsReducerState?.reportsPatientQurey?.from,
      todate: reportsReducerState?.reportsPatientQurey?.to,
      selectDevice: reportsReducerState?.deviceRefId,
     }));
    // dispatch(getDeviceDetailsPaginationRequestAction({page: 0, size: size}));

  }

  useEffect(() => {
    dispatch(setResetReportsState());
    dispatch(patientListRequest({ patientListStatus: "active" }));
    // dispatch(getDeviceDetailsPaginationRequestAction({ page: 0, size: 10 }));
  }, []);

  useEffect(() => {
    if (
      !patientsListReducerSate?.isPatientListLoading &&
      patientsListReducerSate?.patientListError
    ) {
      toast.dismiss();
      toast.error(appMessages.PATIENT_LIST_ERROR);
    }
  }, [patientsListReducerSate]);

  useEffect(() => {
    if (
      !linkDevicePatientListState?.isLinkDevicePatientListLoading &&
      linkDevicePatientListState?.linkDevicePatientListError
    ) {
      toast.dismiss();
      toast.error(appMessages.DEVICE_LIST_ERROR);
    }
  }, [linkDevicePatientListState]);

  useEffect(() => {
    if (
      !reportsReducerState?.isReportsDataLoading &&
      reportsReducerState?.reportsError
    ) {
      toast.dismiss();
      toast.error(appMessages.REPORTS_ERROR);
    }
  }, [reportsReducerState]);

  const handlePatientChange = (selectedOption, setFieldValue) => {
    setFieldValue("selectDevice", "");
    setFieldValue("patientRefId", selectedOption?.id);
    const payload = { patientId: selectedOption?.value };
    dispatch(linkDevicePatientListRequest(payload));
  };

  const generateReport = () => {
    dispatch(requestDownloadReportAction({
      deviceRefId: reportsReducerState?.deviceRefId,
      fromdate: reportsReducerState?.reportsPatientQurey?.from,
      todate: reportsReducerState?.reportsPatientQurey?.to,
      patientRefId: reportsReducerState?.reportsPatientQurey?.id,
    }))
  };

  return (
    <div className=" container-fluid p-5 overflow-y-scroll h-4/5">
      <Loader
        isLoading={
          reportsReducerState?.isReportsDataLoading
        }
      />
      <Formik
        initialValues={initialValues}
        validationSchema={ReportSchema}
        onSubmit={(values, { setSubmitting, resetForm }) => {
          let patient_name = patientsListReducerSate?.patientListResponse?.find(
            (patient) => patient.id === values.selectPatient
          )?.patient_name;
          setSubmitting(false);
          dispatch(requestReportsAction({...values, patient_name, page: reportsReducerState?.page, size: reportsReducerState?.size}));
          resetForm();
        }}
      >
        {({
          values,
          handleSubmit,
          handleChange,
          setFieldValue,
          errors,
          touched,
        }) => (
          <form
            method="post"
            onSubmit={handleSubmit}
            className="flex flex-row justify-start items-center gap-4"
          >
            <div className="w-1/5">
              <CustomSelectPicker
                data={
                  patientsListReducerSate?.patientListResponse?.map(
                    (patient) => ({
                      id: patient.patient_ref_id,
                      value: patient.id,
                      label: patient.patient_name,
                    })
                  ) || []
                }
                name={"selectPatient"}
                label={"Select Patient"}
                isMulti={false}
                customFunctionEnabled={true}
                handleSelectChange={(selectedOption) =>
                  handlePatientChange(selectedOption, setFieldValue)
                }
              />
              <span className="text-danger text-xs font-poppins">
                {errors.selectPatient &&
                  touched.selectPatient &&
                  errors.selectPatient}
              </span>
            </div>

            <div className="w-1/5">
              <CustomSelectPicker
                isDisabled={!values.selectPatient}
                data={
                  linkDevicePatientListState?.linkDevicePatientListResponse?.map(
                    (device) => ({
                      id: device.id,
                      value: device.device_ref_key,
                      label: device.deviceType,
                    })
                  ) || []
                }
                isMulti={false}
                name={"selectDevice"}
                label={"Select Device"}
              />
              <span className="text-danger text-xs font-poppins">
                {errors.selectDevice &&
                  touched.selectDevice &&
                  errors.selectDevice}
              </span>
            </div>

            <div className="w-1/5">
              <CustomDatePicker
                label={"From"}
                name={"fromdate"}
                selected={values.fromdate ? new Date(values.fromdate) : null}
                onChange={(date) => setFieldValue("fromdate", date)}
                placeholder={"Select from date"}
                // isDisabled={!values.selectPatient}
              />
              <span className="text-danger text-xs font-poppins">
                {errors.fromdate && touched.fromdate && errors.fromdate}
              </span>
            </div>
            <div className="w-1/5">
              <CustomDatePicker
                label={"To"}
                placeholder={"Select to date"}
                name={"todate"}
                onChange={(date) => setFieldValue("todate", date)}
                selected={values.todate ? new Date(values.todate) : null}
                // isDisabled={!values.selectPatient}
              />
              <span className="text-danger text-xs font-poppins">
                {errors.todate && touched.todate && errors.todate}
              </span>
            </div>
            <div className="pt-6 flex gap-1">
              <CustomButton
                type={"submit"}
                name={"Generate Report"}
                // isDisabled={reportsReducerState.reportsData?.length < 1}
                rounded={true}
                icon={() => (
                  <svg viewBox="0 0 24 24" fill="#fff" className="size-5">
                    <path d="M15.5 12a3.5 3.5 0 11-7 0 3.5 3.5 0 017 0z" />
                    <path
                      fillRule="evenodd"
                      d="M12 3.5c-3.432 0-6.125 1.534-8.054 3.24C2.02 8.445.814 10.352.33 11.202a1.6 1.6 0 000 1.598c.484.85 1.69 2.758 3.616 4.46C5.876 18.966 8.568 20.5 12 20.5c3.432 0 6.125-1.534 8.054-3.24 1.926-1.704 3.132-3.611 3.616-4.461a1.6 1.6 0 000-1.598c-.484-.85-1.69-2.757-3.616-4.46C18.124 5.034 15.432 3.5 12 3.5zM1.633 11.945c.441-.774 1.551-2.528 3.307-4.08C6.69 6.314 9.045 5 12 5c2.955 0 5.309 1.315 7.06 2.864 1.756 1.553 2.866 3.307 3.307 4.08a.111.111 0 01.017.056.111.111 0 01-.017.056c-.441.774-1.551 2.527-3.307 4.08C17.31 17.685 14.955 19 12 19c-2.955 0-5.309-1.315-7.06-2.864-1.756-1.553-2.866-3.306-3.307-4.08A.11.11 0 011.616 12a.11.11 0 01.017-.055z"
                    />
                  </svg>
                )}
              />
            </div>
          </form>
        )}
      </Formik>
      <div className="bg-white rounded-2xl shadow-2xl p-3 my-5">
        {reportsReducerState?.reportsPatientQurey &&
          reportsReducerState?.reportsData &&
          !reportsReducerState?.isReportsDataLoading && (
            <span>
            <span className="flex flex-row gap-6">
              <h1 className="font-poppins my-2 font-semibold">
                {reportsReducerState?.reportsPatientQurey?.name} Reports From :{" "}
                {convertDateTime(
                  reportsReducerState?.reportsPatientQurey?.from
                )}{" "}
                To : { convertDateTime(reportsReducerState?.reportsPatientQurey?.to)}
              </h1>
              <CustomButton
                onClick={generateReport}
                type={"button"}
                name={""}
                isDisabled={reportsReducerState.reportsData?.length < 1}
                rounded={true}
                icon={() => (
                  <svg
                    viewBox="0 0 24 24"
                    fill="#e14240"
                    className={`size-6`}
                  >
                    <path fill="none" d="M0 0h24v24H0z" />
                    <path
                      d="M12 2a1 1 0 0 1 1 1v7.586l2.293-2.293a1 1 0 0 1 1.414 1.414l-4 4a1 1 0 0 1-1.414 0l-4-4a1 1 0 1 1 1.414-1.414L11 10.586V3a1 1 0 0 1 1-1zM8 16a1 1 0 1 1 0 2h8a1 1 0 1 1 0-2H8z"
                      fill={`${"#fff"}`}
                    />
                  </svg>
                )}
              />
            </span>
            <h6 className="flex text-sm flex-row-reverse font-poppins text-secondary">All times indicated are in U.S. Eastern Time.</h6>
            </span>
          )}

        <CusotmDataTable
          fixedHeaderScrollHeight={"50vh"}
          enableSearch={false}
          data={reportsReducerState?.reportsData?.content || []}
          columns={columns}
          // title={"Patient Reports"}
          pagination={true}
          serverPageNation
          customPageChange={customPageChangeFunction}
          customPageSizeChangeFunction={customPageSizeChangeFunction}
          paginationTotalRows={reportsReducerState?.reportsData?.totalElements || 0}
        />
      </div>
    </div>
  );
};

export default Hoc(Reports);
