import * as DashboardActionsCreator from "./DashboardActionCreator";

export function defaultDashboardState() {
  return {
    isLoading: false,
    dashboardResponse: null,
    dashboardError: null,
  };
}

export const DashboardReducer = (state, action) => {
  if (
    !state ||
    action.type === DashboardActionsCreator.DASHBOARD_ACTION.RESET_DASHBOARD_STATE
  ) {
    return { ...state, ...defaultDashboardState() };
  }

  if (action.type === DashboardActionsCreator.DASHBOARD_ACTION.DASHBOARD_COUNT_REQUEST) {
    return { ...state, isLoading: true };
  }

  if (action.type === DashboardActionsCreator.DASHBOARD_ACTION.DASHBOARD_COUNT_RESPONSE) {
    return { ...state, dashboardResponse: action.data, isLoading: false };
  }

  if (action.type === DashboardActionsCreator.DASHBOARD_ACTION.DASHBOARD_COUNT_ERROR) {
    return { ...state, dashboardError: action.data, isLoading: false };
  }

  return state;
};

export default DashboardReducer;
