import React, { useEffect, useState } from "react";
import LoginHeader from "../../../NewUiComponents/LoginHeader";
import Card from "../../../NewUiComponents/Card";

import { Formik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import { SignInSchema } from "../../../Utilites/Schema";
import TextInput from "../../../NewUiComponents/TextInput";
import {
  requestLoginAction,
  setResetStateLogin,
} from "../../../Redux/LoginState/LoginActionsCreator";
import { toast } from "react-toastify";
import LOCAL_KEYS, { setToLocalStorage } from "../../../Utilites/LocalStorage";
import { appMessages } from "../../../Constants";
import { EyeIcon, EyeSlashIcon } from "@heroicons/react/24/outline";
import CustomButton from "../../../NewUiComponents/CustomButton";
import Loader from "../../../NewUiComponents/Loader";

const Login = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const LoginReducerState = useSelector((state) => state.LoginReducer);
  const { loginError, loginResponse, isLoading } = LoginReducerState;
  const [showPassword, setShowPassword] = useState(false);
  useEffect(() => {
    if (loginError && loginError.code === 403) {
      toast.dismiss();
      toast.error(appMessages.LOGIN_ERROR);
      dispatch(setResetStateLogin());
    }
  }, [loginError]);

  useEffect(() => {
    if (loginResponse !== null) {
      setToLocalStorage(LOCAL_KEYS.ACCESS_TOKEN, loginResponse.access_token);
      setToLocalStorage(LOCAL_KEYS.REFRESH_TOKEN, loginResponse.refresh_token);
      setToLocalStorage(LOCAL_KEYS.USER_DETAILS, loginResponse.userDetails);
      toast.dismiss();
      toast.success(appMessages.LOGIN_SUCCESS);
      dispatch(setResetStateLogin());
      navigate("/dashboard", { replace: true });
    }
  }, [loginResponse]);

  const goToForgotPassword = () => {
    navigate("/forgot-password");
  };

  return (
    <>
      <Loader isLoading={isLoading} />{" "}
      <div className="bg-default h-screen">
        <div className="bg-gradient-to-r from-secondary  to-primary">
          <LoginHeader />
          <div>
            <div className="text-center mt-6  mb-32">
              <p className="font-poppins text-2xl text-white my-4">Welcome!</p>
              <p className="font-poppins text-sm font-light text-lead text-white my-2">
                Please sign in with your credentials
              </p>
              <p className="font-poppins text-sm font-light text-lead text-white my-2">
                to continue.
              </p>
            </div>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              preserveAspectRatio="none"
              version="1.1"
              viewBox="0 0 2560 100"
              x="0"
              y="0"
            >
              <polygon
                className="fill-default"
                points="2560 0 2560 100 0 100"
              ></polygon>
            </svg>
          </div>
        </div>
        <div className="flex justify-center items-center relative">
          <Card
            className={
              "bg-white w-96 h-auto px-8 py-12 justify-center items-center absolute -top-32"
            }
          >
            <div className="">
              <p className="font-poppins text-primary text-xl font-medium text-center">
                Sign In
              </p>
            </div>
            <Formik
              initialValues={{ username: "", password: "" }}
              validationSchema={SignInSchema}
              onSubmit={(values, { setSubmitting }) => {
                setSubmitting(false);
                const credentials = {
                  username: values.username,
                  password: values.password,
                };
                dispatch(requestLoginAction(credentials));
              }}
            >
              {({
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
                isSubmitting,
              }) => (
                <form action="#" method="POST" onSubmit={handleSubmit}>
                  <>
                    <TextInput
                      id="username"
                      name="username"
                      type="username"
                      label={"User Name"}
                      placeHolder={"Enter your username"}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.username}
                    />
                    <span className="text-danger text-xs">
                      {errors.username && touched.username && errors.username}
                    </span>
                  </>
                  <>
                    <TextInput
                      id="password"
                      name="password"
                      type={showPassword ? "text" : "password"}
                      label={"Password"}
                      placeHolder={"Enter your password"}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.password}
                      icon={
                        !showPassword ? (
                          <EyeSlashIcon
                            className="w-4 h-4 text-gray-500 cursor-pointer"
                            onClick={() => setShowPassword(!showPassword)}
                          />
                        ) : (
                          <EyeIcon
                            className="w-4 h-4 text-gray-500 cursor-pointer"
                            onClick={() => setShowPassword(!showPassword)}
                          />
                        )
                      }
                    />
                    {errors && touched && (
                      <span className="text-danger text-xs">
                        {errors.password && touched.password && errors.password}
                      </span>
                    )}
                  </>
                  <div className="py-6">
                    <CustomButton
                      name="Sign In"
                      onClick={() => {}}
                      fullwidth
                      isDisabled={isSubmitting}
                    />
                  </div>
                  <p
                    className="font-poppins text-sm mt-8 text-right cursor-pointer text-primary"
                    onClick={goToForgotPassword}
                  >
                    Forgot password?
                  </p>
                </form>
              )}
            </Formik>
          </Card>
        </div>
      </div>
    </>
  );
};

export default Login;
