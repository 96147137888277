import React from "react";
import PropTypes from "prop-types";
import TextInput from "../TextInput";
import CustomButton from "../CustomButton";

const FilterComponent = ({ onFilter, onClear, filterText, title }) => {
  return (
    <div className="flex flex-row gap-2 mb-2 justify-center items-center">
      <div className="w-44">
        <TextInput
          id="filteredText"
          name="filteredText"
          type="text"
          label={""}
          placeHolder={"Filter by name"}
          onChange={onFilter}
          value={filterText}
        />
      </div>
      <div>
        <p
          className="font-poppins text-xs text-danger underline mt-10 cursor-pointer"
          onClick={onClear}
        >
          Clear search
        </p>
      </div>
    </div>
  );
};

FilterComponent.propTypes = {
  onFilter: PropTypes.func.isRequired,
  onClear: PropTypes.func.isRequired,
  filterText: PropTypes.string.isRequired,
  title: PropTypes.string,
};

export default FilterComponent;
