import React, { useEffect } from "react";
import { Formik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";

import { ChangePasswordSchema } from "../../../Utilites/Schema";
import TextInput from "../../../NewUiComponents/TextInput";
import {
  requestChangePasswordAction,
  setResetChangePasswordState,
} from "../../../Redux/ProfileState/ProfileActionCreator";
import Loader from "../../../NewUiComponents/Loader"; 
import withHoc from "../../../NewUiComponents/Layout";
import { appMessages } from "../../../Constants";
import Card from '../../../NewUiComponents/Card';
import CustomButton from "../../../NewUiComponents/CustomButton";


const ChangePassword = () => {
  const dispatch = useDispatch();

  const ChangePasswordReducer = useSelector((state) => state.ChangePasswordReducer);
  const {
    changePasswordError,
    changePasswordResponse,
    isChangePasswordLoading,
  } = ChangePasswordReducer;

  useEffect(() => {
    dispatch(setResetChangePasswordState());
  }, []);

  useEffect(() => {
    if (changePasswordError !== null) {
      toast.error(changePasswordError?.message);
      dispatch(setResetChangePasswordState());
    }
  }, [changePasswordError]);

  useEffect(() => {
    if (changePasswordResponse !== null) {
      toast.success(appMessages.CHANGE_PASSWORD_SUCCESS);
      dispatch(setResetChangePasswordState());
    }
  }, [changePasswordResponse]);

  return (
    <>
      <Loader isLoading={isChangePasswordLoading} />
      <div className="flex justify-center items-center my-10">
        <Card className="w-1/2 lg:w-1/2 xl:w-1/3 p-10 shadow-2xl">
          <Formik
            initialValues={{
              currentPassword: "",
              newPassword: "",
              confirmationPassword: "",
            }}
            validationSchema={ChangePasswordSchema}
            onSubmit={(values, { setSubmitting, resetForm }) => {
              const { currentPassword, newPassword, confirmationPassword } =
                values;
              const payLoad = {
                currentPassword,
                newPassword,
                confirmationPassword,
              };
              dispatch(requestChangePasswordAction(payLoad));
              setSubmitting(false);
              resetForm();
            }}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              isSubmitting,
            }) => (
              <form
                action="#"
                method="POST"
                onSubmit={handleSubmit}
              >
                <div>
                  <TextInput
                    id="currentPassword"
                    name="currentPassword"
                    type="password"
                    label={"Current Password"}
                    placeHolder={"Enter your current password"}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.currentPassword}
                  />
                  {errors && touched && (
                    <span className="text-danger text-xs">
                      {errors.currentPassword &&
                        touched.currentPassword &&
                        errors.currentPassword}
                    </span>
                  )}
                </div>
                <div>
                  <TextInput
                    id="newPassword"
                    name="newPassword"
                    type="password"
                    label={"New Password"}
                    placeHolder={"Enter your new password"}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.newPassword}
                  />
                  {errors && touched && (
                    <span className="text-danger text-xs">
                      {errors.newPassword &&
                        touched.newPassword &&
                        errors.newPassword}
                    </span>
                  )}
                </div>
                <div>
                  <TextInput
                    id="confirmationPassword"
                    name="confirmationPassword"
                    type="password"
                    label={"Confirm Password"}
                    placeHolder={"Re-enter your new password"}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.confirmationPassword}
                  />
                  {errors && touched && (
                    <span className="text-danger text-xs">
                      {errors.confirmationPassword &&
                        touched.confirmationPassword &&
                        errors.confirmationPassword}
                    </span>
                  )}
                </div>
                <div className="pt-4">
                  <CustomButton
                    name="Update"
                    onClick={() => {}}
                    fullwidth
                    isDisabled={isSubmitting}
                  />
                </div>
                
              </form>
            )}
          </Formik>
        </Card>
      </div>
    </>
  );
};

export default withHoc(ChangePassword);
