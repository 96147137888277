import React, { useState } from "react";
import Sidebar from "./SideBar";
import Header from "./Header";
import { useDispatch, useSelector } from "react-redux";
import { toggleMenubar } from "../../Redux/AppconifgStage/AppconfigActionCreator";
import { removeFromLocalStorage } from "../../Utilites/LocalStorage";
import { resetStateAction, setResetStateLogin } from "../../Redux/LoginState/LoginActionsCreator";
import { useNavigate } from "react-router-dom";
import Footer from "./Footer";
import MobileHeader from "./MobileHeader";
import { resetUsersListPagenationStateAction, setResetUserListState } from "../../Redux/UserState/UserActionCreator";
import { patientListPagenationReset, patientListReset } from "../../Redux/PatientState/PatientActionCreator";

const Hoc = (BaseComponent) => {
  return function EnhancedComponent(props) {
    const [showMobileMenu, setShowMobileMenu] = useState(false);
    const appConfigState = useSelector((state) => state.AppConfigReducer);
    const { expandMenuBar } = appConfigState;
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const handleToggleMenubar = () => {
      dispatch(toggleMenubar(!expandMenuBar));
    };

    const onLogout = () => {
      removeFromLocalStorage();
      dispatch(setResetStateLogin());
      dispatch(resetUsersListPagenationStateAction());
      dispatch(patientListPagenationReset());
      dispatch(resetStateAction());
      navigate("/login", { replace: true });
    };

    const handleShowMobileMenu = () => {
      setShowMobileMenu(!showMobileMenu);
    };

    return (
      <div className="flex flex-row bg-screenBg h-screen overflow-hidden">
        {expandMenuBar && (
          <div className="hidden lg:block w-1/6">
            <Sidebar handleLogout={onLogout} />
          </div>
        )}
        <div className={`${expandMenuBar ? "w-screen lg:w-5/6" : "w-screen"}`}>
          <Header
            onMenubarToggle={handleToggleMenubar}
            expandMenuBar={expandMenuBar}
            handleLogout={onLogout}
            onOpenMobileMenu={handleShowMobileMenu}
            showMobileMenu={showMobileMenu}
          />
          <BaseComponent {...props} />
          <Footer expandMenuBar={expandMenuBar} />
        </div>
      </div>
    );
  };
};

export default Hoc;
