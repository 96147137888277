import React, { useEffect, useState } from "react";
import LoginHeader from "../../../NewUiComponents/LoginHeader";
import Card from "../../../NewUiComponents/Card";

import { Formik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";

import { ResetPasswordSchema } from "../../../Utilites/Schema";
import TextInput from "../../../NewUiComponents/TextInput";
import {
  requestResetPasswordAction,
  setResetStateResetPassword,
} from "../../../Redux/LoginState/LoginActionsCreator";
import { toast } from "react-toastify";
import { appMessages } from "../../../Constants";
import { EyeIcon, EyeSlashIcon } from "@heroicons/react/24/outline";
import CustomButton from "../../../NewUiComponents/CustomButton";
import Loader from '../../../NewUiComponents/Loader';

const ResetPassword = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { fpToken } = useParams();

  const ResetPasswordState = useSelector((state) => state.ResetPasswordReducer);

  const { resetPasswordError, isLoading } = ResetPasswordState;
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPasswordPassword] = useState(false);

  useEffect(() => {
    clearStates();
    if (fpToken === "" || !fpToken || fpToken === undefined) {
      navigate("/forgot-password");
      toast.warning(appMessages.INVALID_TOKEN);
    }
  }, [fpToken]);

  useEffect(() => {
    if (resetPasswordError) {
      toast.error(appMessages.INVALID_TOKEN);
      clearStates();
      navigate("/forgot-password");

    }
  }, [resetPasswordError]);

  useEffect(() => {
    if (
      ResetPasswordState &&
      ResetPasswordState.resetPasswordResponse === "Password reset successful."
    ) {
      toast.success(appMessages.RESET_SUCCESSFULL);
      clearStates();
      navigate("/login");
    }
  }, [ResetPasswordState]);

  const clearStates = () => {
    dispatch(setResetStateResetPassword());
  }

  return (
    <>
      <Loader isLoading={isLoading} />
      <div className="bg-default h-screen">
        <div className="bg-gradient-to-r from-secondary  to-primary">
          <LoginHeader />
          <div>
            <div className="text-center mt-6  mb-32">
              <p className="font-poppins text-2xl text-white my-4">
                Reset Your Password
              </p>
              <p className="font-poppins text-sm font-light text-lead text-white my-2">
                Please enter your new password
              </p>
              <p className="font-poppins text-sm font-light text-lead text-white my-2">
                to continue.
              </p>
            </div>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              preserveAspectRatio="none"
              version="1.1"
              viewBox="0 0 2560 100"
              x="0"
              y="0"
            >
              <polygon
                className="fill-default"
                points="2560 0 2560 100 0 100"
              ></polygon>
            </svg>
          </div>
        </div>
        <div className="flex justify-center items-center relative">
          <Card
            className={
              "bg-white w-96 h-auto px-8 py-12 justify-center items-center absolute -top-32"
            }
          >
            <div className="">
              <p className="font-poppins text-primary text-xl font-medium text-center">
                Reset Password
              </p>
            </div>
            <Formik
              initialValues={{ confirm_password: "", password: "" }}
              validationSchema={ResetPasswordSchema}
              onSubmit={(values, { setSubmitting }) => {
                setSubmitting(false);
                dispatch(
                  requestResetPasswordAction({
                    newPassword: values.confirm_password,
                    token: fpToken,
                  })
                );
              }}
            >
              {({
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
                isSubmitting,
              }) => (
                <form action="#" method="POST" onSubmit={handleSubmit}>
                  <>
                    <TextInput
                      id="password"
                      name="password"
                      type={showPassword ? "text" : "password"}
                      label={"Password"}
                      placeHolder={"Enter your password"}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.password}
                      icon={
                        !showPassword ? (
                          <EyeSlashIcon
                            className="w-4 h-4 text-gray-500 cursor-pointer"
                            onClick={() => setShowPassword(!showPassword)}
                          />
                        ) : (
                          <EyeIcon
                            className="w-4 h-4 text-gray-500 cursor-pointer"
                            onClick={() => setShowPassword(!showPassword)}
                          />
                        )
                      }
                    />
                    {errors && touched && (
                      <span className="text-danger text-xs">
                        {errors.password && touched.password && errors.password}
                      </span>
                    )}
                  </>
                  <>
                    <TextInput
                      id="confirm_password"
                      name="confirm_password"
                      type={showConfirmPassword ? "text" : "password"}
                      label={"Confirm Password"}
                      placeHolder={"Re-enter your password"}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.confirm_password}
                      icon={
                        !showConfirmPassword ? (
                          <EyeSlashIcon
                            className="w-4 h-4 text-gray-500 cursor-pointer"
                            onClick={() =>
                              setShowConfirmPasswordPassword(
                                !showConfirmPassword
                              )
                            }
                          />
                        ) : (
                          <EyeIcon
                            className="w-4 h-4 text-gray-500 cursor-pointer"
                            onClick={() =>
                              setShowConfirmPasswordPassword(
                                !showConfirmPassword
                              )
                            }
                          />
                        )
                      }
                    />
                    {errors && touched && (
                      <span className="text-danger text-xs">
                        {errors.confirm_password &&
                          touched.confirm_password &&
                          errors.confirm_password}
                      </span>
                    )}
                  </>
                  <div className="py-6">
                    <CustomButton
                      name="Update Password"
                      fullwidth
                      isDisabled={isSubmitting}
                      type={"submit"}
                    />
                  </div>
                </form>
              )}
            </Formik>
          </Card>
        </div>
      </div>
    </>
  );
};

export default ResetPassword;
