export function transformArray(inputArray) {
    if (Array.isArray(inputArray)) {
      const items = inputArray.map((item,index) => {
        return typeof item === "object"
          ? item
          : { label: String(item), value: item, index };
      });
      return items;
    }
  }



export function filterArrayWithKey(inputArray, key, type) {
    if(key === 'active'){
      return type === "ALL" ?  inputArray?.filter((item) => item.active === 'Y') : inputArray?.filter((item) => item.active === 'Y' && item.role === type);
    } else if(key === 'inactive'){
      return type === "ALL" ?  inputArray?.filter((item) => item.active === 'N') : inputArray?.filter((item) => item.active === 'N' && item.role === type);
    } else {
      return inputArray;
    }
  }

  export function getFullKey(inputArray, key) {
    let outPut = inputArray.filter((item)=>{
      return item.value == key && item.value != undefined && item.title 
    });
    return outPut[0]
  }


  export function convertDateTime(inputDate) {
    let date = new Date(inputDate);
  
    // Adjust the month to start from 1 (January = 1)
    let day = String(date.getUTCDate() +1).padStart(2, '0');
    let month = String(date.getUTCMonth() + 1).padStart(2, '0');  // Adjusted month
    let year = date.getUTCFullYear();
    
    let fullDate = `${day}-${month}-${year}`;
  
    // Format the time in US Eastern Time Zone (EST/EDT) dynamically
    let time = date.toLocaleTimeString('en-US', {
      hour: '2-digit',
      minute: '2-digit',
      hour12: true,
      timeZone: 'America/New_York'  // Dynamically handles EST/EDT based on the date
    });
  
    return `${fullDate} ${time}`;
  }
  

export function convertDate(inputDate) {
  let date = new Date(inputDate);

  // Format the full date as "day-month-year" with two digits for day and month
  let day = String(date.getUTCDate()).padStart(2, '0');
  let month = date.toLocaleString('en-US', { month: 'short', timeZone: 'UTC' });
  let year = date.getUTCFullYear();

  let fullDate = `${day}-${month}`;

  let time = date.toLocaleTimeString('en-US', {
    hour: '2-digit',
    minute: '2-digit',
    hour12: true,
    timeZone: 'UTC'
  });
  return `${fullDate} ${time}`;
}

  


