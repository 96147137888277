export const PATIENT_ACTION = {
  ADD_PATIENT_STATE_RESET: "ADD_PATIENT_STATE_RESET",
  ADD_PATIENT_STATE_REQUEST: "ADD_PATIENT_STATE_REQUEST",
  ADD_PATIENT_STATE_ERROR: "ADD_PATIENT_STATE_ERROR",
  ADD_PATIENT_STATE_RESPONSE: "ADD_PATIENT_STATE_RESPONSE",

  PATIENT_LIST_RESET: "PATIENT_LIST_RESET",
  PATIENT_LIST_REQUEST: "PATIENT_LIST_REQUEST",
  PATIENT_LIST_ERROR: "PATIENT_LIST_ERROR",
  PATIENT_LIST_RESPONSE: "PATIENT_LIST_RESPONSE",

  PATIENTS_LIST_PAGENATION_RESET: "PATIENTS_LIST_PAGENATION_RESET",
  PATIENTS_LIST_PAGENATION_REQUEST: "PATIENTS_LIST_PAGENATION_REQUEST",
  PATIENTS_LIST_PAGENATION_ERROR: "PATIENTS_LIST_PAGENATION_ERROR",
  PATIENTS_LIST_PAGENATION_RESPONSE: "PATIENTS_LIST_PAGENATION_RESPONSE",

  UPDATE_PATIENT_STATE_RESET: "UPDATE_PATIENT_STATE_RESET",
  UPDATE_PATIENT_STATE_REQUEST: "UPDATE_PATIENT_STATE_REQUEST",
  UPDATE_PATIENT_STATE_ERROR: "UPDATE_PATIENT_STATE_ERROR",
  UPDATE_PATIENT_STATE_RESPONSE: "UPDATE_PATIENT_STATE_RESPONSE",


  GET_PATIENT_BY_ID_STATE_RESET: "GET_PATIENT_BY_ID_STATE_RESET",
  GET_PATIENT_BY_ID_STATE_REQUEST: "GET_PATIENT_BY_ID_STATE_REQUEST",
  GET_PATIENT_BY_ID_STATE_ERROR: "GET_PATIENT_BY_ID_STATE_ERROR",
  GET_PATIENT_BY_ID_STATE_RESPONSE: "GET_PATIENT_BY_ID_STATE_RESPONSE",



  LINK_DOCTOR_PATIENT_STATE_RESET: "LINK_DOCTOR_PATIENT_STATE_RESET",
  LINK_DOCTOR_PATIENT_STATE_REQUEST: "LINK_DOCTOR_PATIENT_STATE_REQUEST",
  LINK_DOCTOR_PATIENT_STATE_RESPONSE: "LINK_DOCTOR_PATIENT_STATE_RESPONSE",
  LINK_DOCTOR_PATIENT_STATE_ERROR: "LINK_DOCTOR_PATIENT_STATE_ERROR",

  LINK_DOCTOR_PATIENT_LIST_STATE_RESET: "LINK_DOCTOR_PATIENT_LIST_STATE_RESET",
  LINK_DOCTOR_PATIENT_LIST_STATE_REQUEST:
    "LINK_DOCTOR_PATIENT_LIST_STATE_REQUEST",
  LINK_DOCTOR_PATIENT_LIST_STATE_RESPONSE:
    "LINK_DOCTOR_PATIENT_LIST_STATE_RESPONSE",
  LINK_DOCTOR_PATIENT_LIST_STATE_ERROR: "LINK_DOCTOR_PATIENT_LIST_STATE_ERROR",

  LINK_DEVICE_PATIENT_STATE_RESET: "LINK_DEVICE_PATIENT_STATE_RESET",
  LINK_DEVICE_PATIENT_STATE_REQUEST: "LINK_DEVICE_PATIENT_STATE_REQUEST",
  LINK_DEVICE_PATIENT_STATE_RESPONSE: "LINK_DEVICE_PATIENT_STATE_RESPONSE",
  LINK_DEVICE_PATIENT_STATE_ERROR: "LINK_DEVICE_PATIENT_STATE_ERROR",

  LINK_DEVICE_PATIENT_LIST_STATE_RESET: "LINK_DEVICE_PATIENT_LIST_STATE_RESET",
  LINK_DEVICE_PATIENT_LIST_STATE_REQUEST:
    "LINK_DEVICE_PATIENT_LIST_STATE_REQUEST",
  LINK_DEVICE_PATIENT_LIST_STATE_RESPONSE:
    "LINK_DEVICE_PATIENT_LIST_STATE_RESPONSE",
  LINK_DEVICE_PATIENT_LIST_STATE_ERROR: "LINK_DEVICE_PATIENT_LIST_STATE_ERROR",
};

export const addPatientReset = () => ({
  type: PATIENT_ACTION.ADD_PATIENT_STATE_RESET,
  data: undefined,
});

export const addPatientRequest = (param) => ({
  type: PATIENT_ACTION.ADD_PATIENT_STATE_REQUEST,
  data: param,
});

export const addPatientResponse = (res) => ({
  type: PATIENT_ACTION.ADD_PATIENT_STATE_RESPONSE,
  data: res,
});

export const addPatientError = (err) => ({
  type: PATIENT_ACTION.ADD_PATIENT_STATE_ERROR,
  data: err,
});

export const patientListReset = () => ({
  type: PATIENT_ACTION.PATIENT_LIST_RESET,
  data: undefined,
});

export const patientListRequest = (param) => ({
  type: PATIENT_ACTION.PATIENT_LIST_REQUEST,
  data: param,
});

export const patientListResponse = (res) => ({
  type: PATIENT_ACTION.PATIENT_LIST_RESPONSE,
  data: res,
});

export const patientListError = (err) => ({
  type: PATIENT_ACTION.PATIENT_LIST_ERROR,
  data: err,
});

export const patientListPagenationReset = () => ({
  type: PATIENT_ACTION.PATIENTS_LIST_PAGENATION_RESET,
  data: undefined,
});

export const patientListPagenationRequest = (payload) => ({
  type: PATIENT_ACTION.PATIENTS_LIST_PAGENATION_REQUEST,
  data: payload,
});

export const patientListPagenationResponse = (res) => ({
  type: PATIENT_ACTION.PATIENTS_LIST_PAGENATION_RESPONSE,
  data: res,
});

export const patientListPagenationError = (err) => ({
  type: PATIENT_ACTION.PATIENTS_LIST_PAGENATION_ERROR,
  data: err,
});


export const getPatientByIdReset = () => ({
  type: PATIENT_ACTION.GET_PATIENT_BY_ID_STATE_RESET,
  data: undefined,
});

export const getPatientByIdRequest = (param) => ({
  type: PATIENT_ACTION.GET_PATIENT_BY_ID_STATE_REQUEST,
  data: param,
});

export const getPatientByIdResponse = (res) => ({
  type: PATIENT_ACTION.GET_PATIENT_BY_ID_STATE_RESPONSE,
  data: res,
});

export const getPatientByIdError = (err) => ({
  type: PATIENT_ACTION.GET_PATIENT_BY_ID_STATE_ERROR,
  data: err,
});





export const updatePatientReset = () => ({
  type: PATIENT_ACTION.UPDATE_PATIENT_STATE_RESET,
  data: undefined,
});

export const updatePatientRequest = (param) => ({
  type: PATIENT_ACTION.UPDATE_PATIENT_STATE_REQUEST,
  data: param,
});

export const updatePatientResponse = (res) => ({
  type: PATIENT_ACTION.UPDATE_PATIENT_STATE_RESPONSE,
  data: res,
});

export const updatePatientError = (err) => ({
  type: PATIENT_ACTION.UPDATE_PATIENT_STATE_ERROR,
  data: err,
});

export const linkDoctorPatientReset = () => ({
  type: PATIENT_ACTION.LINK_DOCTOR_PATIENT_STATE_RESET,
  data: undefined,
});

export const linkDoctorPatientRequest = (param) => ({
  type: PATIENT_ACTION.LINK_DOCTOR_PATIENT_STATE_REQUEST,
  data: param,
});

export const linkDoctorPatientResponse = (res) => ({
  type: PATIENT_ACTION.LINK_DOCTOR_PATIENT_STATE_RESPONSE,
  data: res,
});

export const linkDoctorPatientError = (err) => ({
  type: PATIENT_ACTION.LINK_DOCTOR_PATIENT_STATE_ERROR,
  data: err,
});

export const linkDoctorPatientListReset = () => ({
  type: PATIENT_ACTION.LINK_DOCTOR_PATIENT_LIST_STATE_RESET,
  data: undefined,
});

export const linkDoctorPatientListRequest = (param) => ({
  type: PATIENT_ACTION.LINK_DOCTOR_PATIENT_LIST_STATE_REQUEST,
  data: param,
});

export const linkDoctorPatientListResponse = (res) => ({
  type: PATIENT_ACTION.LINK_DOCTOR_PATIENT_LIST_STATE_RESPONSE,
  data: res,
});

export const linkDoctorPatientListError = (err) => ({
  type: PATIENT_ACTION.LINK_DOCTOR_PATIENT_LIST_STATE_ERROR,
  data: err,
});

export const linkDevicePatientReset = () => ({
  type: PATIENT_ACTION.LINK_DEVICE_PATIENT_STATE_RESET,
  data: undefined,
});

export const linkDevicePatientRequest = (param) => ({
  type: PATIENT_ACTION.LINK_DEVICE_PATIENT_STATE_REQUEST,
  data: param,
});

export const linkDevicePatientResponse = (res) => ({
  type: PATIENT_ACTION.LINK_DEVICE_PATIENT_STATE_RESPONSE,
  data: res,
});

export const linkDevicePatientError = (err) => ({
  type: PATIENT_ACTION.LINK_DEVICE_PATIENT_STATE_ERROR,
  data: err,
});

export const linkDevicePatientListReset = () => ({
  type: PATIENT_ACTION.LINK_DEVICE_PATIENT_LIST_STATE_RESET,
  data: undefined,
});

export const linkDevicePatientListRequest = (param) => ({
  type: PATIENT_ACTION.LINK_DEVICE_PATIENT_LIST_STATE_REQUEST,
  data: param,
});

export const linkDevicePatientListResponse = (res) => ({
  type: PATIENT_ACTION.LINK_DEVICE_PATIENT_LIST_STATE_RESPONSE,
  data: res,
});

export const linkDevicePatientListError = (err) => ({
  type: PATIENT_ACTION.LINK_DEVICE_PATIENT_LIST_STATE_ERROR,
  data: err,
});
