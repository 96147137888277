export const PROFILE_ACTION = {
  RESET_CHANGE_PASSWORD_STATE: "RESET_CHANGE_PASSWORD_STATE",
  CHANGE_PASSWORD_REQUEST: "CHANGE_PASSWORD_REQUEST",
  CHANGE_PASSWORD_ERROR: "CHANGE_PASSWORD_ERROR",
  CHANGE_PASSWORD_RESPONSE: "CHANGE_PASSWORD_RESPONSE",
};

export const setResetChangePasswordState = () => ({
  type: PROFILE_ACTION.RESET_CHANGE_PASSWORD_STATE,
  data: undefined,
});

export const requestChangePasswordAction = (params) => ({
  type: PROFILE_ACTION.CHANGE_PASSWORD_REQUEST,
  data: params,
});

export const responseChangePasswordAction = (res) => ({
  type: PROFILE_ACTION.CHANGE_PASSWORD_RESPONSE,
  data: res,
});

export const errorChangePasswordAction = (err) => ({
  type: PROFILE_ACTION.CHANGE_PASSWORD_ERROR,
  data: err,
});
