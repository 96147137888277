import React from "react";
import PropTypes from "prop-types";
const Cards = (props) => {
  return (
    <div
      className={`${props.className} bg-white border border-gray-light shadow-3xl rounded-3xl `}
    >
      {props.children}
    </div>
  );
};

Cards.propTypes = {
  children: PropTypes.any,
  className: PropTypes.string,
};

export default Cards;
