import React, { useEffect, useState } from "react";
import withHoc from "../../../NewUiComponents/Layout";
import Card from "../../../NewUiComponents/Card";
import LOCAL_KEYS, {
  parseLocalStorageJSON,
} from "../../../Utilites/LocalStorage";
import ProTypes from "prop-types";
import { converDateToReadableFormat } from "../../../Utilites/Wrapper";

const UserDetailItem = ({ title, value }) => {
  return (
    <div className="w-44">
      <p className="font-poppins text-sm font-bold py-2">{title} : </p>
      <p className="font-poppins text-xs px-2">{value}</p>
    </div>
  );
};

const ChangePassword = () => {
  const [userDetails, setUserDetails] = useState(null);
  useEffect(() => {
    const userData = parseLocalStorageJSON(LOCAL_KEYS.USER_DETAILS);
    setUserDetails(userData);
  }, []);

  return (
    <>
      <div className="flex justify-center items-center my-10">
        <Card className="w-11/12 p-6 shadow-2xl relative">
          <div className="w-28 h-28 bg-white shadow-xl rounded-3xl absolute top-40 left-[45%]">
            <img
              src={require("../../../Assets/Images/profile.png")}
              className="w-28 h-28 rounded-3xl border-4 border-gray-light"
            />
            <p className="font-poppins text-sm text-center my-2">{`${userDetails?.firstName}  ${userDetails?.lastName} `}</p>
            <p className="font-poppins text-xs text-center">{`${userDetails?.role} `}</p>
          </div>
          <div className="w-full h-48 bg-gradient-to-r from-primary via-secondary to-primary rounded-3xl" />
          <div className="my-36" />
          <div className="flex flex-row justify-center items-center gap-24 my-6">
            <UserDetailItem title={"Email"} value={`${userDetails?.email}`} />
            <UserDetailItem
              title={"Phone number"}
              value={`${userDetails?.phone}`}
            />
            <UserDetailItem
              title={"Gender"}
              value={`${userDetails?.gender === "M" ? "Male" : "Female"}`}
            />
          </div>
          <div className="flex flex-row justify-center items-center gap-24 my-6">
            <UserDetailItem
              title={"Address"}
              value={`${userDetails?.address ? userDetails?.address : "N/A"}`}
            />
            <UserDetailItem
              title={"Created at"}
              value={`${converDateToReadableFormat(userDetails?.created_date)}`}
            />
            <UserDetailItem
              title={"Updated at"}
              value={`${converDateToReadableFormat(userDetails?.updated_date)}`}
            />
          </div>
        </Card>
      </div>
    </>
  );
};

UserDetailItem.propTypes = {
  title: ProTypes.any,
  value: ProTypes.string,
};
export default withHoc(ChangePassword);
