import { call, put, takeLatest } from "@redux-saga/core/effects";
import {
  USER_ACTION,
  errorUsersListAction,
  errorUserTypesAction,
  responseUserTypesAction,
  responseUsersListAction,
  errorAddUserAction,
  responceAddUserAction,
  errorGetUserByIdAction,
  responceGetUserByIdAction,
  errorEditUserAction,
  responceEditUserAction,
  responceDoctorPatientsListAction,
  errorDoctorPatientsListAction,
  responseUsersListPagenationAction,
  errorUsersListPagenationAction,
} from "./UserActionCreator";
import UserService from "../../Services/RestApiManager/UserManager/UserService";

export function* getUserTypesRequest() {
  try {
    const response = yield call(UserService.shared.getUserTypesRequest);
    yield put(responseUserTypesAction(response));
  } catch (error) {
    yield put(errorUserTypesAction(error));
  }
}

export function* getUsersListRequest(payload) {
  try {
    const response = yield call(
      payload?.typeOfUser === "ADMIN"
      ? UserService.shared.getUserListRequest
        : UserService.shared.getUserListByStatusRequest,
      payload.data
    );
    yield put(responseUsersListAction(response));
  } catch (error) {
    yield put(errorUsersListAction(error));
  }
}

export function* addUsersRequest(payload) {
  try {
    const responce = yield call(
      UserService.shared.addUsersRequest,
      payload.data
    );
    yield put(responceAddUserAction(responce));
  } catch (error) {
    yield put(errorAddUserAction(error));
  }
}

export function* getUserByIdRequest(payload) {
  try {
    const response = yield call(
      UserService.shared.getUserByIdRequest,
      payload.data
    );
    yield put(responceGetUserByIdAction(response));
  } catch (error) {
    yield put(errorGetUserByIdAction(error));
  }
}

export function* editUserRequest(payload) {
  const { data, id } = payload;
  try {
    const responce = yield call(UserService.shared.editUserRequest, data, id);
      yield put(responceEditUserAction(responce));
  } catch (error) {
    yield put(errorEditUserAction(error));
  }
}

export function* getDoctorsPatientsListRequest(payload) {
  try {
    const response = yield call(
      UserService.shared.getDoctorPatientsListRequest,
      payload.data
    );
    yield put(responceDoctorPatientsListAction(response));
  } catch (error) {
    yield put(errorDoctorPatientsListAction(error));
  }
}

export function* getUsersListPagenationRequest(payload){
  try {
    const response = yield call(
      UserService.shared.getUserListPagenationRequest,
      payload.data
    );
    yield put(responseUsersListPagenationAction(response));
  } catch (error) {
    yield put(errorUsersListPagenationAction(error));
  }
}


export function* getUserTypesWatcherSaga() {
  yield takeLatest(USER_ACTION.USER_REQUEST, getUserTypesRequest);
}

export function* getUsersListWatcherSaga() {
  yield takeLatest(USER_ACTION.USERS_LIST_REQUEST, getUsersListRequest);
}

export function* addUsersWatcherSaga() {
  yield takeLatest(USER_ACTION.ADD_USER_REQUEST, addUsersRequest);
}

export function* getUserByIdWatcherSaga() {
  yield takeLatest(USER_ACTION.GET_USER_BY_ID_REQUEST, getUserByIdRequest);
}

export function* editUserByIdWatcherSaga() {
  yield takeLatest(USER_ACTION.EDIT_USER_BY_ID_REQUEST, editUserRequest);
}

export function* getDoctorsPatientsListWatcherSaga() {
  yield takeLatest(
    USER_ACTION.DOCTORS_PATIENTS_LIST_REQUEST,
    getDoctorsPatientsListRequest
  );
}

export function* getUsersListPagenationWatcherSaga(){
  yield takeLatest(USER_ACTION.USERS_LIST_PAGENATION_REQUEST, getUsersListPagenationRequest);
}