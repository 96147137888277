import React from "react";
import zipcodes from "zipcodes";
import PropTypes from "prop-types";
import { Formik } from "formik";

import TextInput from "../../../../NewUiComponents/TextInput";
import { PatientSchema } from "../../../../Utilites/Schema";
import PhoneInputComponent from "../../../../NewUiComponents/PhoneNumber";
import CustomRadio from "../../../../NewUiComponents/Radio";
import { appData } from "../../../../Constants";
import CustomButton from "../../../../NewUiComponents/CustomButton";

const PatientsForm = (props) => {
  const { buttonName, formSubmit, initialValues, viewPatient, editPatient, addPatient } =
    props;
  return (
    <div className="justify-center">
      <Formik
        initialValues={initialValues} 
        validationSchema={PatientSchema}
        onSubmit={(values, { setSubmitting }) => {
          setSubmitting(false);
          const formValues = {
            patient_name: values.patient_name,
            fname: values.fname,
            lname: values.lname,
            phone: values.phone,
            email: values.email,
            active: values.active,
            gender: values.gender,
            address: {
              addressLine1: values.address.addressLine1,
              addressLine2: values.address.addressLine2,
              city: values.address.city,
              state: values.address.state,
              zip: values.address.zip,
              country: values.address.country,
            },
          };
          formSubmit(formValues);
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
        }) => (
          <form
            method="POST"
            onSubmit={handleSubmit}
            className="w-full max-h-[85vh] overflow-y-scroll space-y-1"
          >
            <div>
              <TextInput
                name="patient_name"
                placeHolder="Patient Name"
                label="Patient Name"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.patient_name}
                disabled={viewPatient}
              />
              <span className="text-danger text-sm">
                {errors.patient_name &&
                  touched.patient_name &&
                  errors.patient_name}
              </span>
            </div>

            <div className="lg:flex lg:space-x-4">
              <div className="lg:w-1/2">
                <TextInput
                  name="fname"
                  placeHolder="First name"
                  label="First Name"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.fname}
                  disabled={viewPatient || editPatient}
                />
                <span className="text-danger text-sm">
                  {errors.fname && touched.fname && errors.fname}
                </span>
              </div>
              <div className="lg:w-1/2">
                <TextInput
                  name={"lname"}
                  placeHolder="Last name"
                  label="Last Name"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.lname}
                  disabled={viewPatient || editPatient}
                />
              </div>
            </div>

            <div className="lg:flex lg:items-center lg:space-x-6">
            <div className="lg:w-1/2 lg:mt-4">
                <label className="block text-xs my-1 font-poppins">
                  Phone Number
                </label>
                <PhoneInputComponent
                  name={"phone"}
                  label="Phone Number"
                  onChange={(phone) => {
                    handleChange({
                      target: {
                        name: "phone",
                        value: phone,
                      },
                    });
                  }}
                  value={values.phone}
                  disabled={viewPatient}
                />
                <span className="text-danger text-sm">
                  {errors.phone && touched.phone && errors.phone}
                </span>
              </div>
              <div className="lg:w-1/2">
                <TextInput
                  name={"email"}
                  placeHolder="example@gmail.com"
                  label="Email"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.email}
                  disabled={viewPatient}
                />
                <span className="text-danger text-sm">
                  {errors.email && touched.email && errors.email}
                </span>
              </div>
            </div>

            <div className="lg:flex lg:space-x-4 mt-4">
              <div className="lg:w-1/2">
                <CustomRadio
                  data={appData.gender}
                  name={"gender"}
                  label={"Gender"}
                  disabled={viewPatient || editPatient}
                />
                <span className="text-danger text-sm">
                  {errors.gender && touched.gender && errors.gender}
                </span>
              </div>
              <div className="lg:w-1/2">
                <CustomRadio
                  data={appData.status}
                  name={"active"}
                  label={"Active"}
                  disabled={viewPatient || addPatient }
                />
                <span className="text-danger text-sm">
                  {errors.active && touched.active && errors.active}
                </span>
              </div>
            </div>

            <div>
              <TextInput
                placeHolder="Street adress line"
                label="Address"
                value={values.address.addressLine1}
                name={"address.addressLine1"}
                onChange={handleChange}
                onBlur={handleBlur}
                disabled={viewPatient}
              />
            </div>

            <div>
              <TextInput
                placeHolder="Street adress line 2"
                label=""
                value={values.address.addressLine2}
                name={"address.addressLine2"}
                onChange={handleChange}
                onBlur={handleBlur}
                disabled={viewPatient}
              />
            </div>

            <div className="lg:flex lg:space-x-6">
              <div className="lg:w-1/2">
                <TextInput
                  placeHolder=""
                  label="Postal / Zip Code"
                  onChange={(code) => {
                    handleChange(code);
                    // Lookup zip code information and update the state
                    const lookupResult = zipcodes.lookup(code.target.value);
                    handleChange({
                      target: {
                        name: "address",
                        value: {
                          ...values.address,
                          zip: code.target.value,
                          city: lookupResult?.city || "",
                          state: lookupResult?.state || "",
                          country: lookupResult?.country || "",
                        },
                      },
                    });
                  }}
                  onBlur={handleBlur}
                  value={values.address?.zip}
                  name={"zip"}
                  disabled={viewPatient}
                />
                <span className="text-danger text-sm">
                  {errors.address && touched.address && errors.address.zip}
                </span>
              </div>
              <div className="lg:w-1/2">
                <TextInput
                  placeHolder="select country"
                  label="Country"
                  // onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.address?.country}
                  name={"country"}
                  disabled={true}
                />
              </div>
            </div>

            <div className="lg:flex lg:space-x-6">
              <div className="lg:w-1/2">
                <TextInput
                  placeHolder="select state"
                  label="State"
                  // onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.address?.state}
                  name={"state"}
                  disabled={true}
                />
              </div>
              <div className="lg:w-1/2">
                <TextInput
                  placeHolder="clty"
                  label="City"
                  // onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.address?.city}
                  name={"city"}
                  disabled={true}
                />
              </div>
            </div>
            <div className="pt-4"></div>
            {!viewPatient && (
              <CustomButton
                isDisabled={isSubmitting}
                name={buttonName}
                type={"submit"}
              />
            )}
          </form>
        )}
      </Formik>
    </div>
  );
};
PatientsForm.propTypes = {
  title: PropTypes.string,
  editPatient: PropTypes.bool,
  buttonName: PropTypes.string.isRequired,
  formSubmit: PropTypes.func.isRequired,
  viewPatient: PropTypes.bool,
  addPatient: PropTypes.bool,
  initialValues: PropTypes.shape({
    fname: PropTypes.string,
    lname: PropTypes.string,
    userName: PropTypes.string,
    email: PropTypes.string,
    password: PropTypes.string,
    phone: PropTypes.string,
    role: PropTypes.string,
    active: PropTypes.string,
    startDate: PropTypes.string,
    endDate: PropTypes.string,
    address: PropTypes.shape({
      addressLine1: PropTypes.string,
      addressLine2: PropTypes.string,
      city: PropTypes.string,
      state: PropTypes.string,
      zip: PropTypes.string,
      country: PropTypes.string,
    }),
    gender: PropTypes.string,
  }),
};

export default PatientsForm;

// Multi Select

// <div className="lg:flex lg:space-x-6">
//               <div className="lg:w-1/2">
//                 <CustomSelectPicker
//                   data={doctorsList}
//                   name={"assignedDoctors"}
//                   placeHolder={"Select Assigned Doctors"}
//                   isMulti={true}
//                   label={"Doctor(s) Assigned"}
//                 />
//               </div>

//               <div className="lg:w-1/2">
//                 <CustomSelectPicker
//                   data={devicesList}
//                   name={"assignedDevices"}
//                   placeHolder={"Select Assigned Devices"}
//                   isMulti={true}
//                   label={"Devices(s) Assigned"}
//                 />
//               </div>
//             </div>
