import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import CustomTable from "../../../../../NewUiComponents/Table";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import Cards from "../../../../../NewUiComponents/Card";
import { mockData } from "../../AdminDashboard/mockData";
import { getDoctorPatientsListRequestAction, getUserByIdRequestAction, resetDoctorPatientsListStateAction } from "../../../../../Redux/UserState/UserActionCreator";
import { appData, appMessages } from "../../../../../Constants";
import { filterArrayWithKey } from "../../../../../Utilites/Helpers";
import Hoc from "../../../../../NewUiComponents/Layout";
import Loader from "../../../../../NewUiComponents/Loader";
import Card from "../../../../../NewUiComponents/Card"

const DoctorView = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const DoctorPatientListState = useSelector(
    (state) => state.GetDoctorPatientsListReducer
  );
  const DoctorDetails = useSelector((state) => state.GetUserByIdReducer);
  console.log(DoctorDetails.userDetails?.firstName
    
    ,"DoctorDetails");
  
  let { doctorId } = useParams();
  const [activeFlag, setActiveFlag] = useState(appData.listFlag[0]);

  const handleRowClickFunction = (row) => {
    goToPatientAssociations(row.id);
  };

  const goToPatientAssociations = (patientId) => {
    navigate("/patient-associations/" + patientId);
  };

  useEffect(() => {
    dispatch(resetDoctorPatientsListStateAction());
    if (doctorId) {
      dispatch(getUserByIdRequestAction(doctorId));
      dispatch(getDoctorPatientsListRequestAction(doctorId));
    }
  }, []);

  useEffect(() => {
    if (
      DoctorPatientListState &&
      !DoctorPatientListState?.isLoading &&
      DoctorPatientListState?.isError
    ) {
      toast.dismiss();
      toast.error(appMessages.PATIENTS_LIST_ERROR);
    }
  }, [DoctorPatientListState]);

  const columns = [
    {
      name: "Patient Id",
      selector: (row) => (
        <div className="flex flex-row gap-2">
          <span
            className="text-primary underline cursor-pointer"
            onClick={() => goToPatientAssociations(row.id || row.patient_id)}
          >
            #{row.id || row.patient_id}
          </span>
        </div>
      ),
    },
    {
      name: "Patient name",
      selector: (row) => row.patient_name,
      sortable: true,
    },
    {
      name: "Email",
      selector: (row) => row.email,
      sortable: true,
    },
    {
      name: "Phone Number",
      selector: (row) => row.phone,
      sortable: true,
    },
    {
      name: "Gender",
      selector: (row) =>
        row.gender === "M" || row.gender === "m" ? "Male" : "Female",
      sortable: true,
    },
  ];
  return (
    <div className="container-fluid px-4 overflow-y-scroll h-4/5">
        <Loader 
          isLoading={DoctorPatientListState?.isLoading || DoctorDetails?.isUserDetailsLoading
          }
        />

<div className="w-1/3 lg:1/3 xl:1/3 my-4">
            <Card className={"rounded-2xl shadow-lg p-5"}>
              <div className="flex items-center gap-4 my-1">
                <img
                  src={require("../../../../../Assets/Images/profile.png")}
                  className="w-20 h-20 rounded-3xl border-4 border-gray-light"
                />
                <div className="">
                  <p className="font-poppins text-sm text-center font-bold my-1">{`${DoctorDetails.userDetails?.firstName} ${DoctorDetails.userDetails?.lastName}`}</p>
                  <p className="font-poppins text-sm text-center font-bold my-1">{`${DoctorDetails.userDetails?.role}`}</p>
                </div>
              </div>
              <p className="font-poppins font-bold text-sm lg:text-md xl:text-md my-2 my-1">
                Contact Details
              </p>
              <div className="flex flex-row gap-2 items-center my-3">
                <svg viewBox="0 0 1024 1024" fill="#1a4490" className="size-5">
                  <path d="M744 62H280c-35.3 0-64 28.7-64 64v768c0 35.3 28.7 64 64 64h464c35.3 0 64-28.7 64-64V126c0-35.3-28.7-64-64-64zm-8 824H288V134h448v752zM472 784a40 40 0 1080 0 40 40 0 10-80 0z" />
                </svg>
                <p className="font-poppins text-sm">
                  {DoctorDetails.userDetails?.phone}
                </p>
              </div>
              <div className="flex flex-row gap-2 items-center my-3">
                <svg viewBox="0 0 24 24" fill="#1a4490" className="size-5">
                  <path d="M22 6c0-1.1-.9-2-2-2H4c-1.1 0-2 .9-2 2v12c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V6m-2 0l-8 5-8-5h16m0 12H4V8l8 5 8-5v10z" />
                </svg>
                <p className="font-poppins text-sm">
                  {DoctorDetails.userDetails?.email}
                </p>
              </div>
              <div className="flex flex-row gap-2 items-center my-3">
                <svg viewBox="0 0 1000 1000" fill="#1a4490" className="size-5">
                  <path d="M426 50c13.333 0 20 6.667 20 20v860c0 13.333-6.667 20-20 20h-46c-13.333 0-20-6.667-20-20V490H184c-10.667 0-20-2-28-6-8-1.333-16.667-5.333-26-12L10 390c-6.667-4-10-9.333-10-16s3.333-12 10-16l120-82c9.333-6.667 18-10.667 26-12 5.333-2.667 14.667-4 28-4h176V70c0-13.333 6.667-20 20-20h46m564 208c6.667 4 10 9.333 10 16s-3.333 12-10 16l-118 82c-14.667 8-23.333 12-26 12-9.333 4-18.667 6-28 6H516l-40-230h342c12 0 21.333 1.333 28 4 6.667 2.667 15.333 6.667 26 12l118 82" />
                </svg>
                <p className="font-poppins text-sm">
                  {Object.values(
                    DoctorDetails.userDetails?.address || {}
                  ).join(", ")}
                </p>
              </div>
            </Card>
          </div>

         <div className="bg-white rounded-2xl shadow-2xl p-3 my-5">
        <p className="font-poppins text-md font-medium">Patient List</p>
        {!DoctorPatientListState?.isLoading && (
          <CustomTable
            columns={columns}
            data={
              filterArrayWithKey(
                DoctorPatientListState?.doctorsPatientsList,
                activeFlag.value
              ) || []
            }
            pagination={true}
            filterKey={"patient_name"}
            filteredList={true}
            activeFlag={activeFlag}
            setActiveFlag={setActiveFlag}
            filterListData={appData.listFlag}
            activeFlagRequired={false}
            entireRowClick
            customRowClickFunction={handleRowClickFunction}
            title={"Patients List"}
          />
        )}
      </div>
    </div>
     
  );
};

export default Hoc(DoctorView);
