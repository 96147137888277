import React from "react";
import { Routes, Route } from "react-router-dom";

import Login from "../Pages/Auth/Login";
import ForgotPassword from "../Pages/Auth/ForgotPassword";
import Dashboard from "../Pages/Home/Dashboard";
import Users from "../Pages/Home/Users";
import Patients from "../Pages/Home/Patients";
import ChangePassword from "../Pages/Home/ChangePassword";
import PrivateRoutes from "./PrivateRoutes";
import PublicRoutes from "./PublicRoutes";
import NotFound from '../Pages/Common/NotFound';
import PatientDetails from '../Pages/Home/Patients/Details';
import Devices from "../Pages/Home/Devices";
import Profile from "../Pages/Home/Profile";
import Reports from "../Pages/Home/Reports";
import ResetPassword from "../Pages/Auth/ResetPassword";
import NewUserResetPassword from "../Pages/Auth/NewUserResetPassword";
import DoctorView from "../Pages/Home/Dashboard/DoctorDashboard/DoctorView";

const AppNavigation = () => {
  return (
    <Routes>
      <Route exact path="/" element={<PrivateRoutes />}>
          <Route exact path="/dashboard" element={<Dashboard />} />
          <Route exact path="/users" element={<Users />} />
          <Route exact path="/change-password" element={<ChangePassword />} />
          <Route exact path="/patients" element={<Patients />} />
          <Route exact path="/patient-associations/:patientId" element={<PatientDetails />} />
          <Route exact path="/doctors-associations/:doctorId" element={<DoctorView />} />
          <Route exact path="/devices" element={<Devices/>} />
          <Route exact path="/profile" element={<Profile/>} />
          <Route exact path="/reports" element={<Reports/>} />
      </Route>
      <Route element={<PublicRoutes />}>
          <Route element={<Login />} path="/login" />
          <Route element={<ForgotPassword />} path="/forgot-password" />
          <Route element={<ResetPassword />} path="/reset-password/:fpToken" />
          <Route element={<ResetPassword />} path="/reset-password" />
          <Route element={<NewUserResetPassword />} path="/verify-email/:token" />
          <Route element={<NewUserResetPassword />} path="/verify-email/" />
      </Route>
      <Route exact path="*" element={<NotFound/>} />
    </Routes>
  );
};

export default AppNavigation;
