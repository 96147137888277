import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import {
  addUserRequestAction,
  editUserbyIdRequestAction,
  getUserByIdRequestAction,
  requestUserTypesAction,
  requestUsersListAction,
  requestUsersListPagenationAction,
  resetActiveFlagAction,
  resetGetUserByIdStateAction,
  resetUpdateUserStateAction,
  resetUserStateAction,
  resetUserTypeStateAction,
  resetUsersListPagenationStateAction,
  setActiveFlagAction,
  setResetUserListState,
  setUserTypeRequestAction,
} from "../../../Redux/UserState/UserActionCreator";
import LOCAL_KEYS, {
  parseLocalStorageJSON,
} from "../../../Utilites/LocalStorage";
import ModalComponent from "../../../NewUiComponents/ModalComponent";
import { appData, appMessages } from "../../../Constants";
import { filterArrayWithKey } from "../../../Utilites/Helpers";
import CustomButton from "../../../NewUiComponents/CustomButton";
import withHoc from "../../../NewUiComponents/Layout";
import CustomTable from "../../../NewUiComponents/Table";
import UserDetailsForm from "./UserDetailsForm";
import ActionIcons from "../../../NewUiComponents/Table/ActionIcons";
import Loader from "../../../NewUiComponents/Loader";
import { useLocation, useNavigate } from "react-router-dom";
import CustomToggle from "../../../NewUiComponents/Toggle";

const Users = () => {
  const typeOfUser = parseLocalStorageJSON(LOCAL_KEYS.USER_DETAILS).role;
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const { userDetails, isUserDetailsLoading } = useSelector(
    (state) => state.GetUserByIdReducer
  );
  const { isUsersListLoading, usersListResponce, usersListError } = useSelector((state) => state.UsersListReducer);
  const UsersListPagenationReducer = useSelector((state) => state.UsersListPagenationReducer);
  const AddUserReducer = useSelector((state) => state.AddUserReducer);
  const UpdateUserReducer = useSelector((state) => state.UpdateUserReducer);
  const DefaultUserState = useSelector((state) => state.DefaultUserStaeReducer);
  const ActiveFlagReducer = useSelector((state) => state.ActiveFlagReducer);

  const initialValues = {
    userName: "",
    email: "",
    // password: "",
    firstName: "",
    lastName: "",
    gender: "",
    phone: "",
    active: "N",
    role: "",
    address: {
      addressLine1: "",
      addressLine2: "",
      city: "",
      state: "",
      zip: "",
      country: "",
    },
  };

  const [formView, setFormView] = useState({
    isOpen: false,
    title: "view",
    label: "view",
  });

  const addOrUpdateUser = (formData, view, id) => {
    if (view === "addUser") {
      dispatch(addUserRequestAction(formData));
    } else {
      dispatch(editUserbyIdRequestAction(formData, id));
    }
  };

  const closeModal = () => {
    setFormView({ isOpen: false, title: "view", label: "view" });
    dispatch(resetGetUserByIdStateAction());
  };

  const addUser = () => {
    setFormView({ isOpen: true, title: "addUser", label: "Add New User" });
  };

  const additionalDropDownFunction = (option) => {
    dispatch(setUserTypeRequestAction(option));
  };

  const setActiveFlagFunction =(option)=>{
    dispatch(setActiveFlagAction(option));
  }

  const updateUsersStatus = (row) => {
    dispatch(resetUpdateUserStateAction());
    dispatch(
      editUserbyIdRequestAction(
        { active: row.active === "Y" ? "N" : "Y" },
        row.id
      )
    );
  };

  const customPageChangeFunction = (pageNo) => {
    dispatch(
      requestUsersListPagenationAction({
        page: pageNo - 1,
        size: UsersListPagenationReducer.size,
        status: ActiveFlagReducer.userStatus?.value,
        typeOfUser,
        role: DefaultUserState?.userType?.value
      })
    );
  };

  const customPageSizeChangeFunction = (size) => {
    dispatch(
      requestUsersListPagenationAction({
        page: 0,
        size: size,
        status: ActiveFlagReducer.userStatus?.value,
        typeOfUser,
        role: DefaultUserState?.userType?.value
      })
    );
  };

 

  const clickHandler = (row, action) => {
    dispatch(getUserByIdRequestAction(row?.id));
    if (action === "view") {
      // api logic should be added
      setFormView({
        isOpen: true,
        title: "view",
        label: "View User",
        id: row.id,
      });
    } else {
      setFormView({
        isOpen: true,
        title: "editUser",
        label: "Edit User",
        id: row.id,
      });
    }
  };

  const goToDoctorsAssociations = (DoctorId) => {
    navigate("/doctors-associations/" + DoctorId);
  };

  const columns = [
    {
      name: "S no",
      selector: (row) => row.id,
    },
    {
      name: "Name",
      selector: (row) => `${row.firstname} ${row.lastname}`,
      sortable: true,
    },
    {
      name: "Role",
      selector: (row) => row.role,
      sortable: true,
    },
    {
      name: "Email",
      selector: (row) => row.email,
      sortable: true,
    },
    {
      name: "Phone Number",
      selector: (row) => row.phone,
      sortable: true,
    },
    {
      name: "Status",
      selector: (row) => (
        <CustomToggle
          isChecked={row.active === "Y" ? true : false}
          handleToggleChange={() => updateUsersStatus(row)}
        />
      ),
    },
    {
      name: "Action",
      cell: (row) => (
        <ActionIcons 
          additionalIcon={row.role === "DOCTOR"}
          typeOfUser={typeOfUser}
          onEditClicked={() => {
            clickHandler(row, "edit");
          }}
          onViewClicked={() => {
            clickHandler(row, "view");
          }}
          additionalIconClicked={() => {
            goToDoctorsAssociations(row?.id);
          }}
        />
      ),
    },
  ];

  useEffect(() => {
    dispatch(resetUserStateAction());
    dispatch(setResetUserListState());
    dispatch(requestUserTypesAction());
    dispatch(resetActiveFlagAction());
    dispatch(resetUsersListPagenationStateAction());
    // dispatch(requestUsersListAction(activeFlag.value, typeOfUser));
    dispatch(
      requestUsersListPagenationAction({
        page: UsersListPagenationReducer?.page,
        size: UsersListPagenationReducer?.size,
        status: ActiveFlagReducer.userStatus?.value,
        typeOfUser,
        role: DefaultUserState?.userType?.value
      })
    );
  }, []);

  useEffect(()=>{
    dispatch(
      requestUsersListPagenationAction({
        page: UsersListPagenationReducer?.page,
        size: UsersListPagenationReducer?.size,
        status: ActiveFlagReducer.userStatus?.value,
        role: DefaultUserState?.userType?.value,
        typeOfUser,
      })
    );
  },[DefaultUserState?.userType, ActiveFlagReducer.userStatus]);


  useEffect(() => {
    if (
      !isUsersListLoading &&
      usersListError &&
      !usersListResponce?.usersList
    ) {
      toast.dismiss();
      toast.error(appMessages.USERS_LIST_ERROR);
    }
  }, [usersListResponce, usersListError]);

  useEffect(() => {
    if (
      !UsersListPagenationReducer?.isLoading &&
      UsersListPagenationReducer?.isError 
    ) {
      toast.dismiss();
      toast.error(appMessages.USERS_LIST_ERROR);
    }
  }, [UsersListPagenationReducer?.usersList, UsersListPagenationReducer?.isError]);

  useEffect(() => {
    if (AddUserReducer && AddUserReducer?.userDetailsResponce) {
      setFormView({ isOpen: false, title: "view", label: "view" });
      toast.dismiss();
      toast.success(appMessages.ADD_USER_SUCCESS);
      // dispatch(requestUsersListAction(activeFlag.value, typeOfUser));
      dispatch(
        requestUsersListPagenationAction({
          page: 0,
          size: 5,
          status: ActiveFlagReducer.userStatus?.value,
          typeOfUser,
          role: DefaultUserState?.userType?.value
        })
      );
      dispatch(resetUserStateAction());
    }
  }, [AddUserReducer]);

  useEffect(() => {
    if (AddUserReducer && AddUserReducer?.isError) {
      setFormView({ isOpen: false, title: "view", label: "view" });
      toast.dismiss();
      toast.error(appMessages.ADD_USER_ERROR);
      dispatch(resetUserStateAction());
    }
  }, [AddUserReducer]);

  useEffect(() => {
    if (UpdateUserReducer && UpdateUserReducer?.updateUserDetailsResponce) {
      setFormView({ isOpen: false, title: "view", label: "view" });
      toast.dismiss();
      toast.success(appMessages.UPDATE_USER_SUCCESSFULLY);
      // dispatch(requestUsersListAction(activeFlag.value, typeOfUser));
      dispatch(
        requestUsersListPagenationAction({
          page: UsersListPagenationReducer?.page,
          size: UsersListPagenationReducer?.size,
          status: ActiveFlagReducer.userStatus?.value,
          typeOfUser,
          role: DefaultUserState?.userType?.value
        })
      );
      dispatch(resetUpdateUserStateAction());
    }
  }, [UpdateUserReducer]);

  useEffect(() => {
    if (UpdateUserReducer && UpdateUserReducer?.isError) {
      setFormView({ isOpen: false, title: "view", label: "view" });
      toast.dismiss();
      toast.error(appMessages.UPDATE_USER_ERROR);
      dispatch(resetUpdateUserStateAction());
    }
  }, [UpdateUserReducer]);

  return (
    <div className="container-fluid px-4 overflow-y-scroll h-4/5">
      <Loader
        isLoading={
          isUserDetailsLoading ||
          isUsersListLoading ||
          UpdateUserReducer.isLoading ||
          UsersListPagenationReducer.isLoading
        }
      />
      {!isUserDetailsLoading && (
        <ModalComponent
          onClose={closeModal}
          title={formView.label}
          show={formView.isOpen}
        >
          <UserDetailsForm
            buttonName={
              formView.title === "addUser"
                ? "Submit"
                : formView.title === "editUser"
                ? "Update"
                : ""
            }
            view={formView.title}
            id={formView.title === "editUser" && formView?.id}
            formSubmit={addOrUpdateUser}
            initialValues={
              userDetails &&
              (formView.title === "editUser" || formView.title === "view")
                ? userDetails
                : initialValues
            }
          />
        </ModalComponent>
      )}
      <div className="flex flex-row justify-end align-center m-4">
        {(typeOfUser === "ADMIN" || typeOfUser === "HOSPITAL_ADMIN") && (
          <CustomButton
            name=""
            icon={() => (
              <svg fill="none" viewBox="0 0 24 24" className="size-6">
                <path
                  fill="#fff"
                  d="M12 4a1 1 0 00-1 1v6H5a1 1 0 100 2h6v6a1 1 0 102 0v-6h6a1 1 0 100-2h-6V5a1 1 0 00-1-1z"
                />
              </svg>
            )}
            onClick={addUser}
            fullwidth={false}
            isDisabled={false}
            rounded={true}
          />
        )}
      </div>
      <div className="bg-white rounded-2xl shadow-2xl p-3 my-5">
        {!isUsersListLoading && (
          <CustomTable
            columns={columns}
            data={UsersListPagenationReducer?.usersList?.content || []}
            filterListData={appData?.listFlag}
            pagination={true}
            enableSearch={true}
            filterKey={["firstname", "lastname"]}
            filteredList={true}
            activeFlag={ActiveFlagReducer?.userStatus}
            setActiveFlag={setActiveFlagFunction}
            additionalDropDown
            dropDownDefaultValue={DefaultUserState?.userType}
            additionalDropDownFunction={additionalDropDownFunction}
            serverPageNation
            customPageChange={customPageChangeFunction}
            customPageSizeChangeFunction={customPageSizeChangeFunction}
            paginationTotalRows={
              UsersListPagenationReducer?.usersList?.totalElements || 0
            }
          />
        )}
      </div>
    </div>
  );
};

export default withHoc(Users);
