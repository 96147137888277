export const VENDOR_ACTION = {
    // device list actions
    RESET_VENDOR_LIST_STATE: "RESET_VENDOR_LIST_STATE",
    VENDOR_LIST_REQUEST: "VENDOR_LIST_REQUEST",
    VENDOR_LIST_RESPONCE: "VENDOR_LIST_RESPONCE",
    VENDOR_LIST_ERROR: "VENDOR_LIST_ERROR"

  };

  export const setResetVendorListState = () => ({
    type: VENDOR_ACTION.RESET_VENDOR_LIST_STATE,
    data: undefined,
  });
  
  export const requestVendorListAction = () => ({
    type: VENDOR_ACTION.VENDOR_LIST_REQUEST,
    data: undefined
  });
  
  export const responseVendorListAction = (res) => ({
    type: VENDOR_ACTION.VENDOR_LIST_RESPONCE,
    data: res,
  });
  
  export const errorVendorListAction = (err) => ({
    type: VENDOR_ACTION.VENDOR_LIST_ERROR,
    data: err,
  });