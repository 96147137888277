import * as DeviceActionCreator from "./DeviceActionCreator";

export function deviceTypesListState() {
  return {
    isDeviceTypesLoading: false,
    deviceTypes: null,
    deviceError: null,
  };
}

export const DeviceTypesListReducer = (state, action) => {
  if (!state || action.type === DeviceActionCreator.DEVICE_ACTION.RESET_DEVICE_TYPES_LIST_STATE) {
    return { ...state, ...deviceTypesListState() };
  }

  if (action.type === DeviceActionCreator.DEVICE_ACTION.DEVICE_TYPES_LIST_REQUEST) {
    return { ...state, isDeviceTypesLoading: true };
  }

  if (action.type === DeviceActionCreator.DEVICE_ACTION.DEVICE_TYPES_LIST_RESPONSE) {
    return { ...state, isDeviceTypesLoading: false, deviceTypes: action.data };
  }

  if (action.type === DeviceActionCreator.DEVICE_ACTION.DEVICE_TYPES_LIST_ERROR) {
    return { ...state, deviceError: action.data, isDeviceTypesLoading: false };
  }

  return state;
};

// DEVICES LIST STATE & REDUCER
export function devicesListState() {
  return {
    isDeviceListLoading: false,
    devicesList: null,
    deviceError: null,
  }
}

export const DeviceListReducer = (state, action) => {
  if(!state || action.type === DeviceActionCreator.DEVICE_ACTION.RESET_DEVICE_LIST_STATE) {
    return { ...state, ...devicesListState() }
  }

  if(action.type === DeviceActionCreator.DEVICE_ACTION.DEVICE_LIST_REQUEST) {
    return {...state, isDeviceListLoading: true }
  }

  if(action.type === DeviceActionCreator.DEVICE_ACTION.DEVICE_LIST_RESPONCE) {
    return { ...state, devicesList: action.data, isDeviceListLoading: false }
  }

  if(action.type === DeviceActionCreator.DEVICE_ACTION.DEVICE_LIST_ERROR) {
    return { ...state, deviceError: action.data, isDeviceListLoading: false }
  }
  return state;
}

export function deviceListPagenationState(){
  return {
    isDeviceListLoading: false,
    devicesList: null,
    deviceError: null,
    page: 0,
    size: 10
  }
}

export const DeviceListPagenationReducer = (state, action) => {
  if(!state || action.type === DeviceActionCreator.DEVICE_ACTION.RESET_DEVICE_LIST_PAGINATION_STATE) {
    return { ...state, ...deviceListPagenationState() }
  }

  if(action.type === DeviceActionCreator.DEVICE_ACTION.DEVICE_LIST_PAGINATION_REQUEST) {
    return {...state, isDeviceListLoading: true, page: action.data.page, size: action.data.size }
  }

  if(action.type === DeviceActionCreator.DEVICE_ACTION.DEVICE_LIST_PAGINATION_RESPONCE) {
    return { ...state, devicesList: action.data, isDeviceListLoading: false }
  }

  if(action.type === DeviceActionCreator.DEVICE_ACTION.DEVICE_LIST_PAGINATION_ERROR) {
    return { ...state, deviceError: action.data, isDeviceListLoading: false }
  }
  return state;
}

export function devicesDetailsState() {
  return {
    isDeviceDetailsLoading: false,
    deviceDetails: null,
    deviceDetailsError: null
  }
}

export const DeviceDetailsReducer = (state, action) => {
  if(!state || action.type === DeviceActionCreator.DEVICE_ACTION.RESET_DEVICE_DETAILS_STATE) {
    return { ...state, ...devicesDetailsState() }
  }

  if(action.type === DeviceActionCreator.DEVICE_ACTION.ADD_DEVICE_REQUEST) {
    return {...state, isDeviceDetailsLoading: true }
  }

  if(action.type === DeviceActionCreator.DEVICE_ACTION.ADD_DEVICE_RESPONCE) {
    return { ...state, deviceDetails: action.data, isDeviceDetailsLoading: false }
  }

  if(action.type === DeviceActionCreator.DEVICE_ACTION.ADD_DEVICE_ERROR) {
    return { ...state, deviceDetailsError: action.data, isDeviceDetailsLoading: false }
  }
  return state;
}

export function deviceById(){
  return {
    isDeviceDetailsLoading: false,
    isError: null,
    deviceDetails: null,
    deviceId: null
  }
}

export const GetdeviceByIdReducer = (state, action) => {
  if (
    !state ||
    action.type === DeviceActionCreator.DEVICE_ACTION.RESET_GET_DEVICE_BY_ID
  ) {
    return { ...state, ...deviceById() };
  }

  if(action.type === DeviceActionCreator.DEVICE_ACTION.GET_DEVICE_BY_ID_REQUEST) {
    return {...state, isDeviceDetailsLoading: true, deviceId : action.data };
  }

  if (action.type === DeviceActionCreator.DEVICE_ACTION.GET_DEVICE_BY_ID_RESPONCE) {
    return { ...state, deviceDetails: { 
      device_ref_key: action.data.device.device_ref_key, 
      deviceType: action.data.device.deviceType,
      active: action.data.device.active,
      description: action.data.device.description,
      vendorId: action.data.device.vendorId,
      available: action.data.device.available,
     }, isDeviceDetailsLoading: false };
  }

  if (action.type === DeviceActionCreator.DEVICE_ACTION.GET_DEVICE_BY_ID_ERROR) {
    return { ...state, isDeviceDetailsLoading: false, isError: action.data, deviceDetails: null };
  }

  return state;
}

export const updateDeviceState = () => {
  return {
    isDeviceDetailsLoading: false,
    isError: null,
    deviceDetails: null
  }
}

 export const UpdateDeviceReducer = (state, action) => {
  if (
    !state ||
    action.type === DeviceActionCreator.DEVICE_ACTION.RESET_UPDATE_DEVICE_BY_ID
  ) {
    return { ...state, ...updateDeviceState() };
  }
  // EDIT DEVICE
  if(action.type === DeviceActionCreator.DEVICE_ACTION.UPDATE_DEVICE_BY_ID_REQUEST){
    return { ...state, isLoading: true,id: action.id};
  }
  if (action.type === DeviceActionCreator.DEVICE_ACTION.UPDATE_DEVICE_BY_ID_RESPONCE) {
    return { ...state, deviceDetails: action.data, isLoading: false };
  }
  if (action.type === DeviceActionCreator.DEVICE_ACTION.UPDATE_DEVICE_BY_ID_ERROR) {
    return { ...state, isLoading: false, isError: action.data };
  }
  return state; 
}

// dummy state for reducer pagenation

export function deviceDetailsWithPagenationState() {
  return {
    isDeviceDetailsLoading: false,
    isError: null,
    deviceDetails: null,
    page: 0,
    size: 10
  }
}

export const DeviceDetailsWithPagenationReducer = (state, action) => {
  if (
    !state ||
    action.type === DeviceActionCreator.DEVICE_ACTION.RESET_DEVICE_DETAILS_PAGINATION
  ) {
    return { ...state, ...deviceDetailsWithPagenationState() };
  } 

  if(action.type === DeviceActionCreator.DEVICE_ACTION.DEVICE_DETAILS_PAGINATION_REQUEST) {
    return {...state, isDeviceDetailsLoading: true, page: action.data.page, size: action.data.size };
  }

  if (action.type === DeviceActionCreator.DEVICE_ACTION.DEVICE_DETAILS_PAGINATION_RESPONCE) {
    return { ...state, deviceDetails: action.data, isDeviceDetailsLoading: false };
  }

  if (action.type === DeviceActionCreator.DEVICE_ACTION.DEVICE_DETAILS_PAGINATION_ERROR) {
    return { ...state, deviceDetailsError: action.data, isDeviceDetailsLoading: false };
  }
  return state;
}