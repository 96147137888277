import LOCAL_KEYS, { parseLocalStorageJSON } from "../../../Utilites/LocalStorage";
import { RequestBody } from "../../WebServiceManager/RequestBody";
import { RequestHeader } from "../../WebServiceManager/RequestHeader";

export default class VendorRequestBody {

  getVendorListRequest(method) {
    const header = new RequestHeader();
    const request = new RequestBody(
      method,
      header.applicationJson(parseLocalStorageJSON(LOCAL_KEYS.ACCESS_TOKEN)),
    );
    return request;
  }

}