import React from "react";
import PropTypes from "prop-types";
import "react-phone-input-2/lib/style.css";
import PhoneInput from "react-phone-input-2";

const PhoneInputComponent = (props) => {
  const { name, onChange, onBlur, value, disabled = false } = props;
  const updatedValue = value ? `+1${value}` : "";

  const handleChange = (phoneNumber, country) => {
    onChange(phoneNumber.slice(country.dialCode.length));
  };
  return (
    <PhoneInput
      inputClass="phone-number font-poppins border
    border-gray-light
    rounded-lg
    p-3
    bg-white
    "
      containerClass="shadow-md bg-white"
      name={name}
      country={"us"}
      onlyCountries={["us", "ca"]}
      onChange={(phoneNumber, country) => handleChange(phoneNumber, country)}
      onBlur={onBlur}
      value={updatedValue}
      disabled={disabled}
    />
  );
};

PhoneInputComponent.propTypes = {
  name: PropTypes.string,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  value: PropTypes.string,
  disabled: PropTypes.bool,
};

export default PhoneInputComponent;
