import { Field } from "formik";
import React from "react";
import PropTypes from 'prop-types';

const CustomCheckbox = ({ name, value, onChange, label, isDisabled=false }) => {
    return (
      <>
      {label &&  <label className="text-xs my-1 font-poppins block">
        {label}
      </label>}
      <Field
        type="checkbox"
        name={name}
        checked={value}
        onChange={onChange}
        className="cursor-pointer"
        disabled={isDisabled}
      />
      </>
    );
  };

CustomCheckbox.propTypes = { 
    name: PropTypes.string,
    onChange: PropTypes.func,
    value: PropTypes.bool,
    label: PropTypes.string,
    isDisabled: PropTypes.bool,
}

export default CustomCheckbox;