import React from "react";
import { convertDateTime } from "../../../../Utilites/Helpers";
export const mockData = {
  highAlertPatients: [
    {
      id: 1,
      patientName: "Patient1",
      phoneNumber: "9999999999",
      gender: "Male",
      deviceName: "PULSEOX",
      status: "Adressed",
      alertTime: "2024-08-05T06:30:00Z",
      alert: {
        parameter: "SpO2",
        range: "95-100%",
        value: "108",
      }
    },
    {
      id: 2,
      patientName: "Patient2",
      phoneNumber: "9999999999",
      gender: "Female",
      deviceName: "PULSEOX",
      status: "Adressed",
      alertTime: "2024-08-08T06:30:00Z",
      alert: {
        parameter: "BPM",
        range: "60-100",
        value: "58",
      }
    },
    {
      id: 3,
      patientName: "Patient3",
      phoneNumber: "9999999999",
      gender: "Female",
        deviceName: "PULSEOX",
      status: "not Adressed",
      alertTime: "2024-08-10T06:30:00Z",
      alert: {
        parameter: "SpO2",
        range: "95-100%",
        value: "103",
      }
    },
    {
      id: 4,
      patientName: "Patient4",
      phoneNumber: "9999999999",
      gender: "male",
        deviceName: "PULSEOX",
      status: "not Adressed",
      alertTime: "2024-08-11T06:30:00Z",
      alert: {
        parameter: "BPM",
        range: "60-100",
        value: "106",
      }
    },
  ],
  highAlertPatientsColumns: [
    {
      name: "Sno",
      selector: (row) => row.id,
    },
    {
      name: "Patient Id",
      selector: (row) => row.patientName,
      sortable: true,
    },
    {
      name: "Device Name",
      selector: (row) => row.deviceName,
      sortable: true,
    },
    {
      name: "Alert Parameter",
      selector: (row) => `${row.alert.parameter} (${row.alert.range})`,
      sortable: true,
    },
    {
      name: "Alert Value",
      selector: (row) => row.alert.value,
      sortable: true,
    },
    {
      name: "Time of Alert",
      selector: (row) => convertDateTime(row.alertTime),
      sortable: true,
    },
    {
      name: "Alert Status",
      selector: (row) => row.status,
      sortable: true,
    },
    {
      name: "Action",
      cell: (row) => (
        row.status !== "Adressed" &&
        <div className="bg-primary rounded-2xl h-7 w-7 flex justify-center items-center cursor-pointer">
        <svg
          viewBox="0 0 512 512"
          fill="#fff"
          className={`size-4`}
          onClick={() => {console.log("aleart adressed")}}
        >
          <path
            fill="none"
            stroke="#fff"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={32}
            d="M364.13 125.25L87 403l-23 45 44.99-23 277.76-277.13-22.62-22.62zM420.69 68.69l-22.62 22.62 22.62 22.63 22.62-22.63a16 16 0 000-22.62h0a16 16 0 00-22.62 0z"
          />
        </svg>
      </div>
      ),
    }
  ],
  columnsForDeviceType: [
    {
        name: "Sno",
        selector: (row) => row.index+1,
    },
    {
      name: "Device Type",
      selector: (row) => row.label,
    },
    
  ],
  columnsForUserTypes: [
    {
        name: "Sno",
        selector: (row) => row.index+1,
    },
    {
      name: "User Type",
      selector: (row) => row.label,
    }
  ],
  ipAddressColumns: [
    {
        name: "Sno",
        selector: (row) => row.id,
    },
    {
      name: "Ip address",
      selector: (row) => row.ip,
    },
    {
        name: "Date & Time",
        selector: (row) => row.dateTime,
    },
    
  ],
  ipAddress: [
    {
       id: 1,
       ip: '192.158.1.38',
       dateTime: '1-10-12 4:15 am'
    },
    {
        id: 2,
        ip: '192.158.1.38',
        dateTime: '1-10-12 6:15 am'
     },
     {
        id: 3,
        ip: '192.158.1.38',
        dateTime: '1-10-12 2:15 am'
     },
     {
        id: 4,
        ip: '192.158.1.38',
        dateTime: '1-10-12 1:15 am'
     },
  ]
};
