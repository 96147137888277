import React, { useMemo, useState } from "react";
import DataTable from "react-data-table-component";
import PropTypes, { array } from "prop-types";

import FilterComponent from "../FilterComponent";
import Select from "react-select";
import { appData } from "../../Constants";

const CusotmDataTable = (props) => {
  const {
    columns,
    data,
    pagination,
    fixedHeaderScrollHeight,
    title,
    enableSearch = true,
    filterKey,
    activeFlag,
    setActiveFlag,
    filteredList = false,
    filterListData,
    activeFlagRequired = true,
    serverPageNation = false,
    customPageChange,
    paginationTotalRows = null,
    customPageSizeChangeFunction,
    entireRowClick = false,
    customRowClickFunction,
    additionalDropDown = false,
    dropDownDefaultValue = null,
    additionalDropDownFunction,
  } = props;

  const [filterText, setFilterText] = useState("");
  const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
  
  const filteredItems = data?.filter((item) => {
    if (Array.isArray(filterKey)) {
      // If filterKey is an array, check if any of the keys in the array match the filter text
      return filterKey.some((key) => 
        item[key] &&
        item[key].toLowerCase().includes(filterText.toLowerCase())
      ) || 
      // Check for concatenation of key1 + key2
      filterKey.length > 1 &&
      filterKey.map(key => item[key] || '').join(' ').toLowerCase().includes(filterText.toLowerCase());
    } else {
      // If filterKey is a string, compare it directly
      return item[filterKey] &&
        item[filterKey].toLowerCase().includes(filterText.toLowerCase());
    }
  });
  

  const handlePerRowsChange =( newPerPage, page) => {
    serverPageNation && customPageSizeChangeFunction(newPerPage)
  }

  const handlePageChange = (page) => {
    serverPageNation && customPageChange(page)
    // customPageChange(page)
  }

  const handleRowClickFunction = (row) => {
    entireRowClick && customRowClickFunction(row)
  }


  const subHeaderComponentMemo = useMemo(() => {
    const handleClear = () => {
      if (filterText) {
        setResetPaginationToggle(!resetPaginationToggle);
        setFilterText("");
      }
    };

    const handleOnChange = (option) => {
      setActiveFlag(option);
    };

    const handleTypeChange = (option) => {
      additionalDropDownFunction(option);
    };

    return (
      <span className="flex flex-row gap-4 items-center justify-center">
          {filteredList && activeFlagRequired && (
            <>
            { additionalDropDown && 
            <Select
            className={"w-44 font-poppins rounded-lg shadow-lg text-sm mt-2 z-20"}
            defaultValue={dropDownDefaultValue}
            field={dropDownDefaultValue}
            onChange={handleTypeChange}
            placeholder={"Select Type"}
            options={appData.roles}
            isMulti={false}
            isDisabled={false}
          /> }
            <Select
              className={"w-44 font-poppins rounded-lg shadow-lg text-sm mt-2 z-20"}
              field={activeFlag}
              defaultValue={activeFlag}
              onChange={handleOnChange}
              placeholder={"Select Flag"}
              options={filterListData}
              isMulti={false}
              isDisabled={false}
            />
          </>
          )}
          {enableSearch && (
          <FilterComponent
            onFilter={(e) => setFilterText(e.target.value)}
            title={title}
            onClear={handleClear}
            filterText={filterText}
          />
        )}
      </span>
    );
  }, [filterText, resetPaginationToggle, title]);

  const customStyles = {
    rows: {
      style: {
        fontFamily: 'Poppins',
        fontSize:'12px'
      },
    },
    headCells: {
      style: {
        fontFamily: 'Poppins',
        fontSize:'12px'
      },
    },
    cells: {
      style: {
        fontFamily: 'Poppins',
        fontSize:'12px'
      },
    },
    pagination:{
      style: {
        fontFamily: 'Poppins',
        fontSize:'12px'
      },
    }
  }

  return (
    data && (
      <DataTable
        fixedHeader
        fixedHeaderScrollHeight={fixedHeaderScrollHeight}
        columns={columns}
        data={enableSearch ? filteredItems : data}
        pagination={pagination}
        paginationServer={serverPageNation}
        paginationTotalRows={paginationTotalRows}
        onChangeRowsPerPage={handlePerRowsChange}
        onChangePage={handlePageChange}
        className={`font-poppins `}
        paginationRowsPerPageOptions={[5, 10, 15, 20]}
        subHeader={enableSearch}
        subHeaderComponent={subHeaderComponentMemo}
        customStyles={customStyles}
        onRowClicked={handleRowClickFunction}
      />
    )
  );
};

CusotmDataTable.propTypes = {
  columns: PropTypes.instanceOf(array),
  data: PropTypes.instanceOf(array),
  pagination: PropTypes.bool,
  customStyles: PropTypes?.string,
  title: PropTypes?.string,
  fixedHeaderScrollHeight: PropTypes?.string,
  enableSearch: PropTypes.bool,
  children: PropTypes.any,
  filterKey: PropTypes.string || PropTypes.array,
  filteredList: PropTypes.bool,
  filterListData: PropTypes.instanceOf(array),
  activeFlag: PropTypes.object,
  setActiveFlag: PropTypes.func,
  activeFlagRequired: PropTypes.bool,
  serverPageNation: PropTypes.bool,
  customPageChange: PropTypes.func,
  paginationTotalRows: PropTypes.number,
  customPageSizeChangeFunction: PropTypes.func,
  entireRowClick: PropTypes.bool,
  customRowClickFunction: PropTypes.func,
  additionalDropDown: PropTypes.bool,
  dropDownDefaultValue: PropTypes.object,
  additionalDropDownFunction: PropTypes.func,
};

export default CusotmDataTable;
