import * as MonitoringActionCreator from "./MonitoringActionCreator";

export function monitoringDeviceState() {
  return {
    isMonitoringDataLoading: false,
    monitoringData: null,
    monitoringDataError: null,
  };
}

export const MonitoringDeviceReducer = (state, action) => {
  if (!state || action.type === MonitoringActionCreator.MONITORING_ACTION.RESET_DEVICE_TYPES_LIST_STATE) {
    return { ...state, ...monitoringDeviceState() };
  }

  if (action.type === MonitoringActionCreator.MONITORING_ACTION.MONITORING_DEVICE_REQUEST) {
    return { ...state, isMonitoringDataLoading: true };
  }

  if (action.type === MonitoringActionCreator.MONITORING_ACTION.MONITORING_DEVICE_RESPONSE) {
    return { ...state, isMonitoringDataLoading: false, monitoringData: action.data };
  }

  if (action.type === MonitoringActionCreator.MONITORING_ACTION.MONITORING_DEVICE_ERROR) {
    return { ...state, monitoringDataError: action.data,  isMonitoringDataLoading: false };
  }

  return state;
};


export function refreshMonitoringDeviceState() {
  return {
    isRefreshMonitoringDataLoading: false,
    refreshMonitoringDataError: null,
  };
}

export const RefreshMonitoringDeviceReducer = (state, action) => {
  if (!state || action.type === MonitoringActionCreator.MONITORING_ACTION.RESET_MONITORING_DATA_REFRESH_STATE) {
    return { ...state, ...refreshMonitoringDeviceState() };
  }

  if (action.type === MonitoringActionCreator.MONITORING_ACTION.MONITORING_DATA_REFRESH_REQUEST) {
    return { ...state, isRefreshMonitoringDataLoading: true };
  }

  if (action.type === MonitoringActionCreator.MONITORING_ACTION.MONITORING_DATA_REFRESH_RESPONSE) {
    return { ...state, isRefreshMonitoringDataLoading: false };
  }

  if (action.type === MonitoringActionCreator.MONITORING_ACTION.MONITORING_DATA_REFRESH_ERROR) {
    return { ...state, refreshMonitoringDataError: action.data,  isRefreshMonitoringDataLoading: false };
  }

  return state;
};


export function currentDeviceReadingsState() {
  return {
    isCurrentDeviceReadingsLoading: false,
    currentDeviceReadings: null,
    currentDeviceReadingsError: null,
  };
}


export const CurrentDeviceReadingsReducer = (state, action) => {
  if (!state || action.type === MonitoringActionCreator.MONITORING_ACTION.RESET_CURRENT_DEVICE_READINGS_STATE) {
    return { ...state, ...currentDeviceReadingsState() };
  }

  if (action.type === MonitoringActionCreator.MONITORING_ACTION.CURRENT_DEVICE_READINGS_REQUEST) {
    return { ...state, isCurrentDeviceReadingsLoading: true };
  }

  if (action.type === MonitoringActionCreator.MONITORING_ACTION.CURRENT_DEVICE_READINGS_RESPONSE) {
    return { ...state, isCurrentDeviceReadingsLoading: false, currentDeviceReadings: action.data };
  }

  if (action.type === MonitoringActionCreator.MONITORING_ACTION.CURRENT_DEVICE_READINGS_ERROR) {  
    return { ...state, currentDeviceReadingsError: action.data,  isCurrentDeviceReadingsLoading: false };
  } 

  return state;
};
