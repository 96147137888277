import React, { useEffect, useState } from "react";
import LoginHeader from "../../../NewUiComponents/LoginHeader";
import Card from "../../../NewUiComponents/Card";

import { Formik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import { ForgotPasswordSchema } from "../../../Utilites/Schema";
import TextInput from "../../../NewUiComponents/TextInput";
import {
  requestFpAction,
  setResetStateFp,
  setResetStateResetPassword
} from "../../../Redux/LoginState/LoginActionsCreator";
import { toast } from "react-toastify";
import LOCAL_KEYS, { setToLocalStorage } from "../../../Utilites/LocalStorage";
import { appMessages } from "../../../Constants";
import CustomButton from "../../../NewUiComponents/CustomButton";
import Loader from "../../../NewUiComponents/Loader";

const ForgotPassword = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const FpReducerState = useSelector((state) => state.FpReducer);
  const { fpError, fpResponse, isLoading } = FpReducerState || {};

  useEffect(() => {
    clearStates();
  },[]);

  useEffect(() => {
    if (fpError) {
      toast.dismiss();
      toast.success(appMessages.FP_MESSAGE);
      clearStates();
    }
  }, [fpError]);

  useEffect(() => {
    if (fpResponse) {
      toast.dismiss();
      toast.success(appMessages.FP_MESSAGE);
      clearStates();
    }
  }, [fpResponse]);

  const goToLogin = () => {
    navigate("/login");
  };

  const clearStates = () => {
    dispatch(setResetStateFp());
    dispatch(setResetStateResetPassword());
  }

  return (
    <>
      <Loader isLoading={isLoading} />
      <div className="bg-default h-screen">
        <div className="bg-gradient-to-r from-secondary  to-primary">
          <LoginHeader />
          <div>
            <div className="text-center mt-6  mb-32">
              <p className="font-poppins text-2xl text-white my-4">
                Forgot Password?
              </p>
              <p className="font-poppins text-sm font-light text-lead text-white my-2">
                Please enter your registered email
              </p>
              <p className="font-poppins text-sm font-light text-lead text-white my-2">
                to reset your password.
              </p>
            </div>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              preserveAspectRatio="none"
              version="1.1"
              viewBox="0 0 2560 100"
              x="0"
              y="0"
            >
              <polygon
                className="fill-default"
                points="2560 0 2560 100 0 100"
              ></polygon>
            </svg>
          </div>
        </div>
        <div className="flex justify-center items-center relative">
          <Card
            className={
              "bg-white w-96 h-auto px-8 py-12 justify-center items-center absolute -top-32"
            }
          >
            <div className="">
              <p className="font-poppins text-primary text-xl font-medium text-center">
                Forgot Password
              </p>
            </div>
            <Formik
              initialValues={{ email: "" }}
              validationSchema={ForgotPasswordSchema}
              onSubmit={(values, { setSubmitting }) => {
                setSubmitting(false);
                const credentials = {
                  email: values.email,
                };
                dispatch(requestFpAction(credentials));
              }}
            >
              {({
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
                isSubmitting,
              }) => (
                <form action="#" method="POST" onSubmit={handleSubmit}>
                  <>
                    <TextInput
                      id="email"
                      name="email"
                      type="email"
                      label={"Email address"}
                      placeHolder={"Enter your email"}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.email}
                    />
                    <span className="text-danger text-xs">
                      {errors.email && touched.email && errors.email}
                    </span>
                  </>
                  <div className="py-6">
                    <CustomButton
                      name="Submit"
                      onClick={() => {}}
                      fullwidth
                      isDisabled={isSubmitting}
                      type={"submit"}
                    />
                  </div>
                  <p
                    className="font-poppins text-sm mt-8 text-right cursor-pointer text-primary"
                    onClick={goToLogin}
                  >
                    Sign In Now!
                  </p>
                </form>
              )}
            </Formik>
          </Card>
        </div>
      </div>
    </>
  );
};

export default ForgotPassword;
