import React from "react";
import PropTypes from "prop-types";

const CustomButton = (props) => {
  const {
    isDisabled = false,
    name,
    type,
    fullwidth = false,
    onClick,
    icon,
    rounded,
  } = props;
  return rounded ? (
    <button
      disabled={isDisabled}
      onClick={onClick}
      type={type}
      className={`bg-primary p-2 rounded-3xl shadow-2xl`}
    >
      {icon && icon()}
    </button>
  ) : (
    <button
      disabled={isDisabled}
      onClick={onClick}
      type={type}
      className={`flex float-right 
                  ${fullwidth ? "sm:w-full" : "lg:w-1/4 md:w-2/6"} w-full 
                  justify-center rounded-md
                  bg-primary p-2 
                 font-poppins shadow-xl text-white text-xs lg:text-xs xl:text-sm`}
    >
      {name && name}
      {icon && icon()}
    </button>
  );
};

CustomButton.propTypes = {
  isDisabled: PropTypes.bool,
  fullwidth: PropTypes.bool,
  name: PropTypes.string.isRequired,
  type: PropTypes.string,
  onClick: PropTypes.func,
  icon: PropTypes.func,
  rounded: PropTypes.string,
};

export default CustomButton;
