import * as UserActionCreator from "./UserActionCreator";

export function defaultUserTypesState() {
  return {
      isUserLoading: false,
      userTypes: null,
      userError: null,
  };
}

export function defaultUsersList(){
  return {
    isUsersListLoading: false,
    usersListResponce: null,
    usersListError: null,
    page: 0,
    size: 10
  }
}

export function userState(){
  return {
    isLoading: false,
    isError: null,
    userDetailsResponce: null
  }
}

export function userById(){
  return {
    isUserDetailsLoading: false,
    isError: null,
    userDetails: null,
    userId: null
  }
}

export function defaultUserState(){
  return {
    isLoading: false,
    isError: null,
    userType: { 
      id: 1 + "all", 
      value: "all", 
      label: "ALL" 
    },
    previousPath: false
  }
}

/**
 * Users Reducer its take state and action
 * @param state
 * @param action
 */
export const UserTypesReducer = (state, action) => {
  if (
    !state ||
    action.type === UserActionCreator.USER_ACTION.RESET_USER_STATE
  ) {
    return { ...state, ...defaultUserTypesState() };
  }

  if (action.type === UserActionCreator.USER_ACTION.USER_REQUEST) {
    return { ...state, isUserLoading: true };
  }

  if (action.type === UserActionCreator.USER_ACTION.USER_RESPONSE) {
    return { ...state, userTypes: action.data, isUserLoading: false };
  }

  if (action.type === UserActionCreator.USER_ACTION.USER_ERROR) {
    return { ...state, userError: action.data, isUserLoading: false };
  }

  return state;
};

export const UsersListReducer = (state, action) => {
  if (
    !state ||
    action.type === UserActionCreator.USER_ACTION.RESET_USERS_LIST_STATE
  ) {
    return { ...state, ...defaultUsersList() };
  }
  
  if(action.type === UserActionCreator.USER_ACTION.USERS_LIST_REQUEST) {
    return {...state, isUsersListLoading: true };
  }

  if (action.type === UserActionCreator.USER_ACTION.USERS_LIST_RESPONSE) {
    return { ...state, usersListResponce: { usersList : action.data }, isUsersListLoading: false };
  }

  if (action.type === UserActionCreator.USER_ACTION.USERS_LIST_ERROR) {
    return { ...state, usersListError: action.data, isUsersListLoading: false, usersListResponce: null };
  }

  return state;
}

export const AddUserReducer = (state, action) => {
  if (
    !state ||
    action.type === UserActionCreator.USER_ACTION.RESET_USER_DETAILS_STATE
  ) {
    return { ...state, ...userState() };
  }

  if(action.type === UserActionCreator.USER_ACTION.ADD_USER_REQUEST) {
    return {...state, isLoading: true };
  }

  if (action.type === UserActionCreator.USER_ACTION.ADD_USER_RESPONCE) {
    return { ...state, userDetailsResponce: 'User Added Sucessfully', isLoading: false };
  }

  if (action.type === UserActionCreator.USER_ACTION.ADD_USER_ERROR) {
    return { ...state, isLoading: false, isError: action.data };
  }
  return state;
}

export function updateUserState(){
  return {
    isLoading: false,
    isError: null,
    updateUserDetailsResponce: null
  }
}

export const UpdateUserReducer = (state, action) => {
  if (
    !state ||
    action.type === UserActionCreator.USER_ACTION.RESET_UPDATE_USER_STATE
  ) {
    return { ...state, ...updateUserState() };
  }
// EDIT USER
  if(action.type === UserActionCreator.USER_ACTION.EDIT_USER_BY_ID_REQUEST){
    return { ...state, isLoading: true,id: action.id};
  }
  if (action.type === UserActionCreator.USER_ACTION.EDIT_USER_BY_ID_RESPONCE) {
    return { ...state, updateUserDetailsResponce: action.data, isLoading: false };
  }
  if (action.type === UserActionCreator.USER_ACTION.GET_USER_BY_ID_ERROR) {
    return { ...state, isLoading: false, isError: action.data };
  }
  return state;
}

export const GetUserByIdReducer = (state, action) => {
  if (
    !state ||
    action.type === UserActionCreator.USER_ACTION.RESET_GET_USER_BY_ID
  ) {
    return { ...state, ...userById() };
  }

  if(action.type === UserActionCreator.USER_ACTION.GET_USER_BY_ID_REQUEST) {
    return {...state, isUserDetailsLoading: true, userId : action.data };
  }

  if (action.type === UserActionCreator.USER_ACTION.GET_USER_BY_ID_RESPONCE) {
    return { ...state, userDetails: action.data, isUserDetailsLoading: false };
  }

  if (action.type === UserActionCreator.USER_ACTION.GET_USER_BY_ID_ERROR) {
    return { ...state, isUserDetailsLoading: false, isError: action.data, userDetails: null };
  }

  return state;
}

export function doctorsPatientsState(){
  return {
    isLoading: false,
    isError: null,
    doctorsPatientsList: null
  }
}

export const GetDoctorPatientsListReducer = (state, action) => {
  if (
    !state ||
    action.type === UserActionCreator.USER_ACTION.RESET_DOCTORS_PATIENTS_LIST_STATE
  ) {
    return { ...state, ...doctorsPatientsState() };
  }
  if(action.type === UserActionCreator.USER_ACTION.DOCTORS_PATIENTS_LIST_REQUEST) {
    return { ...state, isLoading: true };
  }
  if (action.type === UserActionCreator.USER_ACTION.DOCTORS_PATIENTS_LIST_RESPONSE) {
    return { ...state, doctorsPatientsList: action.data, isLoading: false };
  }
  if (action.type === UserActionCreator.USER_ACTION.DOCTORS_PATIENTS_LIST_ERROR) {
    return { ...state, isLoading: false, isError: action.data };
  }
  return state;
}

export const DefaultUserStaeReducer = (state, action) => {
  if (
    !state ||
    action.type === UserActionCreator.USER_ACTION.RESET_USER_TYPE_STATE
  ) {
    return { ...state, ...defaultUserState() };
  }

  if (action.type === UserActionCreator.USER_ACTION.USER_TYPE_REQUEST) {
    return { ...state, userType: action.data };
  }

  if (action.type === UserActionCreator.USER_ACTION.USER_TYPE_SET_PATH) {
    return { ...state, previousPath: true };
  }
  return state;
}


/**
 * @USERS_LIST_PAGENATION_STATE
 * 
 */

export function usersListPagenationState(){
  return {
    isLoading: false,
    isError: null,
    usersList: null,
    page: 0,
    size: 10,
  }
}

export const UsersListPagenationReducer = (state, action) => {
  if (
    !state ||
    action.type === UserActionCreator.USER_ACTION.RESET_USERS_LIST_PAGENATION_STATE
  ) {
    return { ...state, ...usersListPagenationState() };
  }
  
  if(action.type === UserActionCreator.USER_ACTION.USERS_LIST_PAGENATION_REQUEST) {
    return {...state, isLoading: true, page: action.data.page, size: action.data.size};
  }

  if (action.type === UserActionCreator.USER_ACTION.USERS_LIST_PAGENATION_RESPONSE) {
    return { ...state, usersList: action.data, isLoading: false };
  }

  if (action.type === UserActionCreator.USER_ACTION.USERS_LIST_PAGENATION_ERROR) {
    return { ...state, isLoading: false, isError: action.data };
  }

  return state;
}

export function defaultactveFlag(){
  return {
    userStatus: {
      id: 1 + "active",
      value: "active",
      label: "active",
    }
  }
}

export const ActiveFlagReducer = (state, action) => {
  if (
    !state ||
    action.type === UserActionCreator.USER_ACTION.RESET_ACTIVE_FLAG
  ) {
    return { ...state, ...defaultactveFlag() };
  }
  if(action.type === UserActionCreator.USER_ACTION.SET_ACTIVE_FLAG) {
    return {...state, userStatus: action.data}
  }
  return state;
}



