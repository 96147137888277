import React from "react";
import PropTypes from "prop-types";

const ActionIcons = ({ typeOfUser, onEditClicked, onViewClicked, additionalIcon=false, additionalIconClicked }) => {
  return (
    <div className="flex flex-row gap-4">
      <div className="bg-primary rounded-2xl h-7 w-7 flex justify-center items-center cursor-pointer">
        <svg
          viewBox="0 0 512 512"
          fill="#fff"
          className={`${
            typeOfUser === "HOSPITAL" && "cursor-not-allowed"
          } size-4 `}
          onClick={() => (typeOfUser === "HOSPITAL" ? null : onEditClicked())}
        >
          <path
            fill="none"
            stroke="#fff"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={32}
            d="M364.13 125.25L87 403l-23 45 44.99-23 277.76-277.13-22.62-22.62zM420.69 68.69l-22.62 22.62 22.62 22.63 22.62-22.63a16 16 0 000-22.62h0a16 16 0 00-22.62 0z"
          />
        </svg>
      </div>
      <div className="bg-primary rounded-2xl h-7 w-7 flex justify-center items-center cursor-pointer">
        <svg
          viewBox="0 0 24 24"
          fill="#fff"
          onClick={onViewClicked}
          className="size-4"
        >
          <path d="M15.5 12a3.5 3.5 0 11-7 0 3.5 3.5 0 017 0z" />
          <path
            fillRule="evenodd"
            d="M12 3.5c-3.432 0-6.125 1.534-8.054 3.24C2.02 8.445.814 10.352.33 11.202a1.6 1.6 0 000 1.598c.484.85 1.69 2.758 3.616 4.46C5.876 18.966 8.568 20.5 12 20.5c3.432 0 6.125-1.534 8.054-3.24 1.926-1.704 3.132-3.611 3.616-4.461a1.6 1.6 0 000-1.598c-.484-.85-1.69-2.757-3.616-4.46C18.124 5.034 15.432 3.5 12 3.5zM1.633 11.945c.441-.774 1.551-2.528 3.307-4.08C6.69 6.314 9.045 5 12 5c2.955 0 5.309 1.315 7.06 2.864 1.756 1.553 2.866 3.307 3.307 4.08a.111.111 0 01.017.056.111.111 0 01-.017.056c-.441.774-1.551 2.527-3.307 4.08C17.31 17.685 14.955 19 12 19c-2.955 0-5.309-1.315-7.06-2.864-1.756-1.553-2.866-3.306-3.307-4.08A.11.11 0 011.616 12a.11.11 0 01.017-.055z"
          />
        </svg>
      </div>
      {additionalIcon &&
      <div className="bg-primary rounded-2xl h-7 w-7 flex justify-center items-center cursor-pointer">
        <svg
        fill="#fff"
        viewBox="0 0 16 16"
        className="size-5 lg:size-4 xl:size-5"
        onClick={additionalIconClicked}
      >
        <path d="M8.5 4.5a.5.5 0 00-1 0v.634l-.549-.317a.5.5 0 10-.5.866L7 6l-.549.317a.5.5 0 10.5.866l.549-.317V7.5a.5.5 0 101 0v-.634l.549.317a.5.5 0 10.5-.866L9 6l.549-.317a.5.5 0 10-.5-.866l-.549.317V4.5zM5.5 9a.5.5 0 000 1h5a.5.5 0 000-1h-5zm0 2a.5.5 0 000 1h5a.5.5 0 000-1h-5z" />
        <path d="M2 2a2 2 0 012-2h8a2 2 0 012 2v12a2 2 0 01-2 2H4a2 2 0 01-2-2V2zm10-1H4a1 1 0 00-1 1v12a1 1 0 001 1h8a1 1 0 001-1V2a1 1 0 00-1-1z" />
      </svg>
      </div>
      }
    </div>
  );
};

ActionIcons.propTypes = {
  typeOfUser: PropTypes.string,
  onEditClicked: PropTypes.func,
  onViewClicked: PropTypes.func,
  additionalIcon: PropTypes.bool,
  additionalIconClicked: PropTypes.func
};

export default ActionIcons;
