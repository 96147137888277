import { call, put, takeLatest } from "@redux-saga/core/effects";
import {
  errorDownloadReportAction,
  errorReportsAction,
  REPORTS_ACTION,
  responseDownloadReportAction,
  responseReportsAction,
} from "./ReportsActionCreator";
import ReportsService from "../../Services/RestApiManager/ReportsManager/ReportsService";
import { downloadBufferToExcel } from "../../Utilites/Wrapper";

export function* reportsRequest(payload) {
  try {
    const response = yield call(
      ReportsService.shared.getReportsRequest,
      payload.data
    );
    yield put(responseReportsAction(response));
  } catch (error) {
    yield put(errorReportsAction(error));
  }
}

export function* downloadReportRequest(payload) {
  try {
    const response = yield call(
      ReportsService.shared.downloadReportRequest,
      payload.data
    );
    if (response) {
      downloadBufferToExcel(response, "patient_data.xlsx");
      yield put(responseDownloadReportAction());
    } else {
      yield put(errorDownloadReportAction({}));
    }
  } catch (error) {
    yield put(errorDownloadReportAction(error));
  }
}

export function* getReportsWatcherSaga() {
  yield takeLatest(REPORTS_ACTION.REQUEST_REPORTS_STATE, reportsRequest);
}

export function* downloadReportWatcherSaga() {
  yield takeLatest(
    REPORTS_ACTION.REQUEST_DOWNLOAD_REPORT_STATE,
    downloadReportRequest
  );
}
