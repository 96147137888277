import { EyeIcon } from "@heroicons/react/24/solid";
import React, { useEffect } from "react";
// import CusotmDataTable from "../../../../Components/Table";
import CustomTable from "../../../../NewUiComponents/Table";
import { useNavigate } from "react-router-dom";
import Cards from "../../../../NewUiComponents/Card";
import PropTypes from "prop-types";
import { mockData } from "../AdminDashboard/mockData";
import { useDispatch, useSelector } from "react-redux";
import { transformArray } from "../../../../Utilites/Helpers";
import { requestDashboardCountAction } from "../../../../Redux/DashboardState/DashboardActionCreator";
import LOCAL_KEYS, { parseLocalStorageJSON } from "../../../../Utilites/LocalStorage";
import { appData } from "../../../../Constants";
import { resetActiveFlagAction, setUserTypeRequestAction, setUserTypeSetPathAction } from "../../../../Redux/UserState/UserActionCreator";

const DashboardCards = ({ title, imgUrl, value, path }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const goToPath = () => {
    if(path === "/users" && title === "Doctors"){
      dispatch(setUserTypeSetPathAction());
      dispatch(setUserTypeRequestAction(appData?.roles.at(-1)));
    }
    dispatch(resetActiveFlagAction());
    navigate(path);
  };
  return (
    <Cards
      className={
        "w-1/4 shadow-2xl rounded-4xl h-40 p-5 flex flex-row justify-between items-center"
      }
    >
      <div>
        <p className="font-poppins text-sm font-medium my-2">{title}</p>
        <p className="text-xl font-bold my-2">{value}</p>
        <p
          className="font-poppins text-xs text-gray underline underline-offset-4 my-2 cursor-pointer"
          onClick={goToPath}
        >
          View all {title}
        </p>
      </div>
      <div>
        <img src={imgUrl} className="w-24 h-24 object-contain" />
      </div>
    </Cards>
  );
};

const HospitalDashboard = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { deviceTypes } = useSelector((state) => state.DeviceTypesListReducer);
  const { userTypes } = useSelector((state) => state.UserTypesReducer);
  const { userName } = parseLocalStorageJSON(LOCAL_KEYS.USER_DETAILS);
  const dashboardReducerState = useSelector((state) => state.DashboardReducer);
  console.log(dashboardReducerState?.dashboardResponse, "dashboardReducerState");

  useEffect(() => {
    dispatch(requestDashboardCountAction(userName));
  }, []);
  
  return (
    <div className="">
      <div className="flex flex-row gap-3">
        <DashboardCards
          title={"Doctors"}
          imgUrl={require("../../../../Assets/Images/scope.png")}
          value={dashboardReducerState?.dashboardResponse?.doctorsCount || 0}
          path={"/users"}
        />
        <DashboardCards
          title={"Patients"}
          imgUrl={require("../../../../Assets/Images/doctorVisit.png")}
          value={dashboardReducerState?.dashboardResponse?.patientsCount || 0}
          path={"/patients"}
        />
        <DashboardCards
          title={"Devices"}
          imgUrl={require("../../../../Assets/Images/device.png")}
          value={dashboardReducerState?.dashboardResponse?.devicesCount || 0}
          path={"/devices"}
        />
        <DashboardCards
          title={"Users"}
          imgUrl={require("../../../../Assets/Images/users.png")}
          value={dashboardReducerState?.dashboardResponse?.usersCount || 0}
          path={"/users"}
        />
      </div>
      <div className="my-5">
      <Cards>
          <p className="font-poppins text-md font-medium my-2 p-3">
            Patients with high alerts
          </p>
          <CustomTable
            columns={mockData.highAlertPatientsColumns}
            enableSearch={false}
            fixedHeaderScrollHeight={"40vh"}
            data={mockData.highAlertPatients}
            // title={"Patient with high alerts"}
          />
          </Cards>
          </div>
      {/* <div className="flex flex-row gap-4 my-5">
        <Cards className={"w-1/2 shadow-2xl  rounded-4xl p-5"}>
          <p className="font-poppins text-md font-medium my-2">
            Patient with high alerts
          </p>
          <CustomTable
            columns={mockData.highAlertPatientsColumns}
            enableSearch={false}
            fixedHeaderScrollHeight={"35vh"}
            data={mockData.highAlertPatients}
          />
        </Cards>
        <Cards className={"w-1/2 shadow-2xl rounded-4xl p-5"}>
          <p className="font-poppins text-md font-medium my-2">Device Types</p>
          <CustomTable
            pagination={false}
            enableSearch={false}
            fixedHeaderScrollHeight={"30vh"}
            data={transformArray(deviceTypes) || []}
            columns={mockData.columnsForDeviceType}
          />
        </Cards>
      </div>

      <div className="flex flex-row gap-4 my-5">
        <Cards className={"w-1/2 shadow-2xl rounded-4xl p-5"}>
          <p className="font-poppins text-md font-medium my-2">User Types</p>
          <CustomTable
            pagination={false}
            enableSearch={false}
            fixedHeaderScrollHeight={"30vh"}
            data={transformArray(userTypes)}
            columns={mockData.columnsForUserTypes}
          />
        </Cards>
        <Cards className={"w-1/2 shadow-2xl rounded-4xl p-5"}>
          <p className="font-poppins text-md font-medium my-2">
            Logged in Ip address
          </p>
          <CustomTable
            pagination={false}
            enableSearch={false}
            fixedHeaderScrollHeight={"30vh"}
            data={transformArray(mockData.ipAddress)}
            columns={mockData.ipAddressColumns}
          />
        </Cards>
      </div> */}

    </div>
  );
};

DashboardCards.propTypes = {
  title: PropTypes.string,
  imgUrl: PropTypes.string,
  value: PropTypes.string,
  path: PropTypes.string,
};

export default HospitalDashboard;
