import React from "react";
import { Outlet, Navigate, useLocation } from "react-router-dom";
import PropTypes from "prop-types";
import LOCAL_KEYS, { parseLocalStorageJSON } from "../../Utilites/LocalStorage";
// import { menu } from "../../Constants";

const PrivateRoute = () => {
  const accessToken = parseLocalStorageJSON(LOCAL_KEYS.ACCESS_TOKEN);
  // const userDetails = parseLocalStorageJSON(LOCAL_KEYS.USER_DETAILS);
  const location = useLocation();
  // const validateUrl = (path) => {
  //   let params = menu.filter((item) => {
  //     if( item.path === path){
  //       return item.access
  //     }
  //   });
  //   let access = params[0].access;
  //   return access?.includes(userDetails?.role) ? false : true;
  // };
  if (accessToken) {
    if (location.pathname === "/") {
      return <Navigate to="/dashboard" />;
    } else {
      return <Outlet />;
    }
  } else {
    return <Navigate to="/login" />;
  }
};
PrivateRoute.propTypes = {
  component: PropTypes.element,
};
export default PrivateRoute;
